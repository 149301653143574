// source: pbtypes/vehicle/skills/mission_camera_settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_user_camera_awb_mode_pb = require('../../../pbtypes/gen/user_camera/awb_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_awb_mode_pb);
var pbtypes_gen_user_camera_exposure_mode_pb = require('../../../pbtypes/gen/user_camera/exposure_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_exposure_mode_pb);
var pbtypes_gen_user_camera_iso_mode_pb = require('../../../pbtypes/gen/user_camera/iso_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_iso_mode_pb);
var pbtypes_gen_user_camera_recording_mode_pb = require('../../../pbtypes/gen/user_camera/recording_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_recording_mode_pb);
var pbtypes_gen_user_camera_compensation_mode_pb = require('../../../pbtypes/gen/user_camera/compensation_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_compensation_mode_pb);
goog.exportSymbol('proto.skills.CameraSettings', null, global);
goog.exportSymbol('proto.skills.CameraSettings.EoSensor', null, global);
goog.exportSymbol('proto.skills.PhotoFormat', null, global);
goog.exportSymbol('proto.skills.PhotoFormatSettings', null, global);
goog.exportSymbol('proto.skills.PhotoFormatSettings.PhotoFormat', null, global);
goog.exportSymbol('proto.skills.PhotoSettings', null, global);
goog.exportSymbol('proto.skills.PhotoType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.CameraSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.CameraSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.CameraSettings.displayName = 'proto.skills.CameraSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PhotoSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PhotoSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PhotoSettings.displayName = 'proto.skills.PhotoSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PhotoType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PhotoType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PhotoType.displayName = 'proto.skills.PhotoType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PhotoFormat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PhotoFormat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PhotoFormat.displayName = 'proto.skills.PhotoFormat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PhotoFormatSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.PhotoFormatSettings.repeatedFields_, null);
};
goog.inherits(proto.skills.PhotoFormatSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PhotoFormatSettings.displayName = 'proto.skills.PhotoFormatSettings';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.CameraSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.CameraSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.CameraSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.CameraSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
isoMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
exposureMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
awbMode: jspb.Message.getFieldWithDefault(msg, 3, 0),
zoomLevel: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
eoSensor: jspb.Message.getFieldWithDefault(msg, 7, 0),
compensationMode: jspb.Message.getFieldWithDefault(msg, 5, 0),
recordingMode: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.CameraSettings}
 */
proto.skills.CameraSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.CameraSettings;
  return proto.skills.CameraSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.CameraSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.CameraSettings}
 */
proto.skills.CameraSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.user_camera.IsoMode.Enum} */ (reader.readEnum());
      msg.setIsoMode(value);
      break;
    case 2:
      var value = /** @type {!proto.user_camera.ExposureMode.Enum} */ (reader.readEnum());
      msg.setExposureMode(value);
      break;
    case 3:
      var value = /** @type {!proto.user_camera.AwbMode.Enum} */ (reader.readEnum());
      msg.setAwbMode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZoomLevel(value);
      break;
    case 7:
      var value = /** @type {!proto.skills.CameraSettings.EoSensor} */ (reader.readEnum());
      msg.setEoSensor(value);
      break;
    case 5:
      var value = /** @type {!proto.user_camera.CompensationMode.Enum} */ (reader.readEnum());
      msg.setCompensationMode(value);
      break;
    case 6:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setRecordingMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.CameraSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.CameraSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.CameraSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.CameraSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsoMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExposureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAwbMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getZoomLevel();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getEoSensor();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCompensationMode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.CameraSettings.EoSensor = {
  UNKNOWN: 0,
  R3_WIDE: 1,
  X2_WIDE: 2,
  X2_NARROW: 3,
  R47_NARROW: 5,
  R47_TELE: 6,
  R47_CINE: 7
};

/**
 * optional user_camera.IsoMode.Enum iso_mode = 1;
 * @return {!proto.user_camera.IsoMode.Enum}
 */
proto.skills.CameraSettings.prototype.getIsoMode = function() {
  return /** @type {!proto.user_camera.IsoMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.user_camera.IsoMode.Enum} value
 * @return {!proto.skills.CameraSettings} returns this
 */
proto.skills.CameraSettings.prototype.setIsoMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional user_camera.ExposureMode.Enum exposure_mode = 2;
 * @return {!proto.user_camera.ExposureMode.Enum}
 */
proto.skills.CameraSettings.prototype.getExposureMode = function() {
  return /** @type {!proto.user_camera.ExposureMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.user_camera.ExposureMode.Enum} value
 * @return {!proto.skills.CameraSettings} returns this
 */
proto.skills.CameraSettings.prototype.setExposureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional user_camera.AwbMode.Enum awb_mode = 3;
 * @return {!proto.user_camera.AwbMode.Enum}
 */
proto.skills.CameraSettings.prototype.getAwbMode = function() {
  return /** @type {!proto.user_camera.AwbMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.user_camera.AwbMode.Enum} value
 * @return {!proto.skills.CameraSettings} returns this
 */
proto.skills.CameraSettings.prototype.setAwbMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float zoom_level = 4;
 * @return {number}
 */
proto.skills.CameraSettings.prototype.getZoomLevel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.CameraSettings} returns this
 */
proto.skills.CameraSettings.prototype.setZoomLevel = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional EoSensor eo_sensor = 7;
 * @return {!proto.skills.CameraSettings.EoSensor}
 */
proto.skills.CameraSettings.prototype.getEoSensor = function() {
  return /** @type {!proto.skills.CameraSettings.EoSensor} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.skills.CameraSettings.EoSensor} value
 * @return {!proto.skills.CameraSettings} returns this
 */
proto.skills.CameraSettings.prototype.setEoSensor = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional user_camera.CompensationMode.Enum compensation_mode = 5;
 * @return {!proto.user_camera.CompensationMode.Enum}
 */
proto.skills.CameraSettings.prototype.getCompensationMode = function() {
  return /** @type {!proto.user_camera.CompensationMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.user_camera.CompensationMode.Enum} value
 * @return {!proto.skills.CameraSettings} returns this
 */
proto.skills.CameraSettings.prototype.setCompensationMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional user_camera.RecordingMode.Enum recording_mode = 6;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.skills.CameraSettings.prototype.getRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.skills.CameraSettings} returns this
 */
proto.skills.CameraSettings.prototype.setRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PhotoSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PhotoSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PhotoSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
photoType: (f = msg.getPhotoType()) && proto.skills.PhotoType.toObject(includeInstance, f),
photoFormat: (f = msg.getPhotoFormat()) && proto.skills.PhotoFormat.toObject(includeInstance, f),
photoQuality: jspb.Message.getFieldWithDefault(msg, 8, 0),
publishThumbnail: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
thumbnailWidth: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PhotoSettings}
 */
proto.skills.PhotoSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PhotoSettings;
  return proto.skills.PhotoSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PhotoSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PhotoSettings}
 */
proto.skills.PhotoSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = new proto.skills.PhotoType;
      reader.readMessage(value,proto.skills.PhotoType.deserializeBinaryFromReader);
      msg.setPhotoType(value);
      break;
    case 7:
      var value = new proto.skills.PhotoFormat;
      reader.readMessage(value,proto.skills.PhotoFormat.deserializeBinaryFromReader);
      msg.setPhotoFormat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPhotoQuality(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublishThumbnail(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setThumbnailWidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PhotoSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PhotoSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PhotoSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhotoType();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.PhotoType.serializeBinaryToWriter
    );
  }
  f = message.getPhotoFormat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.PhotoFormat.serializeBinaryToWriter
    );
  }
  f = message.getPhotoQuality();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPublishThumbnail();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getThumbnailWidth();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional PhotoType photo_type = 6;
 * @return {?proto.skills.PhotoType}
 */
proto.skills.PhotoSettings.prototype.getPhotoType = function() {
  return /** @type{?proto.skills.PhotoType} */ (
    jspb.Message.getWrapperField(this, proto.skills.PhotoType, 6));
};


/**
 * @param {?proto.skills.PhotoType|undefined} value
 * @return {!proto.skills.PhotoSettings} returns this
*/
proto.skills.PhotoSettings.prototype.setPhotoType = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.PhotoSettings} returns this
 */
proto.skills.PhotoSettings.prototype.clearPhotoType = function() {
  return this.setPhotoType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.PhotoSettings.prototype.hasPhotoType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PhotoFormat photo_format = 7;
 * @return {?proto.skills.PhotoFormat}
 */
proto.skills.PhotoSettings.prototype.getPhotoFormat = function() {
  return /** @type{?proto.skills.PhotoFormat} */ (
    jspb.Message.getWrapperField(this, proto.skills.PhotoFormat, 7));
};


/**
 * @param {?proto.skills.PhotoFormat|undefined} value
 * @return {!proto.skills.PhotoSettings} returns this
*/
proto.skills.PhotoSettings.prototype.setPhotoFormat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.PhotoSettings} returns this
 */
proto.skills.PhotoSettings.prototype.clearPhotoFormat = function() {
  return this.setPhotoFormat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.PhotoSettings.prototype.hasPhotoFormat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 photo_quality = 8;
 * @return {number}
 */
proto.skills.PhotoSettings.prototype.getPhotoQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PhotoSettings} returns this
 */
proto.skills.PhotoSettings.prototype.setPhotoQuality = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool publish_thumbnail = 9;
 * @return {boolean}
 */
proto.skills.PhotoSettings.prototype.getPublishThumbnail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.PhotoSettings} returns this
 */
proto.skills.PhotoSettings.prototype.setPublishThumbnail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 thumbnail_width = 10;
 * @return {number}
 */
proto.skills.PhotoSettings.prototype.getThumbnailWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PhotoSettings} returns this
 */
proto.skills.PhotoSettings.prototype.setThumbnailWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PhotoType.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PhotoType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PhotoType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoType.toObject = function(includeInstance, msg) {
  var f, obj = {
eoPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
irPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PhotoType}
 */
proto.skills.PhotoType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PhotoType;
  return proto.skills.PhotoType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PhotoType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PhotoType}
 */
proto.skills.PhotoType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEoPhoto(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIrPhoto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PhotoType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PhotoType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PhotoType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEoPhoto();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIrPhoto();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool eo_photo = 1;
 * @return {boolean}
 */
proto.skills.PhotoType.prototype.getEoPhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.PhotoType} returns this
 */
proto.skills.PhotoType.prototype.setEoPhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool ir_photo = 2;
 * @return {boolean}
 */
proto.skills.PhotoType.prototype.getIrPhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.PhotoType} returns this
 */
proto.skills.PhotoType.prototype.setIrPhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PhotoFormat.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PhotoFormat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PhotoFormat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoFormat.toObject = function(includeInstance, msg) {
  var f, obj = {
format: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PhotoFormat}
 */
proto.skills.PhotoFormat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PhotoFormat;
  return proto.skills.PhotoFormat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PhotoFormat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PhotoFormat}
 */
proto.skills.PhotoFormat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PhotoFormat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PhotoFormat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PhotoFormat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoFormat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string format = 1;
 * @return {string}
 */
proto.skills.PhotoFormat.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.PhotoFormat} returns this
 */
proto.skills.PhotoFormat.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.PhotoFormatSettings.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PhotoFormatSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PhotoFormatSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PhotoFormatSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoFormatSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
eoPhotoFormatsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
irPhotoFormatsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PhotoFormatSettings}
 */
proto.skills.PhotoFormatSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PhotoFormatSettings;
  return proto.skills.PhotoFormatSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PhotoFormatSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PhotoFormatSettings}
 */
proto.skills.PhotoFormatSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEoPhotoFormats(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIrPhotoFormats(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PhotoFormatSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PhotoFormatSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PhotoFormatSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PhotoFormatSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEoPhotoFormatsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getIrPhotoFormatsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.PhotoFormatSettings.PhotoFormat = {
  JPEG: 0,
  DNG: 1,
  RAW: 2,
  RJPEG: 3
};

/**
 * repeated PhotoFormat eo_photo_formats = 1;
 * @return {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>}
 */
proto.skills.PhotoFormatSettings.prototype.getEoPhotoFormatsList = function() {
  return /** @type {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>} value
 * @return {!proto.skills.PhotoFormatSettings} returns this
 */
proto.skills.PhotoFormatSettings.prototype.setEoPhotoFormatsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.skills.PhotoFormatSettings.PhotoFormat} value
 * @param {number=} opt_index
 * @return {!proto.skills.PhotoFormatSettings} returns this
 */
proto.skills.PhotoFormatSettings.prototype.addEoPhotoFormats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.PhotoFormatSettings} returns this
 */
proto.skills.PhotoFormatSettings.prototype.clearEoPhotoFormatsList = function() {
  return this.setEoPhotoFormatsList([]);
};


/**
 * repeated PhotoFormat ir_photo_formats = 2;
 * @return {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>}
 */
proto.skills.PhotoFormatSettings.prototype.getIrPhotoFormatsList = function() {
  return /** @type {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.skills.PhotoFormatSettings.PhotoFormat>} value
 * @return {!proto.skills.PhotoFormatSettings} returns this
 */
proto.skills.PhotoFormatSettings.prototype.setIrPhotoFormatsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.skills.PhotoFormatSettings.PhotoFormat} value
 * @param {number=} opt_index
 * @return {!proto.skills.PhotoFormatSettings} returns this
 */
proto.skills.PhotoFormatSettings.prototype.addIrPhotoFormats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.PhotoFormatSettings} returns this
 */
proto.skills.PhotoFormatSettings.prototype.clearIrPhotoFormatsList = function() {
  return this.setIrPhotoFormatsList([]);
};


goog.object.extend(exports, proto.skills);
