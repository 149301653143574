// source: pbtypes/vehicle/skills/mission.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_gen_common_fault_pb = require('../../../pbtypes/gen/common/fault_pb.js');
goog.object.extend(proto, pbtypes_gen_common_fault_pb);
var pbtypes_gen_common_flight_phase_enum_pb = require('../../../pbtypes/gen/common/flight_phase_enum_pb.js');
goog.object.extend(proto, pbtypes_gen_common_flight_phase_enum_pb);
var pbtypes_gen_user_camera_awb_mode_pb = require('../../../pbtypes/gen/user_camera/awb_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_awb_mode_pb);
var pbtypes_gen_user_camera_compensation_mode_pb = require('../../../pbtypes/gen/user_camera/compensation_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_compensation_mode_pb);
var pbtypes_gen_user_camera_exposure_mode_pb = require('../../../pbtypes/gen/user_camera/exposure_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_exposure_mode_pb);
var pbtypes_gen_user_camera_iso_mode_pb = require('../../../pbtypes/gen/user_camera/iso_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_iso_mode_pb);
var pbtypes_gen_user_camera_recording_mode_pb = require('../../../pbtypes/gen/user_camera/recording_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_recording_mode_pb);
var pbtypes_vehicle_infrastructure_flight_deck_navigation_mode_pb = require('../../../pbtypes/vehicle/infrastructure/flight_deck/navigation_mode_pb.js');
goog.object.extend(proto, pbtypes_vehicle_infrastructure_flight_deck_navigation_mode_pb);
var pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb = require('../../../pbtypes/vehicle/infrastructure/flight_deck/return_settings_pb.js');
goog.object.extend(proto, pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb);
var pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb = require('../../../pbtypes/vehicle/nav_cam_reconstruction/pose_id_pb.js');
goog.object.extend(proto, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb);
var pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb = require('../../../pbtypes/vehicle/skills/actions/acquire_gps/acquire_gps_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb);
var pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb = require('../../../pbtypes/vehicle/skills/actions/apriltag_set_id/apriltag_set_id_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb);
var pbtypes_vehicle_skills_actions_conditional_conditional_pb = require('../../../pbtypes/vehicle/skills/actions/conditional/conditional_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_conditional_conditional_pb);
var pbtypes_vehicle_skills_actions_controls_test_controls_test_pb = require('../../../pbtypes/vehicle/skills/actions/controls_test/controls_test_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb);
var pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb = require('../../../pbtypes/vehicle/skills/actions/dock_gps_estimator/dock_gps_estimator_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb);
var pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb = require('../../../pbtypes/vehicle/skills/actions/g47_command_override/g47_command_override_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb);
var pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb = require('../../../pbtypes/vehicle/skills/actions/dummy_action/dummy_action_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb);
var pbtypes_vehicle_skills_actions_e_land_e_land_pb = require('../../../pbtypes/vehicle/skills/actions/e_land/e_land_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_e_land_e_land_pb);
var pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb = require('../../../pbtypes/vehicle/skills/actions/global_graph_control/global_graph_control_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb);
var pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb = require('../../../pbtypes/vehicle/skills/actions/goto_waypoint/goto_waypoint_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb);
var pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb = require('../../../pbtypes/vehicle/skills/actions/init_global_map/init_global_map_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb);
var pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb = require('../../../pbtypes/vehicle/skills/actions/initialize_gps/initialize_gps_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb);
var pbtypes_vehicle_skills_actions_inverter_inverter_pb = require('../../../pbtypes/vehicle/skills/actions/inverter/inverter_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_inverter_inverter_pb);
var pbtypes_vehicle_skills_actions_looper_looper_pb = require('../../../pbtypes/vehicle/skills/actions/looper/looper_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_looper_looper_pb);
var pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb = require('../../../pbtypes/vehicle/skills/actions/modify_sim_state_estimation/modify_sim_state_estimation_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb);
var pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb = require('../../../pbtypes/vehicle/skills/actions/ncpg_control/ncpg_control_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb);
var pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb = require('../../../pbtypes/vehicle/skills/actions/orbit_poi/orbit_poi_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb);
var pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb = require('../../../pbtypes/vehicle/skills/actions/override_gps_quality/override_gps_quality_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb);
var pbtypes_vehicle_skills_actions_parallel_parallel_pb = require('../../../pbtypes/vehicle/skills/actions/parallel/parallel_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_parallel_parallel_pb);
var pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb = require('../../../pbtypes/vehicle/skills/actions/register_complimentary_actions/register_complimentary_actions_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb);
var pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb = require('../../../pbtypes/vehicle/skills/actions/request_takeoff_profile/request_takeoff_profile_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb);
var pbtypes_vehicle_skills_actions_sequence_sequence_pb = require('../../../pbtypes/vehicle/skills/actions/sequence/sequence_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_sequence_sequence_pb);
var pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb = require('../../../pbtypes/vehicle/skills/actions/set_fault_override/set_fault_override_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb);
var pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb = require('../../../pbtypes/vehicle/skills/actions/set_obstacle_avoidance/set_obstacle_avoidance_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb);
var pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb = require('../../../pbtypes/vehicle/skills/actions/spoof_battery_level/spoof_battery_level_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb);
var pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb = require('../../../pbtypes/vehicle/skills/actions/spoof_disturbance_action/spoof_disturbance_action_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb);
var pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb = require('../../../pbtypes/vehicle/skills/actions/spoof_initial_gps_heading/spoof_initial_gps_heading_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb);
var pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb = require('../../../pbtypes/vehicle/skills/actions/spoof_joysticks_inputs/spoof_joysticks_inputs_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb);
var pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb = require('../../../pbtypes/vehicle/skills/actions/spoof_obstacle/spoof_obstacle_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb);
var pbtypes_vehicle_skills_actions_start_video_start_video_pb = require('../../../pbtypes/vehicle/skills/actions/start_video/start_video_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_start_video_start_video_pb);
var pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb = require('../../../pbtypes/vehicle/skills/actions/stop_for_photo_inspect/stop_for_photo_inspect_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb);
var pbtypes_vehicle_skills_actions_stop_video_stop_video_pb = require('../../../pbtypes/vehicle/skills/actions/stop_video/stop_video_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_stop_video_stop_video_pb);
var pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb = require('../../../pbtypes/vehicle/skills/actions/switch_recording_mode/switch_recording_mode_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb);
var pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb = require('../../../pbtypes/vehicle/skills/actions/take_panorama/take_panorama_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb);
var pbtypes_vehicle_skills_actions_take_photo_take_photo_pb = require('../../../pbtypes/vehicle/skills/actions/take_photo/take_photo_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_take_photo_take_photo_pb);
var pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb = require('../../../pbtypes/vehicle/skills/actions/timed_wait/timed_wait_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb);
var pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb = require('../../../pbtypes/vehicle/skills/actions/toggle_photo_interval_mode/toggle_photo_interval_mode_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb);
var pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb = require('../../../pbtypes/vehicle/skills/actions/toggle_semantic_aim/toggle_semantic_aim_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb);
var pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb = require('../../../pbtypes/vehicle/skills/actions/toggle_target_point_exposure/toggle_target_point_exposure_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb);
var pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb = require('../../../pbtypes/vehicle/skills/actions/track_in_place/track_in_place_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb);
var pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb = require('../../../pbtypes/vehicle/skills/actions/track_landing_pad_request/track_landing_pad_request_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb);
var pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb = require('../../../pbtypes/vehicle/skills/actions/trajectory_inspect/trajectory_inspect_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb);
var pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb = require('../../../pbtypes/vehicle/skills/actions/unified_follow/unified_follow_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb);
var pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb = require('../../../pbtypes/vehicle/skills/actions/update_dock_location/update_dock_location_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb);
var pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb = require('../../../pbtypes/vehicle/skills/actions/update_joysticks_settings/update_joysticks_settings_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb);
var pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb = require('../../../pbtypes/vehicle/skills/actions/update_shape_map/update_shape_map_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb);
var pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb = require('../../../pbtypes/vehicle/skills/actions/update_vehicle_settings/update_vehicle_settings_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb);
var pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb = require('../../../pbtypes/vehicle/skills/actions/wait_for_vehicle_stop/wait_for_vehicle_stop_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb);
var pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb = require('../../../pbtypes/vehicle/skills/actions/wifi_scan/wifi_scan_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb);
var pbtypes_vehicle_skills_actions_within_distance_within_distance_pb = require('../../../pbtypes/vehicle/skills/actions/within_distance/within_distance_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_actions_within_distance_within_distance_pb);
var pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb = require('../../../pbtypes/vehicle/skills/builtin_skills/keyframe/keyframe_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb);
var pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb = require('../../../pbtypes/vehicle/skills/builtin_skills/surface_scan/surface_scan_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb);
var pbtypes_vehicle_skills_waypoints_pb = require('../../../pbtypes/vehicle/skills/waypoints_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_waypoints_pb);
goog.exportSymbol('proto.skills.Action', null, global);
goog.exportSymbol('proto.skills.ActionArgs', null, global);
goog.exportSymbol('proto.skills.ActionArgs.ArgsCase', null, global);
goog.exportSymbol('proto.skills.ActionResult', null, global);
goog.exportSymbol('proto.skills.ActionResult.DataCase', null, global);
goog.exportSymbol('proto.skills.ActionResult.Result', null, global);
goog.exportSymbol('proto.skills.ActionState', null, global);
goog.exportSymbol('proto.skills.ActionState.DataCase', null, global);
goog.exportSymbol('proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode', null, global);
goog.exportSymbol('proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.Enum', null, global);
goog.exportSymbol('proto.skills.DisableStrobeLightsActionArgs', null, global);
goog.exportSymbol('proto.skills.DisableStrobeLightsActionStateData', null, global);
goog.exportSymbol('proto.skills.DisableStrobeLightsResultData', null, global);
goog.exportSymbol('proto.skills.EnableStrobeLightsActionArgs', null, global);
goog.exportSymbol('proto.skills.EnableStrobeLightsActionStateData', null, global);
goog.exportSymbol('proto.skills.EnableStrobeLightsResultData', null, global);
goog.exportSymbol('proto.skills.FailedTakeoffReason', null, global);
goog.exportSymbol('proto.skills.FailedTakeoffReason.Enum', null, global);
goog.exportSymbol('proto.skills.FailedTakeoffSummary', null, global);
goog.exportSymbol('proto.skills.FlightInfo', null, global);
goog.exportSymbol('proto.skills.GotoAndOrbitPoiActionStateData', null, global);
goog.exportSymbol('proto.skills.GpsOriginInfo', null, global);
goog.exportSymbol('proto.skills.InverterActionArgs', null, global);
goog.exportSymbol('proto.skills.LooperActionArgs', null, global);
goog.exportSymbol('proto.skills.LostConnectionActionEnum', null, global);
goog.exportSymbol('proto.skills.LostConnectionActionEnum.Enum', null, global);
goog.exportSymbol('proto.skills.Mission', null, global);
goog.exportSymbol('proto.skills.MissionControl', null, global);
goog.exportSymbol('proto.skills.MissionControl.ControlType', null, global);
goog.exportSymbol('proto.skills.MissionResponse', null, global);
goog.exportSymbol('proto.skills.MissionResult', null, global);
goog.exportSymbol('proto.skills.MissionResult.Result', null, global);
goog.exportSymbol('proto.skills.MissionSkillEnum', null, global);
goog.exportSymbol('proto.skills.MissionSkillEnum.Enum', null, global);
goog.exportSymbol('proto.skills.MissionState', null, global);
goog.exportSymbol('proto.skills.MissionState.GpsStatusEnum', null, global);
goog.exportSymbol('proto.skills.MissionState.MissionStateEnum', null, global);
goog.exportSymbol('proto.skills.MissionTemplate', null, global);
goog.exportSymbol('proto.skills.MissionUpdate', null, global);
goog.exportSymbol('proto.skills.MissionUpdate.UpdateType', null, global);
goog.exportSymbol('proto.skills.MissionUpdateResponse', null, global);
goog.exportSymbol('proto.skills.NcpgWaypointInfo', null, global);
goog.exportSymbol('proto.skills.NcpgWaypointsRequest', null, global);
goog.exportSymbol('proto.skills.PostMissionActionEnum', null, global);
goog.exportSymbol('proto.skills.PostMissionActionEnum.Enum', null, global);
goog.exportSymbol('proto.skills.PostMissionActionResultEnum', null, global);
goog.exportSymbol('proto.skills.PostMissionActionResultEnum.Enum', null, global);
goog.exportSymbol('proto.skills.SequenceActionArgs', null, global);
goog.exportSymbol('proto.skills.SequenceActionStateData', null, global);
goog.exportSymbol('proto.skills.SequenceEstimate', null, global);
goog.exportSymbol('proto.skills.SequencePretakeoffActionStateData', null, global);
goog.exportSymbol('proto.skills.SequenceUntilFailureActionStateData', null, global);
goog.exportSymbol('proto.skills.SequenceUntilSuccessActionStateData', null, global);
goog.exportSymbol('proto.skills.VehicleSupportedActions', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.Mission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.Mission.repeatedFields_, null);
};
goog.inherits(proto.skills.Mission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.Mission.displayName = 'proto.skills.Mission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GpsOriginInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GpsOriginInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GpsOriginInfo.displayName = 'proto.skills.GpsOriginInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PostMissionActionEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PostMissionActionEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PostMissionActionEnum.displayName = 'proto.skills.PostMissionActionEnum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionSkillEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionSkillEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionSkillEnum.displayName = 'proto.skills.MissionSkillEnum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.LostConnectionActionEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.LostConnectionActionEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.LostConnectionActionEnum.displayName = 'proto.skills.LostConnectionActionEnum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionResponse.displayName = 'proto.skills.MissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.Action.displayName = 'proto.skills.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, proto.skills.ActionArgs.oneofGroups_);
};
goog.inherits(proto.skills.ActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ActionArgs.displayName = 'proto.skills.ActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.MissionState.repeatedFields_, null);
};
goog.inherits(proto.skills.MissionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionState.displayName = 'proto.skills.MissionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SequenceEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.SequenceEstimate.repeatedFields_, null);
};
goog.inherits(proto.skills.SequenceEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SequenceEstimate.displayName = 'proto.skills.SequenceEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.MissionUpdate.repeatedFields_, null);
};
goog.inherits(proto.skills.MissionUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionUpdate.displayName = 'proto.skills.MissionUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionUpdateResponse.displayName = 'proto.skills.MissionUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MissionControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionControl.displayName = 'proto.skills.MissionControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.FlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.FlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.FlightInfo.displayName = 'proto.skills.FlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ActionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.ActionState.oneofGroups_);
};
goog.inherits(proto.skills.ActionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ActionState.displayName = 'proto.skills.ActionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.FailedTakeoffSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.FailedTakeoffSummary.repeatedFields_, null);
};
goog.inherits(proto.skills.FailedTakeoffSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.FailedTakeoffSummary.displayName = 'proto.skills.FailedTakeoffSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.FailedTakeoffReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.FailedTakeoffReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.FailedTakeoffReason.displayName = 'proto.skills.FailedTakeoffReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PostMissionActionResultEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PostMissionActionResultEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PostMissionActionResultEnum.displayName = 'proto.skills.PostMissionActionResultEnum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.MissionResult.repeatedFields_, null);
};
goog.inherits(proto.skills.MissionResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionResult.displayName = 'proto.skills.MissionResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ActionResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.ActionResult.oneofGroups_);
};
goog.inherits(proto.skills.ActionResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ActionResult.displayName = 'proto.skills.ActionResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.VehicleSupportedActions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.VehicleSupportedActions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.VehicleSupportedActions.displayName = 'proto.skills.VehicleSupportedActions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MissionTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.MissionTemplate.repeatedFields_, null);
};
goog.inherits(proto.skills.MissionTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MissionTemplate.displayName = 'proto.skills.MissionTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SequenceActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.SequenceActionArgs.repeatedFields_, null);
};
goog.inherits(proto.skills.SequenceActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SequenceActionArgs.displayName = 'proto.skills.SequenceActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SequenceActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SequenceActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SequenceActionStateData.displayName = 'proto.skills.SequenceActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SequenceUntilFailureActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SequenceUntilFailureActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SequenceUntilFailureActionStateData.displayName = 'proto.skills.SequenceUntilFailureActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SequenceUntilSuccessActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SequenceUntilSuccessActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SequenceUntilSuccessActionStateData.displayName = 'proto.skills.SequenceUntilSuccessActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SequencePretakeoffActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SequencePretakeoffActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SequencePretakeoffActionStateData.displayName = 'proto.skills.SequencePretakeoffActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoAndOrbitPoiActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoAndOrbitPoiActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoAndOrbitPoiActionStateData.displayName = 'proto.skills.GotoAndOrbitPoiActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.LooperActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.LooperActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.LooperActionArgs.displayName = 'proto.skills.LooperActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.InverterActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.InverterActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.InverterActionArgs.displayName = 'proto.skills.InverterActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.EnableStrobeLightsActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.EnableStrobeLightsActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.EnableStrobeLightsActionArgs.displayName = 'proto.skills.EnableStrobeLightsActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DisableStrobeLightsActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.DisableStrobeLightsActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DisableStrobeLightsActionArgs.displayName = 'proto.skills.DisableStrobeLightsActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.EnableStrobeLightsResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.EnableStrobeLightsResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.EnableStrobeLightsResultData.displayName = 'proto.skills.EnableStrobeLightsResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DisableStrobeLightsResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.DisableStrobeLightsResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DisableStrobeLightsResultData.displayName = 'proto.skills.DisableStrobeLightsResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.EnableStrobeLightsActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.EnableStrobeLightsActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.EnableStrobeLightsActionStateData.displayName = 'proto.skills.EnableStrobeLightsActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DisableStrobeLightsActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.DisableStrobeLightsActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DisableStrobeLightsActionStateData.displayName = 'proto.skills.DisableStrobeLightsActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.NcpgWaypointInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.NcpgWaypointInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.NcpgWaypointInfo.displayName = 'proto.skills.NcpgWaypointInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.NcpgWaypointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.NcpgWaypointsRequest.repeatedFields_, null);
};
goog.inherits(proto.skills.NcpgWaypointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.NcpgWaypointsRequest.displayName = 'proto.skills.NcpgWaypointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.displayName = 'proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.Mission.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.Mission.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.Mission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.Mission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Mission.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 7, 0),
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
templateUuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.skills.Action.toObject, includeInstance),
postMissionAction: jspb.Message.getFieldWithDefault(msg, 5, 0),
postFailureAction: jspb.Message.getFieldWithDefault(msg, 9, 0),
lostConnectionAction: jspb.Message.getFieldWithDefault(msg, 6, 0),
scheduledMissionUuid: jspb.Message.getFieldWithDefault(msg, 8, ""),
missionRunnerSkill: jspb.Message.getFieldWithDefault(msg, 10, 0),
flightId: jspb.Message.getFieldWithDefault(msg, 11, ""),
ncpgFileId: jspb.Message.getFieldWithDefault(msg, 12, ""),
globalGraphFileId: jspb.Message.getFieldWithDefault(msg, 13, ""),
dockMission: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
autoStart: jspb.Message.getBooleanFieldWithDefault(msg, 101, false),
needsGpsInitializationMove: jspb.Message.getBooleanFieldWithDefault(msg, 102, false),
returnToPathOnResume: jspb.Message.getBooleanFieldWithDefault(msg, 103, false),
showSkipUi: jspb.Message.getBooleanFieldWithDefault(msg, 105, false),
expectedGpsOrigin: (f = msg.getExpectedGpsOrigin()) && proto.skills.GpsOriginInfo.toObject(includeInstance, f),
useRecordingMode: jspb.Message.getBooleanFieldWithDefault(msg, 117, false),
recordingMode: jspb.Message.getFieldWithDefault(msg, 107, 0),
useIsoMode: jspb.Message.getBooleanFieldWithDefault(msg, 120, false),
isoMode: jspb.Message.getFieldWithDefault(msg, 121, 0),
useExposureMode: jspb.Message.getBooleanFieldWithDefault(msg, 122, false),
exposureMode: jspb.Message.getFieldWithDefault(msg, 123, 0),
useAwbMode: jspb.Message.getBooleanFieldWithDefault(msg, 124, false),
awbMode: jspb.Message.getFieldWithDefault(msg, 125, 0),
useCompensationMode: jspb.Message.getBooleanFieldWithDefault(msg, 126, false),
compensationMode: jspb.Message.getFieldWithDefault(msg, 127, 0),
autonomousAbortMissionOnFailedAction: jspb.Message.getBooleanFieldWithDefault(msg, 109, false),
enableFaultBasedDirectRtd: jspb.Message.getBooleanFieldWithDefault(msg, 110, false),
needsNcpgInitialization: jspb.Message.getBooleanFieldWithDefault(msg, 111, false),
videoBitrateOverride: jspb.Message.getFieldWithDefault(msg, 112, 0),
navigationModeOverrideEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 113, false),
navigationModeOverride: jspb.Message.getFieldWithDefault(msg, 114, 0),
useRtxSettings: jspb.Message.getBooleanFieldWithDefault(msg, 115, false),
rtxSettings: (f = msg.getRtxSettings()) && pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.toObject(includeInstance, f),
needsGlobalGraph: jspb.Message.getBooleanFieldWithDefault(msg, 118, false),
estimateReferenceNavTNavTakeoff: (f = msg.getEstimateReferenceNavTNavTakeoff()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
disableStrobeLights: jspb.Message.getBooleanFieldWithDefault(msg, 128, false),
skipUpload: jspb.Message.getBooleanFieldWithDefault(msg, 129, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.Mission}
 */
proto.skills.Mission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.Mission;
  return proto.skills.Mission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.Mission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.Mission}
 */
proto.skills.Mission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateUuid(value);
      break;
    case 4:
      var value = new proto.skills.Action;
      reader.readMessage(value,proto.skills.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 5:
      var value = /** @type {!proto.skills.PostMissionActionEnum.Enum} */ (reader.readEnum());
      msg.setPostMissionAction(value);
      break;
    case 9:
      var value = /** @type {!proto.skills.PostMissionActionEnum.Enum} */ (reader.readEnum());
      msg.setPostFailureAction(value);
      break;
    case 6:
      var value = /** @type {!proto.skills.LostConnectionActionEnum.Enum} */ (reader.readEnum());
      msg.setLostConnectionAction(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduledMissionUuid(value);
      break;
    case 10:
      var value = /** @type {!proto.skills.MissionSkillEnum.Enum} */ (reader.readEnum());
      msg.setMissionRunnerSkill(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNcpgFileId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalGraphFileId(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDockMission(value);
      break;
    case 101:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoStart(value);
      break;
    case 102:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedsGpsInitializationMove(value);
      break;
    case 103:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnToPathOnResume(value);
      break;
    case 105:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowSkipUi(value);
      break;
    case 106:
      var value = new proto.skills.GpsOriginInfo;
      reader.readMessage(value,proto.skills.GpsOriginInfo.deserializeBinaryFromReader);
      msg.setExpectedGpsOrigin(value);
      break;
    case 117:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseRecordingMode(value);
      break;
    case 107:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setRecordingMode(value);
      break;
    case 120:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseIsoMode(value);
      break;
    case 121:
      var value = /** @type {!proto.user_camera.IsoMode.Enum} */ (reader.readEnum());
      msg.setIsoMode(value);
      break;
    case 122:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseExposureMode(value);
      break;
    case 123:
      var value = /** @type {!proto.user_camera.ExposureMode.Enum} */ (reader.readEnum());
      msg.setExposureMode(value);
      break;
    case 124:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseAwbMode(value);
      break;
    case 125:
      var value = /** @type {!proto.user_camera.AwbMode.Enum} */ (reader.readEnum());
      msg.setAwbMode(value);
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseCompensationMode(value);
      break;
    case 127:
      var value = /** @type {!proto.user_camera.CompensationMode.Enum} */ (reader.readEnum());
      msg.setCompensationMode(value);
      break;
    case 109:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutonomousAbortMissionOnFailedAction(value);
      break;
    case 110:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableFaultBasedDirectRtd(value);
      break;
    case 111:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedsNcpgInitialization(value);
      break;
    case 112:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoBitrateOverride(value);
      break;
    case 113:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNavigationModeOverrideEnabled(value);
      break;
    case 114:
      var value = /** @type {!proto.flight_deck.NavigationMode.Mode} */ (reader.readEnum());
      msg.setNavigationModeOverride(value);
      break;
    case 115:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseRtxSettings(value);
      break;
    case 116:
      var value = new pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings;
      reader.readMessage(value,pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.deserializeBinaryFromReader);
      msg.setRtxSettings(value);
      break;
    case 118:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedsGlobalGraph(value);
      break;
    case 119:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setEstimateReferenceNavTNavTakeoff(value);
      break;
    case 128:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableStrobeLights(value);
      break;
    case 129:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipUpload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.Mission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.Mission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.Mission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Mission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplateUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.skills.Action.serializeBinaryToWriter
    );
  }
  f = message.getPostMissionAction();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPostFailureAction();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLostConnectionAction();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getScheduledMissionUuid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMissionRunnerSkill();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNcpgFileId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getGlobalGraphFileId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDockMission();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getAutoStart();
  if (f) {
    writer.writeBool(
      101,
      f
    );
  }
  f = message.getNeedsGpsInitializationMove();
  if (f) {
    writer.writeBool(
      102,
      f
    );
  }
  f = message.getReturnToPathOnResume();
  if (f) {
    writer.writeBool(
      103,
      f
    );
  }
  f = message.getShowSkipUi();
  if (f) {
    writer.writeBool(
      105,
      f
    );
  }
  f = message.getExpectedGpsOrigin();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      proto.skills.GpsOriginInfo.serializeBinaryToWriter
    );
  }
  f = message.getUseRecordingMode();
  if (f) {
    writer.writeBool(
      117,
      f
    );
  }
  f = message.getRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      107,
      f
    );
  }
  f = message.getUseIsoMode();
  if (f) {
    writer.writeBool(
      120,
      f
    );
  }
  f = message.getIsoMode();
  if (f !== 0.0) {
    writer.writeEnum(
      121,
      f
    );
  }
  f = message.getUseExposureMode();
  if (f) {
    writer.writeBool(
      122,
      f
    );
  }
  f = message.getExposureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      123,
      f
    );
  }
  f = message.getUseAwbMode();
  if (f) {
    writer.writeBool(
      124,
      f
    );
  }
  f = message.getAwbMode();
  if (f !== 0.0) {
    writer.writeEnum(
      125,
      f
    );
  }
  f = message.getUseCompensationMode();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
  f = message.getCompensationMode();
  if (f !== 0.0) {
    writer.writeEnum(
      127,
      f
    );
  }
  f = message.getAutonomousAbortMissionOnFailedAction();
  if (f) {
    writer.writeBool(
      109,
      f
    );
  }
  f = message.getEnableFaultBasedDirectRtd();
  if (f) {
    writer.writeBool(
      110,
      f
    );
  }
  f = message.getNeedsNcpgInitialization();
  if (f) {
    writer.writeBool(
      111,
      f
    );
  }
  f = message.getVideoBitrateOverride();
  if (f !== 0) {
    writer.writeInt32(
      112,
      f
    );
  }
  f = message.getNavigationModeOverrideEnabled();
  if (f) {
    writer.writeBool(
      113,
      f
    );
  }
  f = message.getNavigationModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      114,
      f
    );
  }
  f = message.getUseRtxSettings();
  if (f) {
    writer.writeBool(
      115,
      f
    );
  }
  f = message.getRtxSettings();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.serializeBinaryToWriter
    );
  }
  f = message.getNeedsGlobalGraph();
  if (f) {
    writer.writeBool(
      118,
      f
    );
  }
  f = message.getEstimateReferenceNavTNavTakeoff();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getDisableStrobeLights();
  if (f) {
    writer.writeBool(
      128,
      f
    );
  }
  f = message.getSkipUpload();
  if (f) {
    writer.writeBool(
      129,
      f
    );
  }
};


/**
 * optional int64 utime = 7;
 * @return {number}
 */
proto.skills.Mission.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.skills.Mission.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.skills.Mission.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string template_uuid = 3;
 * @return {string}
 */
proto.skills.Mission.prototype.getTemplateUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setTemplateUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Action actions = 4;
 * @return {!Array<!proto.skills.Action>}
 */
proto.skills.Mission.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.skills.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Action, 4));
};


/**
 * @param {!Array<!proto.skills.Action>} value
 * @return {!proto.skills.Mission} returns this
*/
proto.skills.Mission.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.skills.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Action}
 */
proto.skills.Mission.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.skills.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional PostMissionActionEnum.Enum post_mission_action = 5;
 * @return {!proto.skills.PostMissionActionEnum.Enum}
 */
proto.skills.Mission.prototype.getPostMissionAction = function() {
  return /** @type {!proto.skills.PostMissionActionEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skills.PostMissionActionEnum.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setPostMissionAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PostMissionActionEnum.Enum post_failure_action = 9;
 * @return {!proto.skills.PostMissionActionEnum.Enum}
 */
proto.skills.Mission.prototype.getPostFailureAction = function() {
  return /** @type {!proto.skills.PostMissionActionEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.skills.PostMissionActionEnum.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setPostFailureAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional LostConnectionActionEnum.Enum lost_connection_action = 6;
 * @return {!proto.skills.LostConnectionActionEnum.Enum}
 */
proto.skills.Mission.prototype.getLostConnectionAction = function() {
  return /** @type {!proto.skills.LostConnectionActionEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skills.LostConnectionActionEnum.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setLostConnectionAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string scheduled_mission_uuid = 8;
 * @return {string}
 */
proto.skills.Mission.prototype.getScheduledMissionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setScheduledMissionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional MissionSkillEnum.Enum mission_runner_skill = 10;
 * @return {!proto.skills.MissionSkillEnum.Enum}
 */
proto.skills.Mission.prototype.getMissionRunnerSkill = function() {
  return /** @type {!proto.skills.MissionSkillEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.skills.MissionSkillEnum.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setMissionRunnerSkill = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string flight_id = 11;
 * @return {string}
 */
proto.skills.Mission.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ncpg_file_id = 12;
 * @return {string}
 */
proto.skills.Mission.prototype.getNcpgFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setNcpgFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string global_graph_file_id = 13;
 * @return {string}
 */
proto.skills.Mission.prototype.getGlobalGraphFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setGlobalGraphFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool dock_mission = 100;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getDockMission = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setDockMission = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * optional bool auto_start = 101;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getAutoStart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 101, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setAutoStart = function(value) {
  return jspb.Message.setProto3BooleanField(this, 101, value);
};


/**
 * optional bool needs_gps_initialization_move = 102;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getNeedsGpsInitializationMove = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setNeedsGpsInitializationMove = function(value) {
  return jspb.Message.setProto3BooleanField(this, 102, value);
};


/**
 * optional bool return_to_path_on_resume = 103;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getReturnToPathOnResume = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 103, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setReturnToPathOnResume = function(value) {
  return jspb.Message.setProto3BooleanField(this, 103, value);
};


/**
 * optional bool show_skip_ui = 105;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getShowSkipUi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 105, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setShowSkipUi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 105, value);
};


/**
 * optional GpsOriginInfo expected_gps_origin = 106;
 * @return {?proto.skills.GpsOriginInfo}
 */
proto.skills.Mission.prototype.getExpectedGpsOrigin = function() {
  return /** @type{?proto.skills.GpsOriginInfo} */ (
    jspb.Message.getWrapperField(this, proto.skills.GpsOriginInfo, 106));
};


/**
 * @param {?proto.skills.GpsOriginInfo|undefined} value
 * @return {!proto.skills.Mission} returns this
*/
proto.skills.Mission.prototype.setExpectedGpsOrigin = function(value) {
  return jspb.Message.setWrapperField(this, 106, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.clearExpectedGpsOrigin = function() {
  return this.setExpectedGpsOrigin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Mission.prototype.hasExpectedGpsOrigin = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional bool use_recording_mode = 117;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getUseRecordingMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 117, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUseRecordingMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 117, value);
};


/**
 * optional user_camera.RecordingMode.Enum recording_mode = 107;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.skills.Mission.prototype.getRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 107, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 107, value);
};


/**
 * optional bool use_iso_mode = 120;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getUseIsoMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 120, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUseIsoMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 120, value);
};


/**
 * optional user_camera.IsoMode.Enum iso_mode = 121;
 * @return {!proto.user_camera.IsoMode.Enum}
 */
proto.skills.Mission.prototype.getIsoMode = function() {
  return /** @type {!proto.user_camera.IsoMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 121, 0));
};


/**
 * @param {!proto.user_camera.IsoMode.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setIsoMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 121, value);
};


/**
 * optional bool use_exposure_mode = 122;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getUseExposureMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 122, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUseExposureMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 122, value);
};


/**
 * optional user_camera.ExposureMode.Enum exposure_mode = 123;
 * @return {!proto.user_camera.ExposureMode.Enum}
 */
proto.skills.Mission.prototype.getExposureMode = function() {
  return /** @type {!proto.user_camera.ExposureMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 123, 0));
};


/**
 * @param {!proto.user_camera.ExposureMode.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setExposureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 123, value);
};


/**
 * optional bool use_awb_mode = 124;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getUseAwbMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 124, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUseAwbMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 124, value);
};


/**
 * optional user_camera.AwbMode.Enum awb_mode = 125;
 * @return {!proto.user_camera.AwbMode.Enum}
 */
proto.skills.Mission.prototype.getAwbMode = function() {
  return /** @type {!proto.user_camera.AwbMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 125, 0));
};


/**
 * @param {!proto.user_camera.AwbMode.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setAwbMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 125, value);
};


/**
 * optional bool use_compensation_mode = 126;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getUseCompensationMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUseCompensationMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};


/**
 * optional user_camera.CompensationMode.Enum compensation_mode = 127;
 * @return {!proto.user_camera.CompensationMode.Enum}
 */
proto.skills.Mission.prototype.getCompensationMode = function() {
  return /** @type {!proto.user_camera.CompensationMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 127, 0));
};


/**
 * @param {!proto.user_camera.CompensationMode.Enum} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setCompensationMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 127, value);
};


/**
 * optional bool autonomous_abort_mission_on_failed_action = 109;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getAutonomousAbortMissionOnFailedAction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 109, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setAutonomousAbortMissionOnFailedAction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 109, value);
};


/**
 * optional bool enable_fault_based_direct_rtd = 110;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getEnableFaultBasedDirectRtd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 110, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setEnableFaultBasedDirectRtd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 110, value);
};


/**
 * optional bool needs_ncpg_initialization = 111;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getNeedsNcpgInitialization = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 111, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setNeedsNcpgInitialization = function(value) {
  return jspb.Message.setProto3BooleanField(this, 111, value);
};


/**
 * optional int32 video_bitrate_override = 112;
 * @return {number}
 */
proto.skills.Mission.prototype.getVideoBitrateOverride = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 112, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setVideoBitrateOverride = function(value) {
  return jspb.Message.setProto3IntField(this, 112, value);
};


/**
 * optional bool navigation_mode_override_enabled = 113;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getNavigationModeOverrideEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 113, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setNavigationModeOverrideEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 113, value);
};


/**
 * optional flight_deck.NavigationMode.Mode navigation_mode_override = 114;
 * @return {!proto.flight_deck.NavigationMode.Mode}
 */
proto.skills.Mission.prototype.getNavigationModeOverride = function() {
  return /** @type {!proto.flight_deck.NavigationMode.Mode} */ (jspb.Message.getFieldWithDefault(this, 114, 0));
};


/**
 * @param {!proto.flight_deck.NavigationMode.Mode} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setNavigationModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 114, value);
};


/**
 * optional bool use_rtx_settings = 115;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getUseRtxSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 115, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setUseRtxSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 115, value);
};


/**
 * optional flight_deck.ReturnSettings rtx_settings = 116;
 * @return {?proto.flight_deck.ReturnSettings}
 */
proto.skills.Mission.prototype.getRtxSettings = function() {
  return /** @type{?proto.flight_deck.ReturnSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings, 116));
};


/**
 * @param {?proto.flight_deck.ReturnSettings|undefined} value
 * @return {!proto.skills.Mission} returns this
*/
proto.skills.Mission.prototype.setRtxSettings = function(value) {
  return jspb.Message.setWrapperField(this, 116, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.clearRtxSettings = function() {
  return this.setRtxSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Mission.prototype.hasRtxSettings = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional bool needs_global_graph = 118;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getNeedsGlobalGraph = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 118, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setNeedsGlobalGraph = function(value) {
  return jspb.Message.setProto3BooleanField(this, 118, value);
};


/**
 * optional body.TransPb estimate_reference_nav_T_nav_takeoff = 119;
 * @return {?proto.body.TransPb}
 */
proto.skills.Mission.prototype.getEstimateReferenceNavTNavTakeoff = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 119));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.skills.Mission} returns this
*/
proto.skills.Mission.prototype.setEstimateReferenceNavTNavTakeoff = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.clearEstimateReferenceNavTNavTakeoff = function() {
  return this.setEstimateReferenceNavTNavTakeoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Mission.prototype.hasEstimateReferenceNavTNavTakeoff = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional bool disable_strobe_lights = 128;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getDisableStrobeLights = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 128, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setDisableStrobeLights = function(value) {
  return jspb.Message.setProto3BooleanField(this, 128, value);
};


/**
 * optional bool skip_upload = 129;
 * @return {boolean}
 */
proto.skills.Mission.prototype.getSkipUpload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 129, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.Mission} returns this
 */
proto.skills.Mission.prototype.setSkipUpload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 129, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GpsOriginInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GpsOriginInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GpsOriginInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GpsOriginInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
lon: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
gpsAltitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
gpsHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GpsOriginInfo}
 */
proto.skills.GpsOriginInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GpsOriginInfo;
  return proto.skills.GpsOriginInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GpsOriginInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GpsOriginInfo}
 */
proto.skills.GpsOriginInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGpsAltitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGpsHeading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GpsOriginInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GpsOriginInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GpsOriginInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GpsOriginInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getGpsAltitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getGpsHeading();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.skills.GpsOriginInfo.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GpsOriginInfo} returns this
 */
proto.skills.GpsOriginInfo.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lon = 2;
 * @return {number}
 */
proto.skills.GpsOriginInfo.prototype.getLon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GpsOriginInfo} returns this
 */
proto.skills.GpsOriginInfo.prototype.setLon = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double gps_altitude = 3;
 * @return {number}
 */
proto.skills.GpsOriginInfo.prototype.getGpsAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GpsOriginInfo} returns this
 */
proto.skills.GpsOriginInfo.prototype.setGpsAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double gps_heading = 4;
 * @return {number}
 */
proto.skills.GpsOriginInfo.prototype.getGpsHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GpsOriginInfo} returns this
 */
proto.skills.GpsOriginInfo.prototype.setGpsHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PostMissionActionEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PostMissionActionEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PostMissionActionEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PostMissionActionEnum.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PostMissionActionEnum}
 */
proto.skills.PostMissionActionEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PostMissionActionEnum;
  return proto.skills.PostMissionActionEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PostMissionActionEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PostMissionActionEnum}
 */
proto.skills.PostMissionActionEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PostMissionActionEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PostMissionActionEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PostMissionActionEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PostMissionActionEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.PostMissionActionEnum.Enum = {
  UNKNOWN: 0,
  UNSPECIFIED: 0,
  LAND_IN_PLACE: 1,
  LAND_AT_HOME: 2,
  RETURN_TO_HOME: 3,
  LAND_AT_DOCK: 4,
  DIRECT_RETURN_AND_LAND_AT_DOCK: 7,
  RETURN_TO_START: 5,
  HOVER: 6,
  HAND_CARRY_DISABLE: 8,
  RETURN_TO_DOCK: 9,
  DIRECT_RETURN_TO_DOCK: 10
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionSkillEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionSkillEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionSkillEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionSkillEnum.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionSkillEnum}
 */
proto.skills.MissionSkillEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionSkillEnum;
  return proto.skills.MissionSkillEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionSkillEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionSkillEnum}
 */
proto.skills.MissionSkillEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionSkillEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionSkillEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionSkillEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionSkillEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.MissionSkillEnum.Enum = {
  MISSION_RUNNER: 0,
  SURFACE_SCAN: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.LostConnectionActionEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.LostConnectionActionEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.LostConnectionActionEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LostConnectionActionEnum.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.LostConnectionActionEnum}
 */
proto.skills.LostConnectionActionEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.LostConnectionActionEnum;
  return proto.skills.LostConnectionActionEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.LostConnectionActionEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.LostConnectionActionEnum}
 */
proto.skills.LostConnectionActionEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.LostConnectionActionEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.LostConnectionActionEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.LostConnectionActionEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LostConnectionActionEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.LostConnectionActionEnum.Enum = {
  UNKNOWN: 0,
  UNSPECIFIED: 0,
  RETURN_TO_HOME: 1,
  CONTINUE: 2,
  HOVER: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
uuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
requestAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionResponse}
 */
proto.skills.MissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionResponse;
  return proto.skills.MissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionResponse}
 */
proto.skills.MissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestAccepted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestAccepted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.MissionResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionResponse} returns this
 */
proto.skills.MissionResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.skills.MissionResponse.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResponse} returns this
 */
proto.skills.MissionResponse.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool request_accepted = 3;
 * @return {boolean}
 */
proto.skills.MissionResponse.prototype.getRequestAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionResponse} returns this
 */
proto.skills.MissionResponse.prototype.setRequestAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
actionUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
actionKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
args: (f = msg.getArgs()) && proto.skills.ActionArgs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.Action}
 */
proto.skills.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.Action;
  return proto.skills.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.Action}
 */
proto.skills.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionKey(value);
      break;
    case 3:
      var value = new proto.skills.ActionArgs;
      reader.readMessage(value,proto.skills.ActionArgs.deserializeBinaryFromReader);
      msg.setArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getArgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.ActionArgs.serializeBinaryToWriter
    );
  }
};


/**
 * optional string action_uuid = 1;
 * @return {string}
 */
proto.skills.Action.prototype.getActionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Action} returns this
 */
proto.skills.Action.prototype.setActionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action_key = 2;
 * @return {string}
 */
proto.skills.Action.prototype.getActionKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Action} returns this
 */
proto.skills.Action.prototype.setActionKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActionArgs args = 3;
 * @return {?proto.skills.ActionArgs}
 */
proto.skills.Action.prototype.getArgs = function() {
  return /** @type{?proto.skills.ActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.ActionArgs, 3));
};


/**
 * @param {?proto.skills.ActionArgs|undefined} value
 * @return {!proto.skills.Action} returns this
*/
proto.skills.Action.prototype.setArgs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Action} returns this
 */
proto.skills.Action.prototype.clearArgs = function() {
  return this.setArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Action.prototype.hasArgs = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.ActionArgs.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,900,901,902,904,905,906,907]];

/**
 * @enum {number}
 */
proto.skills.ActionArgs.ArgsCase = {
  ARGS_NOT_SET: 0,
  JSON: 1,
  GOTO_GPS_WAYPOINT: 2,
  GOTO_NAV_WAYPOINT: 3,
  TAKE_PHOTO: 4,
  SURFACE_SCAN_SKILL: 5,
  START_VIDEO: 6,
  STOP_VIDEO: 7,
  SWITCH_RECORDING_MODE: 8,
  TIMED_WAIT: 9,
  SEQUENCE: 10,
  ORBIT_POI_ACTION: 11,
  TAKE_PANORAMA: 12,
  KEYFRAME_ACTION: 13,
  GOTO_WAYPOINT: 14,
  SET_OBSTACLE_AVOIDANCE: 15,
  INITIALIZE_GPS: 16,
  CONDITIONAL: 17,
  LOOPER: 18,
  WITHIN_DISTANCE: 19,
  UNIFIED_FOLLOW: 20,
  INVERTER: 21,
  TOGGLE_PHOTO_INTERVAL_MODE: 22,
  WIFI_SCAN: 23,
  ENABLE_STROBE_LIGHTS: 24,
  DISABLE_STROBE_LIGHTS: 25,
  SPOOF_JOYSTICKS_INPUTS: 26,
  UPDATE_JOYSTICKS_SETTINGS: 27,
  UPDATE_SHAPE_MAP: 28,
  SPOOF_OBSTACLE: 29,
  SPOOF_DISTURBANCE_ACTION: 30,
  UPDATE_VEHICLE_SETTINGS: 31,
  WAIT_FOR_VEHICLE_STOP: 32,
  PRECOMPUTED_SUBJECT_SPOOF_ACTION: 33,
  MODIFY_SIM_STATE_ESTIMATION: 34,
  GOTO_AND_ORBIT_POI: 35,
  ACQUIRE_GPS: 36,
  SET_FAULT_OVERRIDE: 37,
  E_LAND: 38,
  TRAJECTORY_INSPECT: 39,
  TOGGLE_TARGET_POINT_EXPOSURE: 40,
  TOGGLE_SEMANTIC_AIM: 41,
  SPOOF_BATTERY_LEVEL: 42,
  NCPG_CONTROL: 43,
  REGISTER_COMPLIMENTARY_ACTIONS: 44,
  TRACK_IN_PLACE: 45,
  GLOBAL_GRAPH_CONTROL: 46,
  OVERRIDE_GPS_QUALITY: 47,
  INIT_GLOBAL_MAP: 48,
  STOP_FOR_PHOTO_INSPECT: 49,
  SPOOF_INITIAL_GPS_HEADING: 50,
  TRACK_LANDING_PAD_REQUEST: 51,
  APRILTAG_SET_ID: 52,
  DOCK_GPS_ESTIMATOR: 53,
  G47_COMMAND_OVERRIDE: 54,
  REQUEST_TAKEOFF_PROFILE: 55,
  DUMMY: 900,
  TEST_DESIRED_VELOCITY: 901,
  TEST_TRACK_TRAJECTORY: 902,
  ATV_SUBJECT_SPOOF_ACTION: 904,
  SKI_SUBJECT_SPOOF_ACTION: 905,
  SET_GIMBAL: 906,
  UPDATE_DOCK_LOCATION_ACTION: 907
};

/**
 * @return {proto.skills.ActionArgs.ArgsCase}
 */
proto.skills.ActionArgs.prototype.getArgsCase = function() {
  return /** @type {proto.skills.ActionArgs.ArgsCase} */(jspb.Message.computeOneofCase(this, proto.skills.ActionArgs.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
json: jspb.Message.getFieldWithDefault(msg, 1, ""),
gotoGpsWaypoint: (f = msg.getGotoGpsWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionArgs.toObject(includeInstance, f),
gotoNavWaypoint: (f = msg.getGotoNavWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionArgs.toObject(includeInstance, f),
takePhoto: (f = msg.getTakePhoto()) && pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionArgs.toObject(includeInstance, f),
surfaceScanSkill: (f = msg.getSurfaceScanSkill()) && pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionArgs.toObject(includeInstance, f),
startVideo: (f = msg.getStartVideo()) && pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionArgs.toObject(includeInstance, f),
stopVideo: (f = msg.getStopVideo()) && pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionArgs.toObject(includeInstance, f),
switchRecordingMode: (f = msg.getSwitchRecordingMode()) && pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionArgs.toObject(includeInstance, f),
timedWait: (f = msg.getTimedWait()) && pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionArgs.toObject(includeInstance, f),
sequence: (f = msg.getSequence()) && proto.skills.SequenceActionArgs.toObject(includeInstance, f),
orbitPoiAction: (f = msg.getOrbitPoiAction()) && pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionArgs.toObject(includeInstance, f),
takePanorama: (f = msg.getTakePanorama()) && pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionArgs.toObject(includeInstance, f),
keyframeAction: (f = msg.getKeyframeAction()) && pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionArgs.toObject(includeInstance, f),
gotoWaypoint: (f = msg.getGotoWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionArgs.toObject(includeInstance, f),
setObstacleAvoidance: (f = msg.getSetObstacleAvoidance()) && pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionArgs.toObject(includeInstance, f),
initializeGps: (f = msg.getInitializeGps()) && pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionArgs.toObject(includeInstance, f),
conditional: (f = msg.getConditional()) && pbtypes_vehicle_skills_actions_conditional_conditional_pb.ConditionalActionArgs.toObject(includeInstance, f),
looper: (f = msg.getLooper()) && proto.skills.LooperActionArgs.toObject(includeInstance, f),
withinDistance: (f = msg.getWithinDistance()) && pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionArgs.toObject(includeInstance, f),
unifiedFollow: (f = msg.getUnifiedFollow()) && pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionArgs.toObject(includeInstance, f),
inverter: (f = msg.getInverter()) && proto.skills.InverterActionArgs.toObject(includeInstance, f),
togglePhotoIntervalMode: (f = msg.getTogglePhotoIntervalMode()) && pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionArgs.toObject(includeInstance, f),
wifiScan: (f = msg.getWifiScan()) && pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionArgs.toObject(includeInstance, f),
enableStrobeLights: (f = msg.getEnableStrobeLights()) && proto.skills.EnableStrobeLightsActionArgs.toObject(includeInstance, f),
disableStrobeLights: (f = msg.getDisableStrobeLights()) && proto.skills.DisableStrobeLightsActionArgs.toObject(includeInstance, f),
spoofJoysticksInputs: (f = msg.getSpoofJoysticksInputs()) && pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionArgs.toObject(includeInstance, f),
updateJoysticksSettings: (f = msg.getUpdateJoysticksSettings()) && pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionArgs.toObject(includeInstance, f),
updateShapeMap: (f = msg.getUpdateShapeMap()) && pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionArgs.toObject(includeInstance, f),
spoofObstacle: (f = msg.getSpoofObstacle()) && pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionArgs.toObject(includeInstance, f),
spoofDisturbanceAction: (f = msg.getSpoofDisturbanceAction()) && pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionActionArgs.toObject(includeInstance, f),
updateVehicleSettings: (f = msg.getUpdateVehicleSettings()) && pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionArgs.toObject(includeInstance, f),
waitForVehicleStop: (f = msg.getWaitForVehicleStop()) && pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionArgs.toObject(includeInstance, f),
precomputedSubjectSpoofAction: (f = msg.getPrecomputedSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionArgs.toObject(includeInstance, f),
modifySimStateEstimation: (f = msg.getModifySimStateEstimation()) && pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionArgs.toObject(includeInstance, f),
gotoAndOrbitPoi: (f = msg.getGotoAndOrbitPoi()) && pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiActionArgs.toObject(includeInstance, f),
acquireGps: (f = msg.getAcquireGps()) && pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionArgs.toObject(includeInstance, f),
setFaultOverride: (f = msg.getSetFaultOverride()) && pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionArgs.toObject(includeInstance, f),
eLand: (f = msg.getELand()) && pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionArgs.toObject(includeInstance, f),
trajectoryInspect: (f = msg.getTrajectoryInspect()) && pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionArgs.toObject(includeInstance, f),
toggleTargetPointExposure: (f = msg.getToggleTargetPointExposure()) && pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionArgs.toObject(includeInstance, f),
toggleSemanticAim: (f = msg.getToggleSemanticAim()) && pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionArgs.toObject(includeInstance, f),
spoofBatteryLevel: (f = msg.getSpoofBatteryLevel()) && pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionArgs.toObject(includeInstance, f),
ncpgControl: (f = msg.getNcpgControl()) && pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionArgs.toObject(includeInstance, f),
registerComplimentaryActions: (f = msg.getRegisterComplimentaryActions()) && pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionArgs.toObject(includeInstance, f),
trackInPlace: (f = msg.getTrackInPlace()) && pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionArgs.toObject(includeInstance, f),
globalGraphControl: (f = msg.getGlobalGraphControl()) && pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionArgs.toObject(includeInstance, f),
overrideGpsQuality: (f = msg.getOverrideGpsQuality()) && pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionArgs.toObject(includeInstance, f),
initGlobalMap: (f = msg.getInitGlobalMap()) && pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionArgs.toObject(includeInstance, f),
stopForPhotoInspect: (f = msg.getStopForPhotoInspect()) && pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionArgs.toObject(includeInstance, f),
spoofInitialGpsHeading: (f = msg.getSpoofInitialGpsHeading()) && pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionArgs.toObject(includeInstance, f),
trackLandingPadRequest: (f = msg.getTrackLandingPadRequest()) && pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionArgs.toObject(includeInstance, f),
apriltagSetId: (f = msg.getApriltagSetId()) && pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionArgs.toObject(includeInstance, f),
dockGpsEstimator: (f = msg.getDockGpsEstimator()) && pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionArgs.toObject(includeInstance, f),
g47CommandOverride: (f = msg.getG47CommandOverride()) && pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionArgs.toObject(includeInstance, f),
requestTakeoffProfile: (f = msg.getRequestTakeoffProfile()) && pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionArgs.toObject(includeInstance, f),
dummy: (f = msg.getDummy()) && pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionArgs.toObject(includeInstance, f),
testDesiredVelocity: (f = msg.getTestDesiredVelocity()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionArgs.toObject(includeInstance, f),
testTrackTrajectory: (f = msg.getTestTrackTrajectory()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionArgs.toObject(includeInstance, f),
atvSubjectSpoofAction: (f = msg.getAtvSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionArgs.toObject(includeInstance, f),
skiSubjectSpoofAction: (f = msg.getSkiSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionArgs.toObject(includeInstance, f),
setGimbal: (f = msg.getSetGimbal()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationArgs.toObject(includeInstance, f),
updateDockLocationAction: (f = msg.getUpdateDockLocationAction()) && pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionArgs.toObject(includeInstance, f),
photoOnCompletion: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
isSkippable: jspb.Message.getBooleanFieldWithDefault(msg, 101, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ActionArgs}
 */
proto.skills.ActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ActionArgs;
  return proto.skills.ActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ActionArgs}
 */
proto.skills.ActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionArgs.deserializeBinaryFromReader);
      msg.setGotoGpsWaypoint(value);
      break;
    case 3:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionArgs.deserializeBinaryFromReader);
      msg.setGotoNavWaypoint(value);
      break;
    case 4:
      var value = new pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionArgs.deserializeBinaryFromReader);
      msg.setTakePhoto(value);
      break;
    case 5:
      var value = new pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionArgs.deserializeBinaryFromReader);
      msg.setSurfaceScanSkill(value);
      break;
    case 6:
      var value = new pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionArgs.deserializeBinaryFromReader);
      msg.setStartVideo(value);
      break;
    case 7:
      var value = new pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionArgs.deserializeBinaryFromReader);
      msg.setStopVideo(value);
      break;
    case 8:
      var value = new pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionArgs.deserializeBinaryFromReader);
      msg.setSwitchRecordingMode(value);
      break;
    case 9:
      var value = new pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionArgs.deserializeBinaryFromReader);
      msg.setTimedWait(value);
      break;
    case 10:
      var value = new proto.skills.SequenceActionArgs;
      reader.readMessage(value,proto.skills.SequenceActionArgs.deserializeBinaryFromReader);
      msg.setSequence(value);
      break;
    case 11:
      var value = new pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionArgs.deserializeBinaryFromReader);
      msg.setOrbitPoiAction(value);
      break;
    case 12:
      var value = new pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionArgs.deserializeBinaryFromReader);
      msg.setTakePanorama(value);
      break;
    case 13:
      var value = new pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionArgs.deserializeBinaryFromReader);
      msg.setKeyframeAction(value);
      break;
    case 14:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionArgs.deserializeBinaryFromReader);
      msg.setGotoWaypoint(value);
      break;
    case 15:
      var value = new pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionArgs.deserializeBinaryFromReader);
      msg.setSetObstacleAvoidance(value);
      break;
    case 16:
      var value = new pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionArgs.deserializeBinaryFromReader);
      msg.setInitializeGps(value);
      break;
    case 17:
      var value = new pbtypes_vehicle_skills_actions_conditional_conditional_pb.ConditionalActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_conditional_conditional_pb.ConditionalActionArgs.deserializeBinaryFromReader);
      msg.setConditional(value);
      break;
    case 18:
      var value = new proto.skills.LooperActionArgs;
      reader.readMessage(value,proto.skills.LooperActionArgs.deserializeBinaryFromReader);
      msg.setLooper(value);
      break;
    case 19:
      var value = new pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionArgs.deserializeBinaryFromReader);
      msg.setWithinDistance(value);
      break;
    case 20:
      var value = new pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionArgs.deserializeBinaryFromReader);
      msg.setUnifiedFollow(value);
      break;
    case 21:
      var value = new proto.skills.InverterActionArgs;
      reader.readMessage(value,proto.skills.InverterActionArgs.deserializeBinaryFromReader);
      msg.setInverter(value);
      break;
    case 22:
      var value = new pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionArgs.deserializeBinaryFromReader);
      msg.setTogglePhotoIntervalMode(value);
      break;
    case 23:
      var value = new pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionArgs.deserializeBinaryFromReader);
      msg.setWifiScan(value);
      break;
    case 24:
      var value = new proto.skills.EnableStrobeLightsActionArgs;
      reader.readMessage(value,proto.skills.EnableStrobeLightsActionArgs.deserializeBinaryFromReader);
      msg.setEnableStrobeLights(value);
      break;
    case 25:
      var value = new proto.skills.DisableStrobeLightsActionArgs;
      reader.readMessage(value,proto.skills.DisableStrobeLightsActionArgs.deserializeBinaryFromReader);
      msg.setDisableStrobeLights(value);
      break;
    case 26:
      var value = new pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionArgs.deserializeBinaryFromReader);
      msg.setSpoofJoysticksInputs(value);
      break;
    case 27:
      var value = new pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionArgs.deserializeBinaryFromReader);
      msg.setUpdateJoysticksSettings(value);
      break;
    case 28:
      var value = new pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionArgs.deserializeBinaryFromReader);
      msg.setUpdateShapeMap(value);
      break;
    case 29:
      var value = new pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionArgs.deserializeBinaryFromReader);
      msg.setSpoofObstacle(value);
      break;
    case 30:
      var value = new pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionActionArgs.deserializeBinaryFromReader);
      msg.setSpoofDisturbanceAction(value);
      break;
    case 31:
      var value = new pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionArgs.deserializeBinaryFromReader);
      msg.setUpdateVehicleSettings(value);
      break;
    case 32:
      var value = new pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionArgs.deserializeBinaryFromReader);
      msg.setWaitForVehicleStop(value);
      break;
    case 33:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionArgs.deserializeBinaryFromReader);
      msg.setPrecomputedSubjectSpoofAction(value);
      break;
    case 34:
      var value = new pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionArgs.deserializeBinaryFromReader);
      msg.setModifySimStateEstimation(value);
      break;
    case 35:
      var value = new pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiActionArgs.deserializeBinaryFromReader);
      msg.setGotoAndOrbitPoi(value);
      break;
    case 36:
      var value = new pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionArgs.deserializeBinaryFromReader);
      msg.setAcquireGps(value);
      break;
    case 37:
      var value = new pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionArgs.deserializeBinaryFromReader);
      msg.setSetFaultOverride(value);
      break;
    case 38:
      var value = new pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionArgs.deserializeBinaryFromReader);
      msg.setELand(value);
      break;
    case 39:
      var value = new pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionArgs.deserializeBinaryFromReader);
      msg.setTrajectoryInspect(value);
      break;
    case 40:
      var value = new pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionArgs.deserializeBinaryFromReader);
      msg.setToggleTargetPointExposure(value);
      break;
    case 41:
      var value = new pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionArgs.deserializeBinaryFromReader);
      msg.setToggleSemanticAim(value);
      break;
    case 42:
      var value = new pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionArgs.deserializeBinaryFromReader);
      msg.setSpoofBatteryLevel(value);
      break;
    case 43:
      var value = new pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionArgs.deserializeBinaryFromReader);
      msg.setNcpgControl(value);
      break;
    case 44:
      var value = new pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionArgs.deserializeBinaryFromReader);
      msg.setRegisterComplimentaryActions(value);
      break;
    case 45:
      var value = new pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionArgs.deserializeBinaryFromReader);
      msg.setTrackInPlace(value);
      break;
    case 46:
      var value = new pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionArgs.deserializeBinaryFromReader);
      msg.setGlobalGraphControl(value);
      break;
    case 47:
      var value = new pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionArgs.deserializeBinaryFromReader);
      msg.setOverrideGpsQuality(value);
      break;
    case 48:
      var value = new pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionArgs.deserializeBinaryFromReader);
      msg.setInitGlobalMap(value);
      break;
    case 49:
      var value = new pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionArgs.deserializeBinaryFromReader);
      msg.setStopForPhotoInspect(value);
      break;
    case 50:
      var value = new pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionArgs.deserializeBinaryFromReader);
      msg.setSpoofInitialGpsHeading(value);
      break;
    case 51:
      var value = new pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionArgs.deserializeBinaryFromReader);
      msg.setTrackLandingPadRequest(value);
      break;
    case 52:
      var value = new pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionArgs.deserializeBinaryFromReader);
      msg.setApriltagSetId(value);
      break;
    case 53:
      var value = new pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionArgs.deserializeBinaryFromReader);
      msg.setDockGpsEstimator(value);
      break;
    case 54:
      var value = new pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionArgs.deserializeBinaryFromReader);
      msg.setG47CommandOverride(value);
      break;
    case 55:
      var value = new pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionArgs.deserializeBinaryFromReader);
      msg.setRequestTakeoffProfile(value);
      break;
    case 900:
      var value = new pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionArgs.deserializeBinaryFromReader);
      msg.setDummy(value);
      break;
    case 901:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionArgs.deserializeBinaryFromReader);
      msg.setTestDesiredVelocity(value);
      break;
    case 902:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionArgs.deserializeBinaryFromReader);
      msg.setTestTrackTrajectory(value);
      break;
    case 904:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionArgs.deserializeBinaryFromReader);
      msg.setAtvSubjectSpoofAction(value);
      break;
    case 905:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionArgs.deserializeBinaryFromReader);
      msg.setSkiSubjectSpoofAction(value);
      break;
    case 906:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationArgs.deserializeBinaryFromReader);
      msg.setSetGimbal(value);
      break;
    case 907:
      var value = new pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionArgs.deserializeBinaryFromReader);
      msg.setUpdateDockLocationAction(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhotoOnCompletion(value);
      break;
    case 101:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSkippable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGotoGpsWaypoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getGotoNavWaypoint();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTakePhoto();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSurfaceScanSkill();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getStartVideo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getStopVideo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSwitchRecordingMode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTimedWait();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSequence();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.skills.SequenceActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getOrbitPoiAction();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTakePanorama();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getKeyframeAction();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getGotoWaypoint();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSetObstacleAvoidance();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getInitializeGps();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getConditional();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      pbtypes_vehicle_skills_actions_conditional_conditional_pb.ConditionalActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getLooper();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.skills.LooperActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getWithinDistance();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getUnifiedFollow();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getInverter();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.skills.InverterActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTogglePhotoIntervalMode();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getWifiScan();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getEnableStrobeLights();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.skills.EnableStrobeLightsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getDisableStrobeLights();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.skills.DisableStrobeLightsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSpoofJoysticksInputs();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getUpdateJoysticksSettings();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getUpdateShapeMap();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSpoofObstacle();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSpoofDisturbanceAction();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getUpdateVehicleSettings();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getWaitForVehicleStop();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getPrecomputedSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getModifySimStateEstimation();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getGotoAndOrbitPoi();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getAcquireGps();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSetFaultOverride();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getELand();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTrajectoryInspect();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getToggleTargetPointExposure();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getToggleSemanticAim();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSpoofBatteryLevel();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getNcpgControl();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getRegisterComplimentaryActions();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTrackInPlace();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getGlobalGraphControl();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getOverrideGpsQuality();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getInitGlobalMap();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getStopForPhotoInspect();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSpoofInitialGpsHeading();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTrackLandingPadRequest();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getApriltagSetId();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getDockGpsEstimator();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getG47CommandOverride();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getRequestTakeoffProfile();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getDummy();
  if (f != null) {
    writer.writeMessage(
      900,
      f,
      pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTestDesiredVelocity();
  if (f != null) {
    writer.writeMessage(
      901,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTestTrackTrajectory();
  if (f != null) {
    writer.writeMessage(
      902,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getAtvSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      904,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSkiSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      905,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSetGimbal();
  if (f != null) {
    writer.writeMessage(
      906,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationArgs.serializeBinaryToWriter
    );
  }
  f = message.getUpdateDockLocationAction();
  if (f != null) {
    writer.writeMessage(
      907,
      f,
      pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getPhotoOnCompletion();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getIsSkippable();
  if (f) {
    writer.writeBool(
      101,
      f
    );
  }
};


/**
 * optional string json = 1;
 * @return {string}
 */
proto.skills.ActionArgs.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.setJson = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearJson = function() {
  return jspb.Message.setOneofField(this, 1, proto.skills.ActionArgs.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasJson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GotoGpsWaypointActionArgs goto_gps_waypoint = 2;
 * @return {?proto.skills.GotoGpsWaypointActionArgs}
 */
proto.skills.ActionArgs.prototype.getGotoGpsWaypoint = function() {
  return /** @type{?proto.skills.GotoGpsWaypointActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionArgs, 2));
};


/**
 * @param {?proto.skills.GotoGpsWaypointActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setGotoGpsWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearGotoGpsWaypoint = function() {
  return this.setGotoGpsWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasGotoGpsWaypoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GotoNavWaypointActionArgs goto_nav_waypoint = 3;
 * @return {?proto.skills.GotoNavWaypointActionArgs}
 */
proto.skills.ActionArgs.prototype.getGotoNavWaypoint = function() {
  return /** @type{?proto.skills.GotoNavWaypointActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionArgs, 3));
};


/**
 * @param {?proto.skills.GotoNavWaypointActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setGotoNavWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearGotoNavWaypoint = function() {
  return this.setGotoNavWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasGotoNavWaypoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TakePhotoActionArgs take_photo = 4;
 * @return {?proto.skills.TakePhotoActionArgs}
 */
proto.skills.ActionArgs.prototype.getTakePhoto = function() {
  return /** @type{?proto.skills.TakePhotoActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionArgs, 4));
};


/**
 * @param {?proto.skills.TakePhotoActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTakePhoto = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTakePhoto = function() {
  return this.setTakePhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTakePhoto = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SurfaceScanActionArgs surface_scan_skill = 5;
 * @return {?proto.skills.SurfaceScanActionArgs}
 */
proto.skills.ActionArgs.prototype.getSurfaceScanSkill = function() {
  return /** @type{?proto.skills.SurfaceScanActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionArgs, 5));
};


/**
 * @param {?proto.skills.SurfaceScanActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSurfaceScanSkill = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSurfaceScanSkill = function() {
  return this.setSurfaceScanSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSurfaceScanSkill = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional StartVideoActionArgs start_video = 6;
 * @return {?proto.skills.StartVideoActionArgs}
 */
proto.skills.ActionArgs.prototype.getStartVideo = function() {
  return /** @type{?proto.skills.StartVideoActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionArgs, 6));
};


/**
 * @param {?proto.skills.StartVideoActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setStartVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearStartVideo = function() {
  return this.setStartVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasStartVideo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional StopVideoActionArgs stop_video = 7;
 * @return {?proto.skills.StopVideoActionArgs}
 */
proto.skills.ActionArgs.prototype.getStopVideo = function() {
  return /** @type{?proto.skills.StopVideoActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionArgs, 7));
};


/**
 * @param {?proto.skills.StopVideoActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setStopVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearStopVideo = function() {
  return this.setStopVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasStopVideo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SwitchRecordingModeActionArgs switch_recording_mode = 8;
 * @return {?proto.skills.SwitchRecordingModeActionArgs}
 */
proto.skills.ActionArgs.prototype.getSwitchRecordingMode = function() {
  return /** @type{?proto.skills.SwitchRecordingModeActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionArgs, 8));
};


/**
 * @param {?proto.skills.SwitchRecordingModeActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSwitchRecordingMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSwitchRecordingMode = function() {
  return this.setSwitchRecordingMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSwitchRecordingMode = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TimedWaitActionArgs timed_wait = 9;
 * @return {?proto.skills.TimedWaitActionArgs}
 */
proto.skills.ActionArgs.prototype.getTimedWait = function() {
  return /** @type{?proto.skills.TimedWaitActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionArgs, 9));
};


/**
 * @param {?proto.skills.TimedWaitActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTimedWait = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTimedWait = function() {
  return this.setTimedWait(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTimedWait = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SequenceActionArgs sequence = 10;
 * @return {?proto.skills.SequenceActionArgs}
 */
proto.skills.ActionArgs.prototype.getSequence = function() {
  return /** @type{?proto.skills.SequenceActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.SequenceActionArgs, 10));
};


/**
 * @param {?proto.skills.SequenceActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSequence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSequence = function() {
  return this.setSequence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OrbitPoiActionArgs orbit_poi_action = 11;
 * @return {?proto.skills.OrbitPoiActionArgs}
 */
proto.skills.ActionArgs.prototype.getOrbitPoiAction = function() {
  return /** @type{?proto.skills.OrbitPoiActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionArgs, 11));
};


/**
 * @param {?proto.skills.OrbitPoiActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setOrbitPoiAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearOrbitPoiAction = function() {
  return this.setOrbitPoiAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasOrbitPoiAction = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional TakePanoramaActionArgs take_panorama = 12;
 * @return {?proto.skills.TakePanoramaActionArgs}
 */
proto.skills.ActionArgs.prototype.getTakePanorama = function() {
  return /** @type{?proto.skills.TakePanoramaActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionArgs, 12));
};


/**
 * @param {?proto.skills.TakePanoramaActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTakePanorama = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTakePanorama = function() {
  return this.setTakePanorama(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTakePanorama = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional KeyframeActionArgs keyframe_action = 13;
 * @return {?proto.skills.KeyframeActionArgs}
 */
proto.skills.ActionArgs.prototype.getKeyframeAction = function() {
  return /** @type{?proto.skills.KeyframeActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionArgs, 13));
};


/**
 * @param {?proto.skills.KeyframeActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setKeyframeAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearKeyframeAction = function() {
  return this.setKeyframeAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasKeyframeAction = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional GotoWaypointActionArgs goto_waypoint = 14;
 * @return {?proto.skills.GotoWaypointActionArgs}
 */
proto.skills.ActionArgs.prototype.getGotoWaypoint = function() {
  return /** @type{?proto.skills.GotoWaypointActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionArgs, 14));
};


/**
 * @param {?proto.skills.GotoWaypointActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setGotoWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearGotoWaypoint = function() {
  return this.setGotoWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasGotoWaypoint = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional SetObstacleAvoidanceActionArgs set_obstacle_avoidance = 15;
 * @return {?proto.skills.SetObstacleAvoidanceActionArgs}
 */
proto.skills.ActionArgs.prototype.getSetObstacleAvoidance = function() {
  return /** @type{?proto.skills.SetObstacleAvoidanceActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionArgs, 15));
};


/**
 * @param {?proto.skills.SetObstacleAvoidanceActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSetObstacleAvoidance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSetObstacleAvoidance = function() {
  return this.setSetObstacleAvoidance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSetObstacleAvoidance = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional InitializeGpsActionArgs initialize_gps = 16;
 * @return {?proto.skills.InitializeGpsActionArgs}
 */
proto.skills.ActionArgs.prototype.getInitializeGps = function() {
  return /** @type{?proto.skills.InitializeGpsActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionArgs, 16));
};


/**
 * @param {?proto.skills.InitializeGpsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setInitializeGps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearInitializeGps = function() {
  return this.setInitializeGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasInitializeGps = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ConditionalActionArgs conditional = 17;
 * @return {?proto.skills.ConditionalActionArgs}
 */
proto.skills.ActionArgs.prototype.getConditional = function() {
  return /** @type{?proto.skills.ConditionalActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_conditional_conditional_pb.ConditionalActionArgs, 17));
};


/**
 * @param {?proto.skills.ConditionalActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setConditional = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearConditional = function() {
  return this.setConditional(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasConditional = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional LooperActionArgs looper = 18;
 * @return {?proto.skills.LooperActionArgs}
 */
proto.skills.ActionArgs.prototype.getLooper = function() {
  return /** @type{?proto.skills.LooperActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.LooperActionArgs, 18));
};


/**
 * @param {?proto.skills.LooperActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setLooper = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearLooper = function() {
  return this.setLooper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasLooper = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional WithinDistanceActionArgs within_distance = 19;
 * @return {?proto.skills.WithinDistanceActionArgs}
 */
proto.skills.ActionArgs.prototype.getWithinDistance = function() {
  return /** @type{?proto.skills.WithinDistanceActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionArgs, 19));
};


/**
 * @param {?proto.skills.WithinDistanceActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setWithinDistance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearWithinDistance = function() {
  return this.setWithinDistance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasWithinDistance = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional UnifiedFollowActionArgs unified_follow = 20;
 * @return {?proto.skills.UnifiedFollowActionArgs}
 */
proto.skills.ActionArgs.prototype.getUnifiedFollow = function() {
  return /** @type{?proto.skills.UnifiedFollowActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionArgs, 20));
};


/**
 * @param {?proto.skills.UnifiedFollowActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setUnifiedFollow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearUnifiedFollow = function() {
  return this.setUnifiedFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasUnifiedFollow = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional InverterActionArgs inverter = 21;
 * @return {?proto.skills.InverterActionArgs}
 */
proto.skills.ActionArgs.prototype.getInverter = function() {
  return /** @type{?proto.skills.InverterActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.InverterActionArgs, 21));
};


/**
 * @param {?proto.skills.InverterActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setInverter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearInverter = function() {
  return this.setInverter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasInverter = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional TogglePhotoIntervalModeActionArgs toggle_photo_interval_mode = 22;
 * @return {?proto.skills.TogglePhotoIntervalModeActionArgs}
 */
proto.skills.ActionArgs.prototype.getTogglePhotoIntervalMode = function() {
  return /** @type{?proto.skills.TogglePhotoIntervalModeActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionArgs, 22));
};


/**
 * @param {?proto.skills.TogglePhotoIntervalModeActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTogglePhotoIntervalMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTogglePhotoIntervalMode = function() {
  return this.setTogglePhotoIntervalMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTogglePhotoIntervalMode = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional WifiScanActionArgs wifi_scan = 23;
 * @return {?proto.skills.WifiScanActionArgs}
 */
proto.skills.ActionArgs.prototype.getWifiScan = function() {
  return /** @type{?proto.skills.WifiScanActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionArgs, 23));
};


/**
 * @param {?proto.skills.WifiScanActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setWifiScan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearWifiScan = function() {
  return this.setWifiScan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasWifiScan = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional EnableStrobeLightsActionArgs enable_strobe_lights = 24;
 * @return {?proto.skills.EnableStrobeLightsActionArgs}
 */
proto.skills.ActionArgs.prototype.getEnableStrobeLights = function() {
  return /** @type{?proto.skills.EnableStrobeLightsActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.EnableStrobeLightsActionArgs, 24));
};


/**
 * @param {?proto.skills.EnableStrobeLightsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setEnableStrobeLights = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearEnableStrobeLights = function() {
  return this.setEnableStrobeLights(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasEnableStrobeLights = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional DisableStrobeLightsActionArgs disable_strobe_lights = 25;
 * @return {?proto.skills.DisableStrobeLightsActionArgs}
 */
proto.skills.ActionArgs.prototype.getDisableStrobeLights = function() {
  return /** @type{?proto.skills.DisableStrobeLightsActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.DisableStrobeLightsActionArgs, 25));
};


/**
 * @param {?proto.skills.DisableStrobeLightsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setDisableStrobeLights = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearDisableStrobeLights = function() {
  return this.setDisableStrobeLights(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasDisableStrobeLights = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional SpoofJoysticksInputsActionArgs spoof_joysticks_inputs = 26;
 * @return {?proto.skills.SpoofJoysticksInputsActionArgs}
 */
proto.skills.ActionArgs.prototype.getSpoofJoysticksInputs = function() {
  return /** @type{?proto.skills.SpoofJoysticksInputsActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionArgs, 26));
};


/**
 * @param {?proto.skills.SpoofJoysticksInputsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSpoofJoysticksInputs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSpoofJoysticksInputs = function() {
  return this.setSpoofJoysticksInputs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSpoofJoysticksInputs = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional UpdateJoysticksSettingsActionArgs update_joysticks_settings = 27;
 * @return {?proto.skills.UpdateJoysticksSettingsActionArgs}
 */
proto.skills.ActionArgs.prototype.getUpdateJoysticksSettings = function() {
  return /** @type{?proto.skills.UpdateJoysticksSettingsActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionArgs, 27));
};


/**
 * @param {?proto.skills.UpdateJoysticksSettingsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setUpdateJoysticksSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearUpdateJoysticksSettings = function() {
  return this.setUpdateJoysticksSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasUpdateJoysticksSettings = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional UpdateShapeMapActionArgs update_shape_map = 28;
 * @return {?proto.skills.UpdateShapeMapActionArgs}
 */
proto.skills.ActionArgs.prototype.getUpdateShapeMap = function() {
  return /** @type{?proto.skills.UpdateShapeMapActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionArgs, 28));
};


/**
 * @param {?proto.skills.UpdateShapeMapActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setUpdateShapeMap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearUpdateShapeMap = function() {
  return this.setUpdateShapeMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasUpdateShapeMap = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional SpoofObstacleActionArgs spoof_obstacle = 29;
 * @return {?proto.skills.SpoofObstacleActionArgs}
 */
proto.skills.ActionArgs.prototype.getSpoofObstacle = function() {
  return /** @type{?proto.skills.SpoofObstacleActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionArgs, 29));
};


/**
 * @param {?proto.skills.SpoofObstacleActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSpoofObstacle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSpoofObstacle = function() {
  return this.setSpoofObstacle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSpoofObstacle = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional SpoofDisturbanceActionActionArgs spoof_disturbance_action = 30;
 * @return {?proto.skills.SpoofDisturbanceActionActionArgs}
 */
proto.skills.ActionArgs.prototype.getSpoofDisturbanceAction = function() {
  return /** @type{?proto.skills.SpoofDisturbanceActionActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionActionArgs, 30));
};


/**
 * @param {?proto.skills.SpoofDisturbanceActionActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSpoofDisturbanceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSpoofDisturbanceAction = function() {
  return this.setSpoofDisturbanceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSpoofDisturbanceAction = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional UpdateVehicleSettingsActionArgs update_vehicle_settings = 31;
 * @return {?proto.skills.UpdateVehicleSettingsActionArgs}
 */
proto.skills.ActionArgs.prototype.getUpdateVehicleSettings = function() {
  return /** @type{?proto.skills.UpdateVehicleSettingsActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionArgs, 31));
};


/**
 * @param {?proto.skills.UpdateVehicleSettingsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setUpdateVehicleSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearUpdateVehicleSettings = function() {
  return this.setUpdateVehicleSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasUpdateVehicleSettings = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional WaitForVehicleStopActionArgs wait_for_vehicle_stop = 32;
 * @return {?proto.skills.WaitForVehicleStopActionArgs}
 */
proto.skills.ActionArgs.prototype.getWaitForVehicleStop = function() {
  return /** @type{?proto.skills.WaitForVehicleStopActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionArgs, 32));
};


/**
 * @param {?proto.skills.WaitForVehicleStopActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setWaitForVehicleStop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearWaitForVehicleStop = function() {
  return this.setWaitForVehicleStop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasWaitForVehicleStop = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional PrecomputedSubjectSpoofActionArgs precomputed_subject_spoof_action = 33;
 * @return {?proto.skills.PrecomputedSubjectSpoofActionArgs}
 */
proto.skills.ActionArgs.prototype.getPrecomputedSubjectSpoofAction = function() {
  return /** @type{?proto.skills.PrecomputedSubjectSpoofActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionArgs, 33));
};


/**
 * @param {?proto.skills.PrecomputedSubjectSpoofActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setPrecomputedSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearPrecomputedSubjectSpoofAction = function() {
  return this.setPrecomputedSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasPrecomputedSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional ModifySimStateEstimationActionArgs modify_sim_state_estimation = 34;
 * @return {?proto.skills.ModifySimStateEstimationActionArgs}
 */
proto.skills.ActionArgs.prototype.getModifySimStateEstimation = function() {
  return /** @type{?proto.skills.ModifySimStateEstimationActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionArgs, 34));
};


/**
 * @param {?proto.skills.ModifySimStateEstimationActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setModifySimStateEstimation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearModifySimStateEstimation = function() {
  return this.setModifySimStateEstimation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasModifySimStateEstimation = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional GotoAndOrbitPoiActionArgs goto_and_orbit_poi = 35;
 * @return {?proto.skills.GotoAndOrbitPoiActionArgs}
 */
proto.skills.ActionArgs.prototype.getGotoAndOrbitPoi = function() {
  return /** @type{?proto.skills.GotoAndOrbitPoiActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiActionArgs, 35));
};


/**
 * @param {?proto.skills.GotoAndOrbitPoiActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setGotoAndOrbitPoi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearGotoAndOrbitPoi = function() {
  return this.setGotoAndOrbitPoi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasGotoAndOrbitPoi = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional AcquireGpsActionArgs acquire_gps = 36;
 * @return {?proto.skills.AcquireGpsActionArgs}
 */
proto.skills.ActionArgs.prototype.getAcquireGps = function() {
  return /** @type{?proto.skills.AcquireGpsActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionArgs, 36));
};


/**
 * @param {?proto.skills.AcquireGpsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setAcquireGps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearAcquireGps = function() {
  return this.setAcquireGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasAcquireGps = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional SetFaultOverrideActionArgs set_fault_override = 37;
 * @return {?proto.skills.SetFaultOverrideActionArgs}
 */
proto.skills.ActionArgs.prototype.getSetFaultOverride = function() {
  return /** @type{?proto.skills.SetFaultOverrideActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionArgs, 37));
};


/**
 * @param {?proto.skills.SetFaultOverrideActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSetFaultOverride = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSetFaultOverride = function() {
  return this.setSetFaultOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSetFaultOverride = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional ELandActionArgs e_land = 38;
 * @return {?proto.skills.ELandActionArgs}
 */
proto.skills.ActionArgs.prototype.getELand = function() {
  return /** @type{?proto.skills.ELandActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionArgs, 38));
};


/**
 * @param {?proto.skills.ELandActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setELand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearELand = function() {
  return this.setELand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasELand = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional TrajectoryInspectActionArgs trajectory_inspect = 39;
 * @return {?proto.skills.TrajectoryInspectActionArgs}
 */
proto.skills.ActionArgs.prototype.getTrajectoryInspect = function() {
  return /** @type{?proto.skills.TrajectoryInspectActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionArgs, 39));
};


/**
 * @param {?proto.skills.TrajectoryInspectActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTrajectoryInspect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTrajectoryInspect = function() {
  return this.setTrajectoryInspect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTrajectoryInspect = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional ToggleTargetPointExposureActionArgs toggle_target_point_exposure = 40;
 * @return {?proto.skills.ToggleTargetPointExposureActionArgs}
 */
proto.skills.ActionArgs.prototype.getToggleTargetPointExposure = function() {
  return /** @type{?proto.skills.ToggleTargetPointExposureActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionArgs, 40));
};


/**
 * @param {?proto.skills.ToggleTargetPointExposureActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setToggleTargetPointExposure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearToggleTargetPointExposure = function() {
  return this.setToggleTargetPointExposure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasToggleTargetPointExposure = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional ToggleSemanticAimActionArgs toggle_semantic_aim = 41;
 * @return {?proto.skills.ToggleSemanticAimActionArgs}
 */
proto.skills.ActionArgs.prototype.getToggleSemanticAim = function() {
  return /** @type{?proto.skills.ToggleSemanticAimActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionArgs, 41));
};


/**
 * @param {?proto.skills.ToggleSemanticAimActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setToggleSemanticAim = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearToggleSemanticAim = function() {
  return this.setToggleSemanticAim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasToggleSemanticAim = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional SpoofBatteryLevelActionArgs spoof_battery_level = 42;
 * @return {?proto.skills.SpoofBatteryLevelActionArgs}
 */
proto.skills.ActionArgs.prototype.getSpoofBatteryLevel = function() {
  return /** @type{?proto.skills.SpoofBatteryLevelActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionArgs, 42));
};


/**
 * @param {?proto.skills.SpoofBatteryLevelActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSpoofBatteryLevel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSpoofBatteryLevel = function() {
  return this.setSpoofBatteryLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSpoofBatteryLevel = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional NcpgControlActionArgs ncpg_control = 43;
 * @return {?proto.skills.NcpgControlActionArgs}
 */
proto.skills.ActionArgs.prototype.getNcpgControl = function() {
  return /** @type{?proto.skills.NcpgControlActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionArgs, 43));
};


/**
 * @param {?proto.skills.NcpgControlActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setNcpgControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearNcpgControl = function() {
  return this.setNcpgControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasNcpgControl = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional RegisterComplimentaryActionsActionArgs register_complimentary_actions = 44;
 * @return {?proto.skills.RegisterComplimentaryActionsActionArgs}
 */
proto.skills.ActionArgs.prototype.getRegisterComplimentaryActions = function() {
  return /** @type{?proto.skills.RegisterComplimentaryActionsActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionArgs, 44));
};


/**
 * @param {?proto.skills.RegisterComplimentaryActionsActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setRegisterComplimentaryActions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 44, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearRegisterComplimentaryActions = function() {
  return this.setRegisterComplimentaryActions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasRegisterComplimentaryActions = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional TrackInPlaceActionArgs track_in_place = 45;
 * @return {?proto.skills.TrackInPlaceActionArgs}
 */
proto.skills.ActionArgs.prototype.getTrackInPlace = function() {
  return /** @type{?proto.skills.TrackInPlaceActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionArgs, 45));
};


/**
 * @param {?proto.skills.TrackInPlaceActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTrackInPlace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTrackInPlace = function() {
  return this.setTrackInPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTrackInPlace = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional GlobalGraphControlActionArgs global_graph_control = 46;
 * @return {?proto.skills.GlobalGraphControlActionArgs}
 */
proto.skills.ActionArgs.prototype.getGlobalGraphControl = function() {
  return /** @type{?proto.skills.GlobalGraphControlActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionArgs, 46));
};


/**
 * @param {?proto.skills.GlobalGraphControlActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setGlobalGraphControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearGlobalGraphControl = function() {
  return this.setGlobalGraphControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasGlobalGraphControl = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional OverrideGpsQualityActionArgs override_gps_quality = 47;
 * @return {?proto.skills.OverrideGpsQualityActionArgs}
 */
proto.skills.ActionArgs.prototype.getOverrideGpsQuality = function() {
  return /** @type{?proto.skills.OverrideGpsQualityActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionArgs, 47));
};


/**
 * @param {?proto.skills.OverrideGpsQualityActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setOverrideGpsQuality = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearOverrideGpsQuality = function() {
  return this.setOverrideGpsQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasOverrideGpsQuality = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional InitGlobalMapActionArgs init_global_map = 48;
 * @return {?proto.skills.InitGlobalMapActionArgs}
 */
proto.skills.ActionArgs.prototype.getInitGlobalMap = function() {
  return /** @type{?proto.skills.InitGlobalMapActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionArgs, 48));
};


/**
 * @param {?proto.skills.InitGlobalMapActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setInitGlobalMap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearInitGlobalMap = function() {
  return this.setInitGlobalMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasInitGlobalMap = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional StopForPhotoInspectActionArgs stop_for_photo_inspect = 49;
 * @return {?proto.skills.StopForPhotoInspectActionArgs}
 */
proto.skills.ActionArgs.prototype.getStopForPhotoInspect = function() {
  return /** @type{?proto.skills.StopForPhotoInspectActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionArgs, 49));
};


/**
 * @param {?proto.skills.StopForPhotoInspectActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setStopForPhotoInspect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearStopForPhotoInspect = function() {
  return this.setStopForPhotoInspect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasStopForPhotoInspect = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional SpoofInitialGpsHeadingActionArgs spoof_initial_gps_heading = 50;
 * @return {?proto.skills.SpoofInitialGpsHeadingActionArgs}
 */
proto.skills.ActionArgs.prototype.getSpoofInitialGpsHeading = function() {
  return /** @type{?proto.skills.SpoofInitialGpsHeadingActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionArgs, 50));
};


/**
 * @param {?proto.skills.SpoofInitialGpsHeadingActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSpoofInitialGpsHeading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSpoofInitialGpsHeading = function() {
  return this.setSpoofInitialGpsHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSpoofInitialGpsHeading = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional TrackLandingPadRequestActionArgs track_landing_pad_request = 51;
 * @return {?proto.skills.TrackLandingPadRequestActionArgs}
 */
proto.skills.ActionArgs.prototype.getTrackLandingPadRequest = function() {
  return /** @type{?proto.skills.TrackLandingPadRequestActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionArgs, 51));
};


/**
 * @param {?proto.skills.TrackLandingPadRequestActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTrackLandingPadRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTrackLandingPadRequest = function() {
  return this.setTrackLandingPadRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTrackLandingPadRequest = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional ApriltagSetIdActionArgs apriltag_set_id = 52;
 * @return {?proto.skills.ApriltagSetIdActionArgs}
 */
proto.skills.ActionArgs.prototype.getApriltagSetId = function() {
  return /** @type{?proto.skills.ApriltagSetIdActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionArgs, 52));
};


/**
 * @param {?proto.skills.ApriltagSetIdActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setApriltagSetId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearApriltagSetId = function() {
  return this.setApriltagSetId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasApriltagSetId = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional DockGpsEstimatorActionArgs dock_gps_estimator = 53;
 * @return {?proto.skills.DockGpsEstimatorActionArgs}
 */
proto.skills.ActionArgs.prototype.getDockGpsEstimator = function() {
  return /** @type{?proto.skills.DockGpsEstimatorActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionArgs, 53));
};


/**
 * @param {?proto.skills.DockGpsEstimatorActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setDockGpsEstimator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearDockGpsEstimator = function() {
  return this.setDockGpsEstimator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasDockGpsEstimator = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional G47CommandOverrideActionArgs g47_command_override = 54;
 * @return {?proto.skills.G47CommandOverrideActionArgs}
 */
proto.skills.ActionArgs.prototype.getG47CommandOverride = function() {
  return /** @type{?proto.skills.G47CommandOverrideActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionArgs, 54));
};


/**
 * @param {?proto.skills.G47CommandOverrideActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setG47CommandOverride = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearG47CommandOverride = function() {
  return this.setG47CommandOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasG47CommandOverride = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional RequestTakeoffProfileActionArgs request_takeoff_profile = 55;
 * @return {?proto.skills.RequestTakeoffProfileActionArgs}
 */
proto.skills.ActionArgs.prototype.getRequestTakeoffProfile = function() {
  return /** @type{?proto.skills.RequestTakeoffProfileActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionArgs, 55));
};


/**
 * @param {?proto.skills.RequestTakeoffProfileActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setRequestTakeoffProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearRequestTakeoffProfile = function() {
  return this.setRequestTakeoffProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasRequestTakeoffProfile = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional DummyActionArgs dummy = 900;
 * @return {?proto.skills.DummyActionArgs}
 */
proto.skills.ActionArgs.prototype.getDummy = function() {
  return /** @type{?proto.skills.DummyActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionArgs, 900));
};


/**
 * @param {?proto.skills.DummyActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setDummy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 900, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearDummy = function() {
  return this.setDummy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasDummy = function() {
  return jspb.Message.getField(this, 900) != null;
};


/**
 * optional TestDesiredVelocityActionArgs test_desired_velocity = 901;
 * @return {?proto.skills.TestDesiredVelocityActionArgs}
 */
proto.skills.ActionArgs.prototype.getTestDesiredVelocity = function() {
  return /** @type{?proto.skills.TestDesiredVelocityActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionArgs, 901));
};


/**
 * @param {?proto.skills.TestDesiredVelocityActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTestDesiredVelocity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 901, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTestDesiredVelocity = function() {
  return this.setTestDesiredVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTestDesiredVelocity = function() {
  return jspb.Message.getField(this, 901) != null;
};


/**
 * optional TestTrackTrajectoryActionArgs test_track_trajectory = 902;
 * @return {?proto.skills.TestTrackTrajectoryActionArgs}
 */
proto.skills.ActionArgs.prototype.getTestTrackTrajectory = function() {
  return /** @type{?proto.skills.TestTrackTrajectoryActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionArgs, 902));
};


/**
 * @param {?proto.skills.TestTrackTrajectoryActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setTestTrackTrajectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 902, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearTestTrackTrajectory = function() {
  return this.setTestTrackTrajectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasTestTrackTrajectory = function() {
  return jspb.Message.getField(this, 902) != null;
};


/**
 * optional AtvSubjectSpoofActionArgs atv_subject_spoof_action = 904;
 * @return {?proto.skills.AtvSubjectSpoofActionArgs}
 */
proto.skills.ActionArgs.prototype.getAtvSubjectSpoofAction = function() {
  return /** @type{?proto.skills.AtvSubjectSpoofActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionArgs, 904));
};


/**
 * @param {?proto.skills.AtvSubjectSpoofActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setAtvSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 904, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearAtvSubjectSpoofAction = function() {
  return this.setAtvSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasAtvSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 904) != null;
};


/**
 * optional SkiSubjectSpoofActionArgs ski_subject_spoof_action = 905;
 * @return {?proto.skills.SkiSubjectSpoofActionArgs}
 */
proto.skills.ActionArgs.prototype.getSkiSubjectSpoofAction = function() {
  return /** @type{?proto.skills.SkiSubjectSpoofActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionArgs, 905));
};


/**
 * @param {?proto.skills.SkiSubjectSpoofActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSkiSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 905, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSkiSubjectSpoofAction = function() {
  return this.setSkiSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSkiSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 905) != null;
};


/**
 * optional SetGimbalOrientationArgs set_gimbal = 906;
 * @return {?proto.skills.SetGimbalOrientationArgs}
 */
proto.skills.ActionArgs.prototype.getSetGimbal = function() {
  return /** @type{?proto.skills.SetGimbalOrientationArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationArgs, 906));
};


/**
 * @param {?proto.skills.SetGimbalOrientationArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setSetGimbal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 906, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearSetGimbal = function() {
  return this.setSetGimbal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasSetGimbal = function() {
  return jspb.Message.getField(this, 906) != null;
};


/**
 * optional UpdateDockLocationActionArgs update_dock_location_action = 907;
 * @return {?proto.skills.UpdateDockLocationActionArgs}
 */
proto.skills.ActionArgs.prototype.getUpdateDockLocationAction = function() {
  return /** @type{?proto.skills.UpdateDockLocationActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionArgs, 907));
};


/**
 * @param {?proto.skills.UpdateDockLocationActionArgs|undefined} value
 * @return {!proto.skills.ActionArgs} returns this
*/
proto.skills.ActionArgs.prototype.setUpdateDockLocationAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 907, proto.skills.ActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.clearUpdateDockLocationAction = function() {
  return this.setUpdateDockLocationAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.hasUpdateDockLocationAction = function() {
  return jspb.Message.getField(this, 907) != null;
};


/**
 * optional bool photo_on_completion = 100;
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.getPhotoOnCompletion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.setPhotoOnCompletion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * optional bool is_skippable = 101;
 * @return {boolean}
 */
proto.skills.ActionArgs.prototype.getIsSkippable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 101, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.ActionArgs} returns this
 */
proto.skills.ActionArgs.prototype.setIsSkippable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 101, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.MissionState.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionState.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionState.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 10, 0),
stateEnum: jspb.Message.getFieldWithDefault(msg, 1, 0),
missionName: jspb.Message.getFieldWithDefault(msg, 2, ""),
missionUuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
templateUuid: jspb.Message.getFieldWithDefault(msg, 25, ""),
executionUuid: jspb.Message.getFieldWithDefault(msg, 15, ""),
scheduledMissionUuid: jspb.Message.getFieldWithDefault(msg, 14, ""),
actionIndex: jspb.Message.getFieldWithDefault(msg, 5, 0),
actionState: (f = msg.getActionState()) && proto.skills.ActionState.toObject(includeInstance, f),
waypointIndex: jspb.Message.getFieldWithDefault(msg, 17, 0),
numWaypoints: jspb.Message.getFieldWithDefault(msg, 18, 0),
flightInfo: (f = msg.getFlightInfo()) && proto.skills.FlightInfo.toObject(includeInstance, f),
updateNonceHistoryList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
gpsStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
postMissionAction: jspb.Message.getFieldWithDefault(msg, 12, 0),
lostConnectionAction: jspb.Message.getFieldWithDefault(msg, 13, 0),
faultBasedDirectRtdEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
actionTimeEstimate: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
actionDistanceEstimate: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
remainingTimeEstimate: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
remainingDistanceEstimate: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
useRtxSettings: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
rtxSettings: (f = msg.getRtxSettings()) && pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.toObject(includeInstance, f),
waypointsCompleted: jspb.Message.getFieldWithDefault(msg, 26, 0),
navigationModeOverrideEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
navigationModeOverride: jspb.Message.getFieldWithDefault(msg, 28, 0),
expectedMissionResult: jspb.Message.getFieldWithDefault(msg, 29, 0),
lastActionResult: (f = msg.getLastActionResult()) && proto.skills.ActionResult.toObject(includeInstance, f),
freeLookActive: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
supportedFreeLookMode: jspb.Message.getFieldWithDefault(msg, 32, 0),
skipAlert: jspb.Message.getBooleanFieldWithDefault(msg, 33, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionState}
 */
proto.skills.MissionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionState;
  return proto.skills.MissionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionState}
 */
proto.skills.MissionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 1:
      var value = /** @type {!proto.skills.MissionState.MissionStateEnum} */ (reader.readEnum());
      msg.setStateEnum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionUuid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateUuid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionUuid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduledMissionUuid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActionIndex(value);
      break;
    case 6:
      var value = new proto.skills.ActionState;
      reader.readMessage(value,proto.skills.ActionState.deserializeBinaryFromReader);
      msg.setActionState(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWaypointIndex(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumWaypoints(value);
      break;
    case 8:
      var value = new proto.skills.FlightInfo;
      reader.readMessage(value,proto.skills.FlightInfo.deserializeBinaryFromReader);
      msg.setFlightInfo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addUpdateNonceHistory(value);
      break;
    case 11:
      var value = /** @type {!proto.skills.MissionState.GpsStatusEnum} */ (reader.readEnum());
      msg.setGpsStatus(value);
      break;
    case 12:
      var value = /** @type {!proto.skills.PostMissionActionEnum.Enum} */ (reader.readEnum());
      msg.setPostMissionAction(value);
      break;
    case 13:
      var value = /** @type {!proto.skills.LostConnectionActionEnum.Enum} */ (reader.readEnum());
      msg.setLostConnectionAction(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaultBasedDirectRtdEnabled(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setActionTimeEstimate(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setActionDistanceEstimate(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRemainingTimeEstimate(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRemainingDistanceEstimate(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseRtxSettings(value);
      break;
    case 24:
      var value = new pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings;
      reader.readMessage(value,pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.deserializeBinaryFromReader);
      msg.setRtxSettings(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWaypointsCompleted(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNavigationModeOverrideEnabled(value);
      break;
    case 28:
      var value = /** @type {!proto.flight_deck.NavigationMode.Mode} */ (reader.readEnum());
      msg.setNavigationModeOverride(value);
      break;
    case 29:
      var value = /** @type {!proto.skills.MissionResult.Result} */ (reader.readEnum());
      msg.setExpectedMissionResult(value);
      break;
    case 30:
      var value = new proto.skills.ActionResult;
      reader.readMessage(value,proto.skills.ActionResult.deserializeBinaryFromReader);
      msg.setLastActionResult(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFreeLookActive(value);
      break;
    case 32:
      var value = /** @type {!proto.skills.FreeLookMode.Enum} */ (reader.readEnum());
      msg.setSupportedFreeLookMode(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStateEnum();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMissionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMissionUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplateUuid();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getExecutionUuid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getScheduledMissionUuid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getActionIndex();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getActionState();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.ActionState.serializeBinaryToWriter
    );
  }
  f = message.getWaypointIndex();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getNumWaypoints();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getFlightInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.skills.FlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getUpdateNonceHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getGpsStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPostMissionAction();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getLostConnectionAction();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getFaultBasedDirectRtdEnabled();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getActionTimeEstimate();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getActionDistanceEstimate();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getRemainingTimeEstimate();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getRemainingDistanceEstimate();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getUseRtxSettings();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getRtxSettings();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.serializeBinaryToWriter
    );
  }
  f = message.getWaypointsCompleted();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getNavigationModeOverrideEnabled();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getNavigationModeOverride();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getExpectedMissionResult();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = message.getLastActionResult();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.skills.ActionResult.serializeBinaryToWriter
    );
  }
  f = message.getFreeLookActive();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getSupportedFreeLookMode();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getSkipAlert();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.MissionState.MissionStateEnum = {
  UNINITIALIZED: 0,
  IDLE: 1,
  READY: 2,
  IN_PROGRESS: 3,
  PAUSED: 4,
  COMPLETED: 5,
  ABORTED: 6,
  ERROR: 7,
  POST_MISSION_ACTION: 8,
  POST_MISSION_TASK: 9,
  RETURNING_TO_FLIGHT_PATH: 11,
  LOST_CONNECTION: 12,
  LOST_CONNECTION_PAUSED: 13,
  LOW_BATTERY_RETURN: 14,
  VEHICLE_PREP: 16,
  PRETAKEOFF_SEQUENCE: 18
};

/**
 * @enum {number}
 */
proto.skills.MissionState.GpsStatusEnum = {
  GPS_UNKNOWN: 0,
  GPS_INITIALIZING: 1,
  GPS_READY: 2
};

/**
 * optional int64 utime = 10;
 * @return {number}
 */
proto.skills.MissionState.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional MissionStateEnum state_enum = 1;
 * @return {!proto.skills.MissionState.MissionStateEnum}
 */
proto.skills.MissionState.prototype.getStateEnum = function() {
  return /** @type {!proto.skills.MissionState.MissionStateEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skills.MissionState.MissionStateEnum} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setStateEnum = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string mission_name = 2;
 * @return {string}
 */
proto.skills.MissionState.prototype.getMissionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setMissionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mission_uuid = 3;
 * @return {string}
 */
proto.skills.MissionState.prototype.getMissionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setMissionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string template_uuid = 25;
 * @return {string}
 */
proto.skills.MissionState.prototype.getTemplateUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setTemplateUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string execution_uuid = 15;
 * @return {string}
 */
proto.skills.MissionState.prototype.getExecutionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setExecutionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string scheduled_mission_uuid = 14;
 * @return {string}
 */
proto.skills.MissionState.prototype.getScheduledMissionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setScheduledMissionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 action_index = 5;
 * @return {number}
 */
proto.skills.MissionState.prototype.getActionIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setActionIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ActionState action_state = 6;
 * @return {?proto.skills.ActionState}
 */
proto.skills.MissionState.prototype.getActionState = function() {
  return /** @type{?proto.skills.ActionState} */ (
    jspb.Message.getWrapperField(this, proto.skills.ActionState, 6));
};


/**
 * @param {?proto.skills.ActionState|undefined} value
 * @return {!proto.skills.MissionState} returns this
*/
proto.skills.MissionState.prototype.setActionState = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.clearActionState = function() {
  return this.setActionState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionState.prototype.hasActionState = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 waypoint_index = 17;
 * @return {number}
 */
proto.skills.MissionState.prototype.getWaypointIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setWaypointIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 num_waypoints = 18;
 * @return {number}
 */
proto.skills.MissionState.prototype.getNumWaypoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setNumWaypoints = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional FlightInfo flight_info = 8;
 * @return {?proto.skills.FlightInfo}
 */
proto.skills.MissionState.prototype.getFlightInfo = function() {
  return /** @type{?proto.skills.FlightInfo} */ (
    jspb.Message.getWrapperField(this, proto.skills.FlightInfo, 8));
};


/**
 * @param {?proto.skills.FlightInfo|undefined} value
 * @return {!proto.skills.MissionState} returns this
*/
proto.skills.MissionState.prototype.setFlightInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.clearFlightInfo = function() {
  return this.setFlightInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionState.prototype.hasFlightInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string update_nonce_history = 9;
 * @return {!Array<string>}
 */
proto.skills.MissionState.prototype.getUpdateNonceHistoryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setUpdateNonceHistoryList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.addUpdateNonceHistory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.clearUpdateNonceHistoryList = function() {
  return this.setUpdateNonceHistoryList([]);
};


/**
 * optional GpsStatusEnum gps_status = 11;
 * @return {!proto.skills.MissionState.GpsStatusEnum}
 */
proto.skills.MissionState.prototype.getGpsStatus = function() {
  return /** @type {!proto.skills.MissionState.GpsStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.skills.MissionState.GpsStatusEnum} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setGpsStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional PostMissionActionEnum.Enum post_mission_action = 12;
 * @return {!proto.skills.PostMissionActionEnum.Enum}
 */
proto.skills.MissionState.prototype.getPostMissionAction = function() {
  return /** @type {!proto.skills.PostMissionActionEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.skills.PostMissionActionEnum.Enum} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setPostMissionAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional LostConnectionActionEnum.Enum lost_connection_action = 13;
 * @return {!proto.skills.LostConnectionActionEnum.Enum}
 */
proto.skills.MissionState.prototype.getLostConnectionAction = function() {
  return /** @type {!proto.skills.LostConnectionActionEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.skills.LostConnectionActionEnum.Enum} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setLostConnectionAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional bool fault_based_direct_rtd_enabled = 16;
 * @return {boolean}
 */
proto.skills.MissionState.prototype.getFaultBasedDirectRtdEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setFaultBasedDirectRtdEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional float action_time_estimate = 19;
 * @return {number}
 */
proto.skills.MissionState.prototype.getActionTimeEstimate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setActionTimeEstimate = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float action_distance_estimate = 20;
 * @return {number}
 */
proto.skills.MissionState.prototype.getActionDistanceEstimate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setActionDistanceEstimate = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float remaining_time_estimate = 21;
 * @return {number}
 */
proto.skills.MissionState.prototype.getRemainingTimeEstimate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setRemainingTimeEstimate = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional float remaining_distance_estimate = 22;
 * @return {number}
 */
proto.skills.MissionState.prototype.getRemainingDistanceEstimate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setRemainingDistanceEstimate = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional bool use_rtx_settings = 23;
 * @return {boolean}
 */
proto.skills.MissionState.prototype.getUseRtxSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setUseRtxSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional flight_deck.ReturnSettings rtx_settings = 24;
 * @return {?proto.flight_deck.ReturnSettings}
 */
proto.skills.MissionState.prototype.getRtxSettings = function() {
  return /** @type{?proto.flight_deck.ReturnSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings, 24));
};


/**
 * @param {?proto.flight_deck.ReturnSettings|undefined} value
 * @return {!proto.skills.MissionState} returns this
*/
proto.skills.MissionState.prototype.setRtxSettings = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.clearRtxSettings = function() {
  return this.setRtxSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionState.prototype.hasRtxSettings = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional int32 waypoints_completed = 26;
 * @return {number}
 */
proto.skills.MissionState.prototype.getWaypointsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setWaypointsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional bool navigation_mode_override_enabled = 27;
 * @return {boolean}
 */
proto.skills.MissionState.prototype.getNavigationModeOverrideEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setNavigationModeOverrideEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional flight_deck.NavigationMode.Mode navigation_mode_override = 28;
 * @return {!proto.flight_deck.NavigationMode.Mode}
 */
proto.skills.MissionState.prototype.getNavigationModeOverride = function() {
  return /** @type {!proto.flight_deck.NavigationMode.Mode} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.flight_deck.NavigationMode.Mode} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setNavigationModeOverride = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional MissionResult.Result expected_mission_result = 29;
 * @return {!proto.skills.MissionResult.Result}
 */
proto.skills.MissionState.prototype.getExpectedMissionResult = function() {
  return /** @type {!proto.skills.MissionResult.Result} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.skills.MissionResult.Result} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setExpectedMissionResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional ActionResult last_action_result = 30;
 * @return {?proto.skills.ActionResult}
 */
proto.skills.MissionState.prototype.getLastActionResult = function() {
  return /** @type{?proto.skills.ActionResult} */ (
    jspb.Message.getWrapperField(this, proto.skills.ActionResult, 30));
};


/**
 * @param {?proto.skills.ActionResult|undefined} value
 * @return {!proto.skills.MissionState} returns this
*/
proto.skills.MissionState.prototype.setLastActionResult = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.clearLastActionResult = function() {
  return this.setLastActionResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionState.prototype.hasLastActionResult = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional bool free_look_active = 31;
 * @return {boolean}
 */
proto.skills.MissionState.prototype.getFreeLookActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setFreeLookActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional FreeLookMode.Enum supported_free_look_mode = 32;
 * @return {!proto.skills.FreeLookMode.Enum}
 */
proto.skills.MissionState.prototype.getSupportedFreeLookMode = function() {
  return /** @type {!proto.skills.FreeLookMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.skills.FreeLookMode.Enum} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setSupportedFreeLookMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};


/**
 * optional bool skip_alert = 33;
 * @return {boolean}
 */
proto.skills.MissionState.prototype.getSkipAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionState} returns this
 */
proto.skills.MissionState.prototype.setSkipAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.SequenceEstimate.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SequenceEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SequenceEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SequenceEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
actionUuid: jspb.Message.getFieldWithDefault(msg, 6, ""),
duration: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
distanceTraveled: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
finalPosition: (f = msg.getFinalPosition()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
childSequenceEstimatesList: jspb.Message.toObjectList(msg.getChildSequenceEstimatesList(),
    proto.skills.SequenceEstimate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SequenceEstimate}
 */
proto.skills.SequenceEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SequenceEstimate;
  return proto.skills.SequenceEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SequenceEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SequenceEstimate}
 */
proto.skills.SequenceEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistanceTraveled(value);
      break;
    case 4:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setFinalPosition(value);
      break;
    case 5:
      var value = new proto.skills.SequenceEstimate;
      reader.readMessage(value,proto.skills.SequenceEstimate.deserializeBinaryFromReader);
      msg.addChildSequenceEstimates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SequenceEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SequenceEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SequenceEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionUuid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDistanceTraveled();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFinalPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getChildSequenceEstimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.skills.SequenceEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.skills.SequenceEstimate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.SequenceEstimate} returns this
 */
proto.skills.SequenceEstimate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action_uuid = 6;
 * @return {string}
 */
proto.skills.SequenceEstimate.prototype.getActionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.SequenceEstimate} returns this
 */
proto.skills.SequenceEstimate.prototype.setActionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float duration = 2;
 * @return {number}
 */
proto.skills.SequenceEstimate.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SequenceEstimate} returns this
 */
proto.skills.SequenceEstimate.prototype.setDuration = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float distance_traveled = 3;
 * @return {number}
 */
proto.skills.SequenceEstimate.prototype.getDistanceTraveled = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SequenceEstimate} returns this
 */
proto.skills.SequenceEstimate.prototype.setDistanceTraveled = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Waypoint final_position = 4;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.SequenceEstimate.prototype.getFinalPosition = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 4));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.SequenceEstimate} returns this
*/
proto.skills.SequenceEstimate.prototype.setFinalPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SequenceEstimate} returns this
 */
proto.skills.SequenceEstimate.prototype.clearFinalPosition = function() {
  return this.setFinalPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SequenceEstimate.prototype.hasFinalPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated SequenceEstimate child_sequence_estimates = 5;
 * @return {!Array<!proto.skills.SequenceEstimate>}
 */
proto.skills.SequenceEstimate.prototype.getChildSequenceEstimatesList = function() {
  return /** @type{!Array<!proto.skills.SequenceEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.SequenceEstimate, 5));
};


/**
 * @param {!Array<!proto.skills.SequenceEstimate>} value
 * @return {!proto.skills.SequenceEstimate} returns this
*/
proto.skills.SequenceEstimate.prototype.setChildSequenceEstimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skills.SequenceEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.SequenceEstimate}
 */
proto.skills.SequenceEstimate.prototype.addChildSequenceEstimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skills.SequenceEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.SequenceEstimate} returns this
 */
proto.skills.SequenceEstimate.prototype.clearChildSequenceEstimatesList = function() {
  return this.setChildSequenceEstimatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.MissionUpdate.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 6, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 1, ""),
missionUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
type: jspb.Message.getFieldWithDefault(msg, 3, 0),
actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.skills.Action.toObject, includeInstance),
relativeActionUuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
insertBefore: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionUpdate}
 */
proto.skills.MissionUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionUpdate;
  return proto.skills.MissionUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionUpdate}
 */
proto.skills.MissionUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonce(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionUuid(value);
      break;
    case 3:
      var value = /** @type {!proto.skills.MissionUpdate.UpdateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.skills.Action;
      reader.readMessage(value,proto.skills.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelativeActionUuid(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsertBefore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getNonce();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMissionUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.skills.Action.serializeBinaryToWriter
    );
  }
  f = message.getRelativeActionUuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInsertBefore();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.MissionUpdate.UpdateType = {
  UNKNOWN: 0,
  INSERT: 1,
  UPDATE: 2,
  DELETE_ALL_AFTER: 3,
  DELETE_MULTIPLE: 4,
  REVERSE: 5
};

/**
 * optional int64 utime = 6;
 * @return {number}
 */
proto.skills.MissionUpdate.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionUpdate} returns this
 */
proto.skills.MissionUpdate.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string nonce = 1;
 * @return {string}
 */
proto.skills.MissionUpdate.prototype.getNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionUpdate} returns this
 */
proto.skills.MissionUpdate.prototype.setNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mission_uuid = 2;
 * @return {string}
 */
proto.skills.MissionUpdate.prototype.getMissionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionUpdate} returns this
 */
proto.skills.MissionUpdate.prototype.setMissionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UpdateType type = 3;
 * @return {!proto.skills.MissionUpdate.UpdateType}
 */
proto.skills.MissionUpdate.prototype.getType = function() {
  return /** @type {!proto.skills.MissionUpdate.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skills.MissionUpdate.UpdateType} value
 * @return {!proto.skills.MissionUpdate} returns this
 */
proto.skills.MissionUpdate.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated Action actions = 4;
 * @return {!Array<!proto.skills.Action>}
 */
proto.skills.MissionUpdate.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.skills.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Action, 4));
};


/**
 * @param {!Array<!proto.skills.Action>} value
 * @return {!proto.skills.MissionUpdate} returns this
*/
proto.skills.MissionUpdate.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.skills.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Action}
 */
proto.skills.MissionUpdate.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.skills.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MissionUpdate} returns this
 */
proto.skills.MissionUpdate.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string relative_action_uuid = 5;
 * @return {string}
 */
proto.skills.MissionUpdate.prototype.getRelativeActionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionUpdate} returns this
 */
proto.skills.MissionUpdate.prototype.setRelativeActionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool insert_before = 7;
 * @return {boolean}
 */
proto.skills.MissionUpdate.prototype.getInsertBefore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionUpdate} returns this
 */
proto.skills.MissionUpdate.prototype.setInsertBefore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 2, ""),
uuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
updateAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionUpdateResponse}
 */
proto.skills.MissionUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionUpdateResponse;
  return proto.skills.MissionUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionUpdateResponse}
 */
proto.skills.MissionUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonce(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateAccepted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdateAccepted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.MissionUpdateResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionUpdateResponse} returns this
 */
proto.skills.MissionUpdateResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string nonce = 2;
 * @return {string}
 */
proto.skills.MissionUpdateResponse.prototype.getNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionUpdateResponse} returns this
 */
proto.skills.MissionUpdateResponse.prototype.setNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string uuid = 3;
 * @return {string}
 */
proto.skills.MissionUpdateResponse.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionUpdateResponse} returns this
 */
proto.skills.MissionUpdateResponse.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool update_accepted = 4;
 * @return {boolean}
 */
proto.skills.MissionUpdateResponse.prototype.getUpdateAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionUpdateResponse} returns this
 */
proto.skills.MissionUpdateResponse.prototype.setUpdateAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionControl.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionControl.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 2, 0),
command: jspb.Message.getFieldWithDefault(msg, 1, 0),
actionUuid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionControl}
 */
proto.skills.MissionControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionControl;
  return proto.skills.MissionControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionControl}
 */
proto.skills.MissionControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 1:
      var value = /** @type {!proto.skills.MissionControl.ControlType} */ (reader.readEnum());
      msg.setCommand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCommand();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActionUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.MissionControl.ControlType = {
  INVALID: 0,
  START: 1,
  PAUSE: 2,
  ABORT: 3,
  RESUME: 5,
  REQUEST_MISSION: 6,
  ABORT_AUTONOMOUS: 7,
  LAND_IN_PLACE: 8,
  SKIP_TO_WAYPOINT: 9
};

/**
 * optional int64 utime = 2;
 * @return {number}
 */
proto.skills.MissionControl.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionControl} returns this
 */
proto.skills.MissionControl.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ControlType command = 1;
 * @return {!proto.skills.MissionControl.ControlType}
 */
proto.skills.MissionControl.prototype.getCommand = function() {
  return /** @type {!proto.skills.MissionControl.ControlType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skills.MissionControl.ControlType} value
 * @return {!proto.skills.MissionControl} returns this
 */
proto.skills.MissionControl.prototype.setCommand = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string action_uuid = 3;
 * @return {string}
 */
proto.skills.MissionControl.prototype.getActionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionControl} returns this
 */
proto.skills.MissionControl.prototype.setActionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.FlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.FlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.FlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleName: jspb.Message.getFieldWithDefault(msg, 1, ""),
vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
flightId: jspb.Message.getFieldWithDefault(msg, 3, ""),
startUclock: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.FlightInfo}
 */
proto.skills.FlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.FlightInfo;
  return proto.skills.FlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.FlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.FlightInfo}
 */
proto.skills.FlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.FlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.FlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.FlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartUclock();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_name = 1;
 * @return {string}
 */
proto.skills.FlightInfo.prototype.getVehicleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.FlightInfo} returns this
 */
proto.skills.FlightInfo.prototype.setVehicleName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.skills.FlightInfo.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.FlightInfo} returns this
 */
proto.skills.FlightInfo.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_id = 3;
 * @return {string}
 */
proto.skills.FlightInfo.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.FlightInfo} returns this
 */
proto.skills.FlightInfo.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_uclock = 4;
 * @return {number}
 */
proto.skills.FlightInfo.prototype.getStartUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.FlightInfo} returns this
 */
proto.skills.FlightInfo.prototype.setStartUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.ActionState.oneofGroups_ = [[200,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231,232,233,234,235,236,237,238,258,239,240,241,242,243,244,245,246,247,248,249,250,251,252,253,254,255,256,257,259,260,261,262,263,264,265]];

/**
 * @enum {number}
 */
proto.skills.ActionState.DataCase = {
  DATA_NOT_SET: 0,
  SEQUENCE: 200,
  GOTO_AND_ORBIT_POI: 202,
  ORBIT_POI_ACTION: 203,
  GOTO_GPS_WAYPOINT: 204,
  GOTO_NAV_WAYPOINT: 205,
  TAKE_PHOTO: 206,
  SURFACE_SCAN_SKILL: 207,
  START_VIDEO: 208,
  STOP_VIDEO: 209,
  SWITCH_RECORDING_MODE: 210,
  TIMED_WAIT: 211,
  TAKE_PANORAMA: 212,
  KEYFRAME_SKILL: 213,
  GOTO_WAYPOINT: 214,
  SET_OBSTACLE_AVOIDANCE: 215,
  INITIALIZE_GPS: 216,
  SUBJECT_DETECTED: 217,
  LOOPER: 218,
  WITHIN_DISTANCE: 219,
  UNIFIED_FOLLOW_ACTION: 220,
  INVERTER: 221,
  TOGGLE_PHOTO_INTERVAL_MODE: 222,
  WIFI_SCAN: 223,
  ENABLE_STROBE_LIGHTS: 224,
  DISABLE_STROBE_LIGHTS: 225,
  SPOOF_JOYSTICKS_INPUTS: 226,
  UPDATE_JOYSTICKS_SETTINGS: 227,
  UPDATE_SHAPE_MAP: 228,
  SPOOF_OBSTACLE: 229,
  DUMMY_ACTION: 230,
  TEST_DESIRED_VELOCITY_ACTION: 231,
  TEST_TRACK_TRAJECTORY: 232,
  ATV_SUBJECT_SPOOF_ACTION: 233,
  SKI_SUBJECT_SPOOF_ACTION: 234,
  SET_GIMBAL_ORIENTATION: 235,
  PARALLEL: 236,
  SEQUENCE_UNTIL_SUCCESS: 237,
  SEQUENCE_UNTIL_FAILURE: 238,
  SEQUENCE_PRETAKEOFF: 258,
  SPOOF_DISTURBANCE_ACTION: 239,
  UPDATE_VEHICLE_SETTINGS: 240,
  WAIT_FOR_VEHICLE_STOP: 241,
  PRECOMPUTED_SUBJECT_SPOOF_ACTION: 242,
  MODIFY_SIM_STATE_ESTIMATION: 243,
  ACQUIRE_GPS: 244,
  SET_FAULT_OVERRIDE: 245,
  E_LAND: 246,
  TRAJECTORY_INSPECT: 247,
  TOGGLE_TARGET_POINT_EXPOSURE: 248,
  TOGGLE_SEMANTIC_AIM: 249,
  SPOOF_BATTERY_LEVEL: 250,
  NCPG_CONTROL: 251,
  REGISTER_COMPLIMENTARY_ACTIONS: 252,
  TRACK_IN_PLACE: 253,
  GLOBAL_GRAPH_CONTROL: 254,
  OVERRIDE_GPS_QUALITY: 255,
  INIT_GLOBAL_MAP: 256,
  STOP_FOR_PHOTO_INSPECT: 257,
  SPOOF_INITIAL_GPS_HEADING: 259,
  TRACK_LANDING_PAD_REQUEST: 260,
  APRILTAG_SET_ID: 261,
  DOCK_GPS_ESTIMATOR: 262,
  G47_COMMAND_OVERRIDE: 263,
  REQUEST_TAKEOFF_PROFILE: 264,
  UPDATE_DOCK_LOCATION_ACTION: 265
};

/**
 * @return {proto.skills.ActionState.DataCase}
 */
proto.skills.ActionState.prototype.getDataCase = function() {
  return /** @type {proto.skills.ActionState.DataCase} */(jspb.Message.computeOneofCase(this, proto.skills.ActionState.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ActionState.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ActionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ActionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ActionState.toObject = function(includeInstance, msg) {
  var f, obj = {
actionUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
progressRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
startUclock: jspb.Message.getFieldWithDefault(msg, 3, 0),
displayMessage: jspb.Message.getFieldWithDefault(msg, 4, ""),
sequence: (f = msg.getSequence()) && proto.skills.SequenceActionStateData.toObject(includeInstance, f),
gotoAndOrbitPoi: (f = msg.getGotoAndOrbitPoi()) && proto.skills.GotoAndOrbitPoiActionStateData.toObject(includeInstance, f),
orbitPoiAction: (f = msg.getOrbitPoiAction()) && pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionStateData.toObject(includeInstance, f),
gotoGpsWaypoint: (f = msg.getGotoGpsWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionStateData.toObject(includeInstance, f),
gotoNavWaypoint: (f = msg.getGotoNavWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionStateData.toObject(includeInstance, f),
takePhoto: (f = msg.getTakePhoto()) && pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionStateData.toObject(includeInstance, f),
surfaceScanSkill: (f = msg.getSurfaceScanSkill()) && pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionStateData.toObject(includeInstance, f),
startVideo: (f = msg.getStartVideo()) && pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionStateData.toObject(includeInstance, f),
stopVideo: (f = msg.getStopVideo()) && pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionStateData.toObject(includeInstance, f),
switchRecordingMode: (f = msg.getSwitchRecordingMode()) && pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionStateData.toObject(includeInstance, f),
timedWait: (f = msg.getTimedWait()) && pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionStateData.toObject(includeInstance, f),
takePanorama: (f = msg.getTakePanorama()) && pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionStateData.toObject(includeInstance, f),
keyframeSkill: (f = msg.getKeyframeSkill()) && pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionStateData.toObject(includeInstance, f),
gotoWaypoint: (f = msg.getGotoWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionStateData.toObject(includeInstance, f),
setObstacleAvoidance: (f = msg.getSetObstacleAvoidance()) && pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionStateData.toObject(includeInstance, f),
initializeGps: (f = msg.getInitializeGps()) && pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionStateData.toObject(includeInstance, f),
subjectDetected: (f = msg.getSubjectDetected()) && pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedActionStateData.toObject(includeInstance, f),
looper: (f = msg.getLooper()) && pbtypes_vehicle_skills_actions_looper_looper_pb.LooperActionStateData.toObject(includeInstance, f),
withinDistance: (f = msg.getWithinDistance()) && pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionStateData.toObject(includeInstance, f),
unifiedFollowAction: (f = msg.getUnifiedFollowAction()) && pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionStateData.toObject(includeInstance, f),
inverter: (f = msg.getInverter()) && pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterActionStateData.toObject(includeInstance, f),
togglePhotoIntervalMode: (f = msg.getTogglePhotoIntervalMode()) && pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionStateData.toObject(includeInstance, f),
wifiScan: (f = msg.getWifiScan()) && pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionStateData.toObject(includeInstance, f),
enableStrobeLights: (f = msg.getEnableStrobeLights()) && proto.skills.EnableStrobeLightsActionStateData.toObject(includeInstance, f),
disableStrobeLights: (f = msg.getDisableStrobeLights()) && proto.skills.DisableStrobeLightsActionStateData.toObject(includeInstance, f),
spoofJoysticksInputs: (f = msg.getSpoofJoysticksInputs()) && pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionStateData.toObject(includeInstance, f),
updateJoysticksSettings: (f = msg.getUpdateJoysticksSettings()) && pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionStateData.toObject(includeInstance, f),
updateShapeMap: (f = msg.getUpdateShapeMap()) && pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionStateData.toObject(includeInstance, f),
spoofObstacle: (f = msg.getSpoofObstacle()) && pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionStateData.toObject(includeInstance, f),
dummyAction: (f = msg.getDummyAction()) && pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionStateData.toObject(includeInstance, f),
testDesiredVelocityAction: (f = msg.getTestDesiredVelocityAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionStateData.toObject(includeInstance, f),
testTrackTrajectory: (f = msg.getTestTrackTrajectory()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionStateData.toObject(includeInstance, f),
atvSubjectSpoofAction: (f = msg.getAtvSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionStateData.toObject(includeInstance, f),
skiSubjectSpoofAction: (f = msg.getSkiSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionStateData.toObject(includeInstance, f),
setGimbalOrientation: (f = msg.getSetGimbalOrientation()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationActionStateData.toObject(includeInstance, f),
parallel: (f = msg.getParallel()) && pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelActionStateData.toObject(includeInstance, f),
sequenceUntilSuccess: (f = msg.getSequenceUntilSuccess()) && proto.skills.SequenceUntilSuccessActionStateData.toObject(includeInstance, f),
sequenceUntilFailure: (f = msg.getSequenceUntilFailure()) && proto.skills.SequenceUntilFailureActionStateData.toObject(includeInstance, f),
sequencePretakeoff: (f = msg.getSequencePretakeoff()) && proto.skills.SequencePretakeoffActionStateData.toObject(includeInstance, f),
spoofDisturbanceAction: (f = msg.getSpoofDisturbanceAction()) && pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionStateData.toObject(includeInstance, f),
updateVehicleSettings: (f = msg.getUpdateVehicleSettings()) && pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionStateData.toObject(includeInstance, f),
waitForVehicleStop: (f = msg.getWaitForVehicleStop()) && pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionStateData.toObject(includeInstance, f),
precomputedSubjectSpoofAction: (f = msg.getPrecomputedSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionStateData.toObject(includeInstance, f),
modifySimStateEstimation: (f = msg.getModifySimStateEstimation()) && pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionStateData.toObject(includeInstance, f),
acquireGps: (f = msg.getAcquireGps()) && pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionStateData.toObject(includeInstance, f),
setFaultOverride: (f = msg.getSetFaultOverride()) && pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionStateData.toObject(includeInstance, f),
eLand: (f = msg.getELand()) && pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionStateData.toObject(includeInstance, f),
trajectoryInspect: (f = msg.getTrajectoryInspect()) && pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionStateData.toObject(includeInstance, f),
toggleTargetPointExposure: (f = msg.getToggleTargetPointExposure()) && pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionStateData.toObject(includeInstance, f),
toggleSemanticAim: (f = msg.getToggleSemanticAim()) && pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionStateData.toObject(includeInstance, f),
spoofBatteryLevel: (f = msg.getSpoofBatteryLevel()) && pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionStateData.toObject(includeInstance, f),
ncpgControl: (f = msg.getNcpgControl()) && pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionStateData.toObject(includeInstance, f),
registerComplimentaryActions: (f = msg.getRegisterComplimentaryActions()) && pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionStateData.toObject(includeInstance, f),
trackInPlace: (f = msg.getTrackInPlace()) && pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionStateData.toObject(includeInstance, f),
globalGraphControl: (f = msg.getGlobalGraphControl()) && pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionStateData.toObject(includeInstance, f),
overrideGpsQuality: (f = msg.getOverrideGpsQuality()) && pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionStateData.toObject(includeInstance, f),
initGlobalMap: (f = msg.getInitGlobalMap()) && pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionStateData.toObject(includeInstance, f),
stopForPhotoInspect: (f = msg.getStopForPhotoInspect()) && pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionStateData.toObject(includeInstance, f),
spoofInitialGpsHeading: (f = msg.getSpoofInitialGpsHeading()) && pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionStateData.toObject(includeInstance, f),
trackLandingPadRequest: (f = msg.getTrackLandingPadRequest()) && pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionStateData.toObject(includeInstance, f),
apriltagSetId: (f = msg.getApriltagSetId()) && pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionStateData.toObject(includeInstance, f),
dockGpsEstimator: (f = msg.getDockGpsEstimator()) && pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionStateData.toObject(includeInstance, f),
g47CommandOverride: (f = msg.getG47CommandOverride()) && pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionStateData.toObject(includeInstance, f),
requestTakeoffProfile: (f = msg.getRequestTakeoffProfile()) && pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionStateData.toObject(includeInstance, f),
updateDockLocationAction: (f = msg.getUpdateDockLocationAction()) && pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionStateData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ActionState}
 */
proto.skills.ActionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ActionState;
  return proto.skills.ActionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ActionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ActionState}
 */
proto.skills.ActionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgressRatio(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUclock(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayMessage(value);
      break;
    case 200:
      var value = new proto.skills.SequenceActionStateData;
      reader.readMessage(value,proto.skills.SequenceActionStateData.deserializeBinaryFromReader);
      msg.setSequence(value);
      break;
    case 202:
      var value = new proto.skills.GotoAndOrbitPoiActionStateData;
      reader.readMessage(value,proto.skills.GotoAndOrbitPoiActionStateData.deserializeBinaryFromReader);
      msg.setGotoAndOrbitPoi(value);
      break;
    case 203:
      var value = new pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionStateData.deserializeBinaryFromReader);
      msg.setOrbitPoiAction(value);
      break;
    case 204:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionStateData.deserializeBinaryFromReader);
      msg.setGotoGpsWaypoint(value);
      break;
    case 205:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionStateData.deserializeBinaryFromReader);
      msg.setGotoNavWaypoint(value);
      break;
    case 206:
      var value = new pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionStateData.deserializeBinaryFromReader);
      msg.setTakePhoto(value);
      break;
    case 207:
      var value = new pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionStateData.deserializeBinaryFromReader);
      msg.setSurfaceScanSkill(value);
      break;
    case 208:
      var value = new pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionStateData.deserializeBinaryFromReader);
      msg.setStartVideo(value);
      break;
    case 209:
      var value = new pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionStateData.deserializeBinaryFromReader);
      msg.setStopVideo(value);
      break;
    case 210:
      var value = new pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionStateData.deserializeBinaryFromReader);
      msg.setSwitchRecordingMode(value);
      break;
    case 211:
      var value = new pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionStateData.deserializeBinaryFromReader);
      msg.setTimedWait(value);
      break;
    case 212:
      var value = new pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionStateData.deserializeBinaryFromReader);
      msg.setTakePanorama(value);
      break;
    case 213:
      var value = new pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionStateData.deserializeBinaryFromReader);
      msg.setKeyframeSkill(value);
      break;
    case 214:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionStateData.deserializeBinaryFromReader);
      msg.setGotoWaypoint(value);
      break;
    case 215:
      var value = new pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionStateData.deserializeBinaryFromReader);
      msg.setSetObstacleAvoidance(value);
      break;
    case 216:
      var value = new pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionStateData.deserializeBinaryFromReader);
      msg.setInitializeGps(value);
      break;
    case 217:
      var value = new pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedActionStateData.deserializeBinaryFromReader);
      msg.setSubjectDetected(value);
      break;
    case 218:
      var value = new pbtypes_vehicle_skills_actions_looper_looper_pb.LooperActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_looper_looper_pb.LooperActionStateData.deserializeBinaryFromReader);
      msg.setLooper(value);
      break;
    case 219:
      var value = new pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionStateData.deserializeBinaryFromReader);
      msg.setWithinDistance(value);
      break;
    case 220:
      var value = new pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionStateData.deserializeBinaryFromReader);
      msg.setUnifiedFollowAction(value);
      break;
    case 221:
      var value = new pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterActionStateData.deserializeBinaryFromReader);
      msg.setInverter(value);
      break;
    case 222:
      var value = new pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionStateData.deserializeBinaryFromReader);
      msg.setTogglePhotoIntervalMode(value);
      break;
    case 223:
      var value = new pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionStateData.deserializeBinaryFromReader);
      msg.setWifiScan(value);
      break;
    case 224:
      var value = new proto.skills.EnableStrobeLightsActionStateData;
      reader.readMessage(value,proto.skills.EnableStrobeLightsActionStateData.deserializeBinaryFromReader);
      msg.setEnableStrobeLights(value);
      break;
    case 225:
      var value = new proto.skills.DisableStrobeLightsActionStateData;
      reader.readMessage(value,proto.skills.DisableStrobeLightsActionStateData.deserializeBinaryFromReader);
      msg.setDisableStrobeLights(value);
      break;
    case 226:
      var value = new pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionStateData.deserializeBinaryFromReader);
      msg.setSpoofJoysticksInputs(value);
      break;
    case 227:
      var value = new pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionStateData.deserializeBinaryFromReader);
      msg.setUpdateJoysticksSettings(value);
      break;
    case 228:
      var value = new pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionStateData.deserializeBinaryFromReader);
      msg.setUpdateShapeMap(value);
      break;
    case 229:
      var value = new pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionStateData.deserializeBinaryFromReader);
      msg.setSpoofObstacle(value);
      break;
    case 230:
      var value = new pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionStateData.deserializeBinaryFromReader);
      msg.setDummyAction(value);
      break;
    case 231:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionStateData.deserializeBinaryFromReader);
      msg.setTestDesiredVelocityAction(value);
      break;
    case 232:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionStateData.deserializeBinaryFromReader);
      msg.setTestTrackTrajectory(value);
      break;
    case 233:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionStateData.deserializeBinaryFromReader);
      msg.setAtvSubjectSpoofAction(value);
      break;
    case 234:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionStateData.deserializeBinaryFromReader);
      msg.setSkiSubjectSpoofAction(value);
      break;
    case 235:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationActionStateData.deserializeBinaryFromReader);
      msg.setSetGimbalOrientation(value);
      break;
    case 236:
      var value = new pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelActionStateData.deserializeBinaryFromReader);
      msg.setParallel(value);
      break;
    case 237:
      var value = new proto.skills.SequenceUntilSuccessActionStateData;
      reader.readMessage(value,proto.skills.SequenceUntilSuccessActionStateData.deserializeBinaryFromReader);
      msg.setSequenceUntilSuccess(value);
      break;
    case 238:
      var value = new proto.skills.SequenceUntilFailureActionStateData;
      reader.readMessage(value,proto.skills.SequenceUntilFailureActionStateData.deserializeBinaryFromReader);
      msg.setSequenceUntilFailure(value);
      break;
    case 258:
      var value = new proto.skills.SequencePretakeoffActionStateData;
      reader.readMessage(value,proto.skills.SequencePretakeoffActionStateData.deserializeBinaryFromReader);
      msg.setSequencePretakeoff(value);
      break;
    case 239:
      var value = new pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionStateData.deserializeBinaryFromReader);
      msg.setSpoofDisturbanceAction(value);
      break;
    case 240:
      var value = new pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionStateData.deserializeBinaryFromReader);
      msg.setUpdateVehicleSettings(value);
      break;
    case 241:
      var value = new pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionStateData.deserializeBinaryFromReader);
      msg.setWaitForVehicleStop(value);
      break;
    case 242:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionStateData.deserializeBinaryFromReader);
      msg.setPrecomputedSubjectSpoofAction(value);
      break;
    case 243:
      var value = new pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionStateData.deserializeBinaryFromReader);
      msg.setModifySimStateEstimation(value);
      break;
    case 244:
      var value = new pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionStateData.deserializeBinaryFromReader);
      msg.setAcquireGps(value);
      break;
    case 245:
      var value = new pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionStateData.deserializeBinaryFromReader);
      msg.setSetFaultOverride(value);
      break;
    case 246:
      var value = new pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionStateData.deserializeBinaryFromReader);
      msg.setELand(value);
      break;
    case 247:
      var value = new pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionStateData.deserializeBinaryFromReader);
      msg.setTrajectoryInspect(value);
      break;
    case 248:
      var value = new pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionStateData.deserializeBinaryFromReader);
      msg.setToggleTargetPointExposure(value);
      break;
    case 249:
      var value = new pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionStateData.deserializeBinaryFromReader);
      msg.setToggleSemanticAim(value);
      break;
    case 250:
      var value = new pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionStateData.deserializeBinaryFromReader);
      msg.setSpoofBatteryLevel(value);
      break;
    case 251:
      var value = new pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionStateData.deserializeBinaryFromReader);
      msg.setNcpgControl(value);
      break;
    case 252:
      var value = new pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionStateData.deserializeBinaryFromReader);
      msg.setRegisterComplimentaryActions(value);
      break;
    case 253:
      var value = new pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionStateData.deserializeBinaryFromReader);
      msg.setTrackInPlace(value);
      break;
    case 254:
      var value = new pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionStateData.deserializeBinaryFromReader);
      msg.setGlobalGraphControl(value);
      break;
    case 255:
      var value = new pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionStateData.deserializeBinaryFromReader);
      msg.setOverrideGpsQuality(value);
      break;
    case 256:
      var value = new pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionStateData.deserializeBinaryFromReader);
      msg.setInitGlobalMap(value);
      break;
    case 257:
      var value = new pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionStateData.deserializeBinaryFromReader);
      msg.setStopForPhotoInspect(value);
      break;
    case 259:
      var value = new pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionStateData.deserializeBinaryFromReader);
      msg.setSpoofInitialGpsHeading(value);
      break;
    case 260:
      var value = new pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionStateData.deserializeBinaryFromReader);
      msg.setTrackLandingPadRequest(value);
      break;
    case 261:
      var value = new pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionStateData.deserializeBinaryFromReader);
      msg.setApriltagSetId(value);
      break;
    case 262:
      var value = new pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionStateData.deserializeBinaryFromReader);
      msg.setDockGpsEstimator(value);
      break;
    case 263:
      var value = new pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionStateData.deserializeBinaryFromReader);
      msg.setG47CommandOverride(value);
      break;
    case 264:
      var value = new pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionStateData.deserializeBinaryFromReader);
      msg.setRequestTakeoffProfile(value);
      break;
    case 265:
      var value = new pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionStateData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionStateData.deserializeBinaryFromReader);
      msg.setUpdateDockLocationAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ActionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ActionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ActionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ActionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgressRatio();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getStartUclock();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDisplayMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSequence();
  if (f != null) {
    writer.writeMessage(
      200,
      f,
      proto.skills.SequenceActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getGotoAndOrbitPoi();
  if (f != null) {
    writer.writeMessage(
      202,
      f,
      proto.skills.GotoAndOrbitPoiActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getOrbitPoiAction();
  if (f != null) {
    writer.writeMessage(
      203,
      f,
      pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getGotoGpsWaypoint();
  if (f != null) {
    writer.writeMessage(
      204,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getGotoNavWaypoint();
  if (f != null) {
    writer.writeMessage(
      205,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTakePhoto();
  if (f != null) {
    writer.writeMessage(
      206,
      f,
      pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSurfaceScanSkill();
  if (f != null) {
    writer.writeMessage(
      207,
      f,
      pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getStartVideo();
  if (f != null) {
    writer.writeMessage(
      208,
      f,
      pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getStopVideo();
  if (f != null) {
    writer.writeMessage(
      209,
      f,
      pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSwitchRecordingMode();
  if (f != null) {
    writer.writeMessage(
      210,
      f,
      pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTimedWait();
  if (f != null) {
    writer.writeMessage(
      211,
      f,
      pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTakePanorama();
  if (f != null) {
    writer.writeMessage(
      212,
      f,
      pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getKeyframeSkill();
  if (f != null) {
    writer.writeMessage(
      213,
      f,
      pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getGotoWaypoint();
  if (f != null) {
    writer.writeMessage(
      214,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSetObstacleAvoidance();
  if (f != null) {
    writer.writeMessage(
      215,
      f,
      pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getInitializeGps();
  if (f != null) {
    writer.writeMessage(
      216,
      f,
      pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSubjectDetected();
  if (f != null) {
    writer.writeMessage(
      217,
      f,
      pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getLooper();
  if (f != null) {
    writer.writeMessage(
      218,
      f,
      pbtypes_vehicle_skills_actions_looper_looper_pb.LooperActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getWithinDistance();
  if (f != null) {
    writer.writeMessage(
      219,
      f,
      pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getUnifiedFollowAction();
  if (f != null) {
    writer.writeMessage(
      220,
      f,
      pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getInverter();
  if (f != null) {
    writer.writeMessage(
      221,
      f,
      pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTogglePhotoIntervalMode();
  if (f != null) {
    writer.writeMessage(
      222,
      f,
      pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getWifiScan();
  if (f != null) {
    writer.writeMessage(
      223,
      f,
      pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getEnableStrobeLights();
  if (f != null) {
    writer.writeMessage(
      224,
      f,
      proto.skills.EnableStrobeLightsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getDisableStrobeLights();
  if (f != null) {
    writer.writeMessage(
      225,
      f,
      proto.skills.DisableStrobeLightsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofJoysticksInputs();
  if (f != null) {
    writer.writeMessage(
      226,
      f,
      pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateJoysticksSettings();
  if (f != null) {
    writer.writeMessage(
      227,
      f,
      pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateShapeMap();
  if (f != null) {
    writer.writeMessage(
      228,
      f,
      pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofObstacle();
  if (f != null) {
    writer.writeMessage(
      229,
      f,
      pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getDummyAction();
  if (f != null) {
    writer.writeMessage(
      230,
      f,
      pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTestDesiredVelocityAction();
  if (f != null) {
    writer.writeMessage(
      231,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTestTrackTrajectory();
  if (f != null) {
    writer.writeMessage(
      232,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getAtvSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      233,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSkiSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      234,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSetGimbalOrientation();
  if (f != null) {
    writer.writeMessage(
      235,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getParallel();
  if (f != null) {
    writer.writeMessage(
      236,
      f,
      pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSequenceUntilSuccess();
  if (f != null) {
    writer.writeMessage(
      237,
      f,
      proto.skills.SequenceUntilSuccessActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSequenceUntilFailure();
  if (f != null) {
    writer.writeMessage(
      238,
      f,
      proto.skills.SequenceUntilFailureActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSequencePretakeoff();
  if (f != null) {
    writer.writeMessage(
      258,
      f,
      proto.skills.SequencePretakeoffActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofDisturbanceAction();
  if (f != null) {
    writer.writeMessage(
      239,
      f,
      pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateVehicleSettings();
  if (f != null) {
    writer.writeMessage(
      240,
      f,
      pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getWaitForVehicleStop();
  if (f != null) {
    writer.writeMessage(
      241,
      f,
      pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getPrecomputedSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      242,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getModifySimStateEstimation();
  if (f != null) {
    writer.writeMessage(
      243,
      f,
      pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getAcquireGps();
  if (f != null) {
    writer.writeMessage(
      244,
      f,
      pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSetFaultOverride();
  if (f != null) {
    writer.writeMessage(
      245,
      f,
      pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getELand();
  if (f != null) {
    writer.writeMessage(
      246,
      f,
      pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTrajectoryInspect();
  if (f != null) {
    writer.writeMessage(
      247,
      f,
      pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getToggleTargetPointExposure();
  if (f != null) {
    writer.writeMessage(
      248,
      f,
      pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getToggleSemanticAim();
  if (f != null) {
    writer.writeMessage(
      249,
      f,
      pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofBatteryLevel();
  if (f != null) {
    writer.writeMessage(
      250,
      f,
      pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getNcpgControl();
  if (f != null) {
    writer.writeMessage(
      251,
      f,
      pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getRegisterComplimentaryActions();
  if (f != null) {
    writer.writeMessage(
      252,
      f,
      pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTrackInPlace();
  if (f != null) {
    writer.writeMessage(
      253,
      f,
      pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getGlobalGraphControl();
  if (f != null) {
    writer.writeMessage(
      254,
      f,
      pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getOverrideGpsQuality();
  if (f != null) {
    writer.writeMessage(
      255,
      f,
      pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getInitGlobalMap();
  if (f != null) {
    writer.writeMessage(
      256,
      f,
      pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getStopForPhotoInspect();
  if (f != null) {
    writer.writeMessage(
      257,
      f,
      pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofInitialGpsHeading();
  if (f != null) {
    writer.writeMessage(
      259,
      f,
      pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getTrackLandingPadRequest();
  if (f != null) {
    writer.writeMessage(
      260,
      f,
      pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getApriltagSetId();
  if (f != null) {
    writer.writeMessage(
      261,
      f,
      pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getDockGpsEstimator();
  if (f != null) {
    writer.writeMessage(
      262,
      f,
      pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getG47CommandOverride();
  if (f != null) {
    writer.writeMessage(
      263,
      f,
      pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getRequestTakeoffProfile();
  if (f != null) {
    writer.writeMessage(
      264,
      f,
      pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionStateData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateDockLocationAction();
  if (f != null) {
    writer.writeMessage(
      265,
      f,
      pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionStateData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string action_uuid = 1;
 * @return {string}
 */
proto.skills.ActionState.prototype.getActionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.setActionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float progress_ratio = 2;
 * @return {number}
 */
proto.skills.ActionState.prototype.getProgressRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.setProgressRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 start_uclock = 3;
 * @return {number}
 */
proto.skills.ActionState.prototype.getStartUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.setStartUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string display_message = 4;
 * @return {string}
 */
proto.skills.ActionState.prototype.getDisplayMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.setDisplayMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional SequenceActionStateData sequence = 200;
 * @return {?proto.skills.SequenceActionStateData}
 */
proto.skills.ActionState.prototype.getSequence = function() {
  return /** @type{?proto.skills.SequenceActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.SequenceActionStateData, 200));
};


/**
 * @param {?proto.skills.SequenceActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSequence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 200, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSequence = function() {
  return this.setSequence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 200) != null;
};


/**
 * optional GotoAndOrbitPoiActionStateData goto_and_orbit_poi = 202;
 * @return {?proto.skills.GotoAndOrbitPoiActionStateData}
 */
proto.skills.ActionState.prototype.getGotoAndOrbitPoi = function() {
  return /** @type{?proto.skills.GotoAndOrbitPoiActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.GotoAndOrbitPoiActionStateData, 202));
};


/**
 * @param {?proto.skills.GotoAndOrbitPoiActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setGotoAndOrbitPoi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 202, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearGotoAndOrbitPoi = function() {
  return this.setGotoAndOrbitPoi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasGotoAndOrbitPoi = function() {
  return jspb.Message.getField(this, 202) != null;
};


/**
 * optional OrbitPoiActionStateData orbit_poi_action = 203;
 * @return {?proto.skills.OrbitPoiActionStateData}
 */
proto.skills.ActionState.prototype.getOrbitPoiAction = function() {
  return /** @type{?proto.skills.OrbitPoiActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiActionStateData, 203));
};


/**
 * @param {?proto.skills.OrbitPoiActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setOrbitPoiAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 203, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearOrbitPoiAction = function() {
  return this.setOrbitPoiAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasOrbitPoiAction = function() {
  return jspb.Message.getField(this, 203) != null;
};


/**
 * optional GotoGpsWaypointActionStateData goto_gps_waypoint = 204;
 * @return {?proto.skills.GotoGpsWaypointActionStateData}
 */
proto.skills.ActionState.prototype.getGotoGpsWaypoint = function() {
  return /** @type{?proto.skills.GotoGpsWaypointActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointActionStateData, 204));
};


/**
 * @param {?proto.skills.GotoGpsWaypointActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setGotoGpsWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 204, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearGotoGpsWaypoint = function() {
  return this.setGotoGpsWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasGotoGpsWaypoint = function() {
  return jspb.Message.getField(this, 204) != null;
};


/**
 * optional GotoNavWaypointActionStateData goto_nav_waypoint = 205;
 * @return {?proto.skills.GotoNavWaypointActionStateData}
 */
proto.skills.ActionState.prototype.getGotoNavWaypoint = function() {
  return /** @type{?proto.skills.GotoNavWaypointActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointActionStateData, 205));
};


/**
 * @param {?proto.skills.GotoNavWaypointActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setGotoNavWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 205, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearGotoNavWaypoint = function() {
  return this.setGotoNavWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasGotoNavWaypoint = function() {
  return jspb.Message.getField(this, 205) != null;
};


/**
 * optional TakePhotoActionStateData take_photo = 206;
 * @return {?proto.skills.TakePhotoActionStateData}
 */
proto.skills.ActionState.prototype.getTakePhoto = function() {
  return /** @type{?proto.skills.TakePhotoActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoActionStateData, 206));
};


/**
 * @param {?proto.skills.TakePhotoActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTakePhoto = function(value) {
  return jspb.Message.setOneofWrapperField(this, 206, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTakePhoto = function() {
  return this.setTakePhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTakePhoto = function() {
  return jspb.Message.getField(this, 206) != null;
};


/**
 * optional SurfaceScanActionStateData surface_scan_skill = 207;
 * @return {?proto.skills.SurfaceScanActionStateData}
 */
proto.skills.ActionState.prototype.getSurfaceScanSkill = function() {
  return /** @type{?proto.skills.SurfaceScanActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanActionStateData, 207));
};


/**
 * @param {?proto.skills.SurfaceScanActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSurfaceScanSkill = function(value) {
  return jspb.Message.setOneofWrapperField(this, 207, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSurfaceScanSkill = function() {
  return this.setSurfaceScanSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSurfaceScanSkill = function() {
  return jspb.Message.getField(this, 207) != null;
};


/**
 * optional StartVideoActionStateData start_video = 208;
 * @return {?proto.skills.StartVideoActionStateData}
 */
proto.skills.ActionState.prototype.getStartVideo = function() {
  return /** @type{?proto.skills.StartVideoActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoActionStateData, 208));
};


/**
 * @param {?proto.skills.StartVideoActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setStartVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 208, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearStartVideo = function() {
  return this.setStartVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasStartVideo = function() {
  return jspb.Message.getField(this, 208) != null;
};


/**
 * optional StopVideoActionStateData stop_video = 209;
 * @return {?proto.skills.StopVideoActionStateData}
 */
proto.skills.ActionState.prototype.getStopVideo = function() {
  return /** @type{?proto.skills.StopVideoActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoActionStateData, 209));
};


/**
 * @param {?proto.skills.StopVideoActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setStopVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 209, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearStopVideo = function() {
  return this.setStopVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasStopVideo = function() {
  return jspb.Message.getField(this, 209) != null;
};


/**
 * optional SwitchRecordingModeActionStateData switch_recording_mode = 210;
 * @return {?proto.skills.SwitchRecordingModeActionStateData}
 */
proto.skills.ActionState.prototype.getSwitchRecordingMode = function() {
  return /** @type{?proto.skills.SwitchRecordingModeActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeActionStateData, 210));
};


/**
 * @param {?proto.skills.SwitchRecordingModeActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSwitchRecordingMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 210, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSwitchRecordingMode = function() {
  return this.setSwitchRecordingMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSwitchRecordingMode = function() {
  return jspb.Message.getField(this, 210) != null;
};


/**
 * optional TimedWaitActionStateData timed_wait = 211;
 * @return {?proto.skills.TimedWaitActionStateData}
 */
proto.skills.ActionState.prototype.getTimedWait = function() {
  return /** @type{?proto.skills.TimedWaitActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitActionStateData, 211));
};


/**
 * @param {?proto.skills.TimedWaitActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTimedWait = function(value) {
  return jspb.Message.setOneofWrapperField(this, 211, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTimedWait = function() {
  return this.setTimedWait(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTimedWait = function() {
  return jspb.Message.getField(this, 211) != null;
};


/**
 * optional TakePanoramaActionStateData take_panorama = 212;
 * @return {?proto.skills.TakePanoramaActionStateData}
 */
proto.skills.ActionState.prototype.getTakePanorama = function() {
  return /** @type{?proto.skills.TakePanoramaActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaActionStateData, 212));
};


/**
 * @param {?proto.skills.TakePanoramaActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTakePanorama = function(value) {
  return jspb.Message.setOneofWrapperField(this, 212, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTakePanorama = function() {
  return this.setTakePanorama(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTakePanorama = function() {
  return jspb.Message.getField(this, 212) != null;
};


/**
 * optional KeyframeActionStateData keyframe_skill = 213;
 * @return {?proto.skills.KeyframeActionStateData}
 */
proto.skills.ActionState.prototype.getKeyframeSkill = function() {
  return /** @type{?proto.skills.KeyframeActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeActionStateData, 213));
};


/**
 * @param {?proto.skills.KeyframeActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setKeyframeSkill = function(value) {
  return jspb.Message.setOneofWrapperField(this, 213, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearKeyframeSkill = function() {
  return this.setKeyframeSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasKeyframeSkill = function() {
  return jspb.Message.getField(this, 213) != null;
};


/**
 * optional GotoWaypointActionStateData goto_waypoint = 214;
 * @return {?proto.skills.GotoWaypointActionStateData}
 */
proto.skills.ActionState.prototype.getGotoWaypoint = function() {
  return /** @type{?proto.skills.GotoWaypointActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointActionStateData, 214));
};


/**
 * @param {?proto.skills.GotoWaypointActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setGotoWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 214, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearGotoWaypoint = function() {
  return this.setGotoWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasGotoWaypoint = function() {
  return jspb.Message.getField(this, 214) != null;
};


/**
 * optional SetObstacleAvoidanceActionStateData set_obstacle_avoidance = 215;
 * @return {?proto.skills.SetObstacleAvoidanceActionStateData}
 */
proto.skills.ActionState.prototype.getSetObstacleAvoidance = function() {
  return /** @type{?proto.skills.SetObstacleAvoidanceActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceActionStateData, 215));
};


/**
 * @param {?proto.skills.SetObstacleAvoidanceActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSetObstacleAvoidance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 215, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSetObstacleAvoidance = function() {
  return this.setSetObstacleAvoidance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSetObstacleAvoidance = function() {
  return jspb.Message.getField(this, 215) != null;
};


/**
 * optional InitializeGpsActionStateData initialize_gps = 216;
 * @return {?proto.skills.InitializeGpsActionStateData}
 */
proto.skills.ActionState.prototype.getInitializeGps = function() {
  return /** @type{?proto.skills.InitializeGpsActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsActionStateData, 216));
};


/**
 * @param {?proto.skills.InitializeGpsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setInitializeGps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 216, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearInitializeGps = function() {
  return this.setInitializeGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasInitializeGps = function() {
  return jspb.Message.getField(this, 216) != null;
};


/**
 * optional SubjectDetectedActionStateData subject_detected = 217;
 * @return {?proto.skills.SubjectDetectedActionStateData}
 */
proto.skills.ActionState.prototype.getSubjectDetected = function() {
  return /** @type{?proto.skills.SubjectDetectedActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedActionStateData, 217));
};


/**
 * @param {?proto.skills.SubjectDetectedActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSubjectDetected = function(value) {
  return jspb.Message.setOneofWrapperField(this, 217, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSubjectDetected = function() {
  return this.setSubjectDetected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSubjectDetected = function() {
  return jspb.Message.getField(this, 217) != null;
};


/**
 * optional LooperActionStateData looper = 218;
 * @return {?proto.skills.LooperActionStateData}
 */
proto.skills.ActionState.prototype.getLooper = function() {
  return /** @type{?proto.skills.LooperActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_looper_looper_pb.LooperActionStateData, 218));
};


/**
 * @param {?proto.skills.LooperActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setLooper = function(value) {
  return jspb.Message.setOneofWrapperField(this, 218, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearLooper = function() {
  return this.setLooper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasLooper = function() {
  return jspb.Message.getField(this, 218) != null;
};


/**
 * optional WithinDistanceActionStateData within_distance = 219;
 * @return {?proto.skills.WithinDistanceActionStateData}
 */
proto.skills.ActionState.prototype.getWithinDistance = function() {
  return /** @type{?proto.skills.WithinDistanceActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceActionStateData, 219));
};


/**
 * @param {?proto.skills.WithinDistanceActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setWithinDistance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 219, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearWithinDistance = function() {
  return this.setWithinDistance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasWithinDistance = function() {
  return jspb.Message.getField(this, 219) != null;
};


/**
 * optional UnifiedFollowActionStateData unified_follow_action = 220;
 * @return {?proto.skills.UnifiedFollowActionStateData}
 */
proto.skills.ActionState.prototype.getUnifiedFollowAction = function() {
  return /** @type{?proto.skills.UnifiedFollowActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowActionStateData, 220));
};


/**
 * @param {?proto.skills.UnifiedFollowActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setUnifiedFollowAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 220, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearUnifiedFollowAction = function() {
  return this.setUnifiedFollowAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasUnifiedFollowAction = function() {
  return jspb.Message.getField(this, 220) != null;
};


/**
 * optional InverterActionStateData inverter = 221;
 * @return {?proto.skills.InverterActionStateData}
 */
proto.skills.ActionState.prototype.getInverter = function() {
  return /** @type{?proto.skills.InverterActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterActionStateData, 221));
};


/**
 * @param {?proto.skills.InverterActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setInverter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 221, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearInverter = function() {
  return this.setInverter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasInverter = function() {
  return jspb.Message.getField(this, 221) != null;
};


/**
 * optional TogglePhotoIntervalModeActionStateData toggle_photo_interval_mode = 222;
 * @return {?proto.skills.TogglePhotoIntervalModeActionStateData}
 */
proto.skills.ActionState.prototype.getTogglePhotoIntervalMode = function() {
  return /** @type{?proto.skills.TogglePhotoIntervalModeActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeActionStateData, 222));
};


/**
 * @param {?proto.skills.TogglePhotoIntervalModeActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTogglePhotoIntervalMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 222, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTogglePhotoIntervalMode = function() {
  return this.setTogglePhotoIntervalMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTogglePhotoIntervalMode = function() {
  return jspb.Message.getField(this, 222) != null;
};


/**
 * optional WifiScanActionStateData wifi_scan = 223;
 * @return {?proto.skills.WifiScanActionStateData}
 */
proto.skills.ActionState.prototype.getWifiScan = function() {
  return /** @type{?proto.skills.WifiScanActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanActionStateData, 223));
};


/**
 * @param {?proto.skills.WifiScanActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setWifiScan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 223, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearWifiScan = function() {
  return this.setWifiScan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasWifiScan = function() {
  return jspb.Message.getField(this, 223) != null;
};


/**
 * optional EnableStrobeLightsActionStateData enable_strobe_lights = 224;
 * @return {?proto.skills.EnableStrobeLightsActionStateData}
 */
proto.skills.ActionState.prototype.getEnableStrobeLights = function() {
  return /** @type{?proto.skills.EnableStrobeLightsActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.EnableStrobeLightsActionStateData, 224));
};


/**
 * @param {?proto.skills.EnableStrobeLightsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setEnableStrobeLights = function(value) {
  return jspb.Message.setOneofWrapperField(this, 224, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearEnableStrobeLights = function() {
  return this.setEnableStrobeLights(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasEnableStrobeLights = function() {
  return jspb.Message.getField(this, 224) != null;
};


/**
 * optional DisableStrobeLightsActionStateData disable_strobe_lights = 225;
 * @return {?proto.skills.DisableStrobeLightsActionStateData}
 */
proto.skills.ActionState.prototype.getDisableStrobeLights = function() {
  return /** @type{?proto.skills.DisableStrobeLightsActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.DisableStrobeLightsActionStateData, 225));
};


/**
 * @param {?proto.skills.DisableStrobeLightsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setDisableStrobeLights = function(value) {
  return jspb.Message.setOneofWrapperField(this, 225, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearDisableStrobeLights = function() {
  return this.setDisableStrobeLights(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasDisableStrobeLights = function() {
  return jspb.Message.getField(this, 225) != null;
};


/**
 * optional SpoofJoysticksInputsActionStateData spoof_joysticks_inputs = 226;
 * @return {?proto.skills.SpoofJoysticksInputsActionStateData}
 */
proto.skills.ActionState.prototype.getSpoofJoysticksInputs = function() {
  return /** @type{?proto.skills.SpoofJoysticksInputsActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsActionStateData, 226));
};


/**
 * @param {?proto.skills.SpoofJoysticksInputsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSpoofJoysticksInputs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 226, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSpoofJoysticksInputs = function() {
  return this.setSpoofJoysticksInputs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSpoofJoysticksInputs = function() {
  return jspb.Message.getField(this, 226) != null;
};


/**
 * optional UpdateJoysticksSettingsActionStateData update_joysticks_settings = 227;
 * @return {?proto.skills.UpdateJoysticksSettingsActionStateData}
 */
proto.skills.ActionState.prototype.getUpdateJoysticksSettings = function() {
  return /** @type{?proto.skills.UpdateJoysticksSettingsActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsActionStateData, 227));
};


/**
 * @param {?proto.skills.UpdateJoysticksSettingsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setUpdateJoysticksSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 227, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearUpdateJoysticksSettings = function() {
  return this.setUpdateJoysticksSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasUpdateJoysticksSettings = function() {
  return jspb.Message.getField(this, 227) != null;
};


/**
 * optional UpdateShapeMapActionStateData update_shape_map = 228;
 * @return {?proto.skills.UpdateShapeMapActionStateData}
 */
proto.skills.ActionState.prototype.getUpdateShapeMap = function() {
  return /** @type{?proto.skills.UpdateShapeMapActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapActionStateData, 228));
};


/**
 * @param {?proto.skills.UpdateShapeMapActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setUpdateShapeMap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 228, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearUpdateShapeMap = function() {
  return this.setUpdateShapeMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasUpdateShapeMap = function() {
  return jspb.Message.getField(this, 228) != null;
};


/**
 * optional SpoofObstacleActionStateData spoof_obstacle = 229;
 * @return {?proto.skills.SpoofObstacleActionStateData}
 */
proto.skills.ActionState.prototype.getSpoofObstacle = function() {
  return /** @type{?proto.skills.SpoofObstacleActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleActionStateData, 229));
};


/**
 * @param {?proto.skills.SpoofObstacleActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSpoofObstacle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 229, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSpoofObstacle = function() {
  return this.setSpoofObstacle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSpoofObstacle = function() {
  return jspb.Message.getField(this, 229) != null;
};


/**
 * optional DummyActionStateData dummy_action = 230;
 * @return {?proto.skills.DummyActionStateData}
 */
proto.skills.ActionState.prototype.getDummyAction = function() {
  return /** @type{?proto.skills.DummyActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyActionStateData, 230));
};


/**
 * @param {?proto.skills.DummyActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setDummyAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 230, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearDummyAction = function() {
  return this.setDummyAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasDummyAction = function() {
  return jspb.Message.getField(this, 230) != null;
};


/**
 * optional TestDesiredVelocityActionStateData test_desired_velocity_action = 231;
 * @return {?proto.skills.TestDesiredVelocityActionStateData}
 */
proto.skills.ActionState.prototype.getTestDesiredVelocityAction = function() {
  return /** @type{?proto.skills.TestDesiredVelocityActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityActionStateData, 231));
};


/**
 * @param {?proto.skills.TestDesiredVelocityActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTestDesiredVelocityAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 231, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTestDesiredVelocityAction = function() {
  return this.setTestDesiredVelocityAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTestDesiredVelocityAction = function() {
  return jspb.Message.getField(this, 231) != null;
};


/**
 * optional TestTrackTrajectoryActionStateData test_track_trajectory = 232;
 * @return {?proto.skills.TestTrackTrajectoryActionStateData}
 */
proto.skills.ActionState.prototype.getTestTrackTrajectory = function() {
  return /** @type{?proto.skills.TestTrackTrajectoryActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryActionStateData, 232));
};


/**
 * @param {?proto.skills.TestTrackTrajectoryActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTestTrackTrajectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 232, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTestTrackTrajectory = function() {
  return this.setTestTrackTrajectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTestTrackTrajectory = function() {
  return jspb.Message.getField(this, 232) != null;
};


/**
 * optional AtvSubjectSpoofActionStateData atv_subject_spoof_action = 233;
 * @return {?proto.skills.AtvSubjectSpoofActionStateData}
 */
proto.skills.ActionState.prototype.getAtvSubjectSpoofAction = function() {
  return /** @type{?proto.skills.AtvSubjectSpoofActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofActionStateData, 233));
};


/**
 * @param {?proto.skills.AtvSubjectSpoofActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setAtvSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 233, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearAtvSubjectSpoofAction = function() {
  return this.setAtvSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasAtvSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 233) != null;
};


/**
 * optional SkiSubjectSpoofActionStateData ski_subject_spoof_action = 234;
 * @return {?proto.skills.SkiSubjectSpoofActionStateData}
 */
proto.skills.ActionState.prototype.getSkiSubjectSpoofAction = function() {
  return /** @type{?proto.skills.SkiSubjectSpoofActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofActionStateData, 234));
};


/**
 * @param {?proto.skills.SkiSubjectSpoofActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSkiSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 234, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSkiSubjectSpoofAction = function() {
  return this.setSkiSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSkiSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 234) != null;
};


/**
 * optional SetGimbalOrientationActionStateData set_gimbal_orientation = 235;
 * @return {?proto.skills.SetGimbalOrientationActionStateData}
 */
proto.skills.ActionState.prototype.getSetGimbalOrientation = function() {
  return /** @type{?proto.skills.SetGimbalOrientationActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationActionStateData, 235));
};


/**
 * @param {?proto.skills.SetGimbalOrientationActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSetGimbalOrientation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 235, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSetGimbalOrientation = function() {
  return this.setSetGimbalOrientation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSetGimbalOrientation = function() {
  return jspb.Message.getField(this, 235) != null;
};


/**
 * optional ParallelActionStateData parallel = 236;
 * @return {?proto.skills.ParallelActionStateData}
 */
proto.skills.ActionState.prototype.getParallel = function() {
  return /** @type{?proto.skills.ParallelActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelActionStateData, 236));
};


/**
 * @param {?proto.skills.ParallelActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setParallel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 236, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearParallel = function() {
  return this.setParallel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasParallel = function() {
  return jspb.Message.getField(this, 236) != null;
};


/**
 * optional SequenceUntilSuccessActionStateData sequence_until_success = 237;
 * @return {?proto.skills.SequenceUntilSuccessActionStateData}
 */
proto.skills.ActionState.prototype.getSequenceUntilSuccess = function() {
  return /** @type{?proto.skills.SequenceUntilSuccessActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.SequenceUntilSuccessActionStateData, 237));
};


/**
 * @param {?proto.skills.SequenceUntilSuccessActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSequenceUntilSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 237, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSequenceUntilSuccess = function() {
  return this.setSequenceUntilSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSequenceUntilSuccess = function() {
  return jspb.Message.getField(this, 237) != null;
};


/**
 * optional SequenceUntilFailureActionStateData sequence_until_failure = 238;
 * @return {?proto.skills.SequenceUntilFailureActionStateData}
 */
proto.skills.ActionState.prototype.getSequenceUntilFailure = function() {
  return /** @type{?proto.skills.SequenceUntilFailureActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.SequenceUntilFailureActionStateData, 238));
};


/**
 * @param {?proto.skills.SequenceUntilFailureActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSequenceUntilFailure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 238, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSequenceUntilFailure = function() {
  return this.setSequenceUntilFailure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSequenceUntilFailure = function() {
  return jspb.Message.getField(this, 238) != null;
};


/**
 * optional SequencePretakeoffActionStateData sequence_pretakeoff = 258;
 * @return {?proto.skills.SequencePretakeoffActionStateData}
 */
proto.skills.ActionState.prototype.getSequencePretakeoff = function() {
  return /** @type{?proto.skills.SequencePretakeoffActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.SequencePretakeoffActionStateData, 258));
};


/**
 * @param {?proto.skills.SequencePretakeoffActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSequencePretakeoff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 258, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSequencePretakeoff = function() {
  return this.setSequencePretakeoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSequencePretakeoff = function() {
  return jspb.Message.getField(this, 258) != null;
};


/**
 * optional SpoofDisturbanceActionStateData spoof_disturbance_action = 239;
 * @return {?proto.skills.SpoofDisturbanceActionStateData}
 */
proto.skills.ActionState.prototype.getSpoofDisturbanceAction = function() {
  return /** @type{?proto.skills.SpoofDisturbanceActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceActionStateData, 239));
};


/**
 * @param {?proto.skills.SpoofDisturbanceActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSpoofDisturbanceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 239, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSpoofDisturbanceAction = function() {
  return this.setSpoofDisturbanceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSpoofDisturbanceAction = function() {
  return jspb.Message.getField(this, 239) != null;
};


/**
 * optional UpdateVehicleSettingsActionStateData update_vehicle_settings = 240;
 * @return {?proto.skills.UpdateVehicleSettingsActionStateData}
 */
proto.skills.ActionState.prototype.getUpdateVehicleSettings = function() {
  return /** @type{?proto.skills.UpdateVehicleSettingsActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsActionStateData, 240));
};


/**
 * @param {?proto.skills.UpdateVehicleSettingsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setUpdateVehicleSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 240, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearUpdateVehicleSettings = function() {
  return this.setUpdateVehicleSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasUpdateVehicleSettings = function() {
  return jspb.Message.getField(this, 240) != null;
};


/**
 * optional WaitForVehicleStopActionStateData wait_for_vehicle_stop = 241;
 * @return {?proto.skills.WaitForVehicleStopActionStateData}
 */
proto.skills.ActionState.prototype.getWaitForVehicleStop = function() {
  return /** @type{?proto.skills.WaitForVehicleStopActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopActionStateData, 241));
};


/**
 * @param {?proto.skills.WaitForVehicleStopActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setWaitForVehicleStop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 241, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearWaitForVehicleStop = function() {
  return this.setWaitForVehicleStop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasWaitForVehicleStop = function() {
  return jspb.Message.getField(this, 241) != null;
};


/**
 * optional PrecomputedSubjectSpoofActionStateData precomputed_subject_spoof_action = 242;
 * @return {?proto.skills.PrecomputedSubjectSpoofActionStateData}
 */
proto.skills.ActionState.prototype.getPrecomputedSubjectSpoofAction = function() {
  return /** @type{?proto.skills.PrecomputedSubjectSpoofActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofActionStateData, 242));
};


/**
 * @param {?proto.skills.PrecomputedSubjectSpoofActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setPrecomputedSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 242, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearPrecomputedSubjectSpoofAction = function() {
  return this.setPrecomputedSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasPrecomputedSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 242) != null;
};


/**
 * optional ModifySimStateEstimationActionStateData modify_sim_state_estimation = 243;
 * @return {?proto.skills.ModifySimStateEstimationActionStateData}
 */
proto.skills.ActionState.prototype.getModifySimStateEstimation = function() {
  return /** @type{?proto.skills.ModifySimStateEstimationActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationActionStateData, 243));
};


/**
 * @param {?proto.skills.ModifySimStateEstimationActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setModifySimStateEstimation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 243, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearModifySimStateEstimation = function() {
  return this.setModifySimStateEstimation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasModifySimStateEstimation = function() {
  return jspb.Message.getField(this, 243) != null;
};


/**
 * optional AcquireGpsActionStateData acquire_gps = 244;
 * @return {?proto.skills.AcquireGpsActionStateData}
 */
proto.skills.ActionState.prototype.getAcquireGps = function() {
  return /** @type{?proto.skills.AcquireGpsActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsActionStateData, 244));
};


/**
 * @param {?proto.skills.AcquireGpsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setAcquireGps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 244, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearAcquireGps = function() {
  return this.setAcquireGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasAcquireGps = function() {
  return jspb.Message.getField(this, 244) != null;
};


/**
 * optional SetFaultOverrideActionStateData set_fault_override = 245;
 * @return {?proto.skills.SetFaultOverrideActionStateData}
 */
proto.skills.ActionState.prototype.getSetFaultOverride = function() {
  return /** @type{?proto.skills.SetFaultOverrideActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideActionStateData, 245));
};


/**
 * @param {?proto.skills.SetFaultOverrideActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSetFaultOverride = function(value) {
  return jspb.Message.setOneofWrapperField(this, 245, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSetFaultOverride = function() {
  return this.setSetFaultOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSetFaultOverride = function() {
  return jspb.Message.getField(this, 245) != null;
};


/**
 * optional ELandActionStateData e_land = 246;
 * @return {?proto.skills.ELandActionStateData}
 */
proto.skills.ActionState.prototype.getELand = function() {
  return /** @type{?proto.skills.ELandActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandActionStateData, 246));
};


/**
 * @param {?proto.skills.ELandActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setELand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 246, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearELand = function() {
  return this.setELand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasELand = function() {
  return jspb.Message.getField(this, 246) != null;
};


/**
 * optional TrajectoryInspectActionStateData trajectory_inspect = 247;
 * @return {?proto.skills.TrajectoryInspectActionStateData}
 */
proto.skills.ActionState.prototype.getTrajectoryInspect = function() {
  return /** @type{?proto.skills.TrajectoryInspectActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectActionStateData, 247));
};


/**
 * @param {?proto.skills.TrajectoryInspectActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTrajectoryInspect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 247, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTrajectoryInspect = function() {
  return this.setTrajectoryInspect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTrajectoryInspect = function() {
  return jspb.Message.getField(this, 247) != null;
};


/**
 * optional ToggleTargetPointExposureActionStateData toggle_target_point_exposure = 248;
 * @return {?proto.skills.ToggleTargetPointExposureActionStateData}
 */
proto.skills.ActionState.prototype.getToggleTargetPointExposure = function() {
  return /** @type{?proto.skills.ToggleTargetPointExposureActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureActionStateData, 248));
};


/**
 * @param {?proto.skills.ToggleTargetPointExposureActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setToggleTargetPointExposure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 248, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearToggleTargetPointExposure = function() {
  return this.setToggleTargetPointExposure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasToggleTargetPointExposure = function() {
  return jspb.Message.getField(this, 248) != null;
};


/**
 * optional ToggleSemanticAimActionStateData toggle_semantic_aim = 249;
 * @return {?proto.skills.ToggleSemanticAimActionStateData}
 */
proto.skills.ActionState.prototype.getToggleSemanticAim = function() {
  return /** @type{?proto.skills.ToggleSemanticAimActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimActionStateData, 249));
};


/**
 * @param {?proto.skills.ToggleSemanticAimActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setToggleSemanticAim = function(value) {
  return jspb.Message.setOneofWrapperField(this, 249, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearToggleSemanticAim = function() {
  return this.setToggleSemanticAim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasToggleSemanticAim = function() {
  return jspb.Message.getField(this, 249) != null;
};


/**
 * optional SpoofBatteryLevelActionStateData spoof_battery_level = 250;
 * @return {?proto.skills.SpoofBatteryLevelActionStateData}
 */
proto.skills.ActionState.prototype.getSpoofBatteryLevel = function() {
  return /** @type{?proto.skills.SpoofBatteryLevelActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelActionStateData, 250));
};


/**
 * @param {?proto.skills.SpoofBatteryLevelActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSpoofBatteryLevel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 250, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSpoofBatteryLevel = function() {
  return this.setSpoofBatteryLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSpoofBatteryLevel = function() {
  return jspb.Message.getField(this, 250) != null;
};


/**
 * optional NcpgControlActionStateData ncpg_control = 251;
 * @return {?proto.skills.NcpgControlActionStateData}
 */
proto.skills.ActionState.prototype.getNcpgControl = function() {
  return /** @type{?proto.skills.NcpgControlActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlActionStateData, 251));
};


/**
 * @param {?proto.skills.NcpgControlActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setNcpgControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 251, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearNcpgControl = function() {
  return this.setNcpgControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasNcpgControl = function() {
  return jspb.Message.getField(this, 251) != null;
};


/**
 * optional RegisterComplimentaryActionsActionStateData register_complimentary_actions = 252;
 * @return {?proto.skills.RegisterComplimentaryActionsActionStateData}
 */
proto.skills.ActionState.prototype.getRegisterComplimentaryActions = function() {
  return /** @type{?proto.skills.RegisterComplimentaryActionsActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsActionStateData, 252));
};


/**
 * @param {?proto.skills.RegisterComplimentaryActionsActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setRegisterComplimentaryActions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 252, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearRegisterComplimentaryActions = function() {
  return this.setRegisterComplimentaryActions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasRegisterComplimentaryActions = function() {
  return jspb.Message.getField(this, 252) != null;
};


/**
 * optional TrackInPlaceActionStateData track_in_place = 253;
 * @return {?proto.skills.TrackInPlaceActionStateData}
 */
proto.skills.ActionState.prototype.getTrackInPlace = function() {
  return /** @type{?proto.skills.TrackInPlaceActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceActionStateData, 253));
};


/**
 * @param {?proto.skills.TrackInPlaceActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTrackInPlace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 253, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTrackInPlace = function() {
  return this.setTrackInPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTrackInPlace = function() {
  return jspb.Message.getField(this, 253) != null;
};


/**
 * optional GlobalGraphControlActionStateData global_graph_control = 254;
 * @return {?proto.skills.GlobalGraphControlActionStateData}
 */
proto.skills.ActionState.prototype.getGlobalGraphControl = function() {
  return /** @type{?proto.skills.GlobalGraphControlActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlActionStateData, 254));
};


/**
 * @param {?proto.skills.GlobalGraphControlActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setGlobalGraphControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 254, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearGlobalGraphControl = function() {
  return this.setGlobalGraphControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasGlobalGraphControl = function() {
  return jspb.Message.getField(this, 254) != null;
};


/**
 * optional OverrideGpsQualityActionStateData override_gps_quality = 255;
 * @return {?proto.skills.OverrideGpsQualityActionStateData}
 */
proto.skills.ActionState.prototype.getOverrideGpsQuality = function() {
  return /** @type{?proto.skills.OverrideGpsQualityActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityActionStateData, 255));
};


/**
 * @param {?proto.skills.OverrideGpsQualityActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setOverrideGpsQuality = function(value) {
  return jspb.Message.setOneofWrapperField(this, 255, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearOverrideGpsQuality = function() {
  return this.setOverrideGpsQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasOverrideGpsQuality = function() {
  return jspb.Message.getField(this, 255) != null;
};


/**
 * optional InitGlobalMapActionStateData init_global_map = 256;
 * @return {?proto.skills.InitGlobalMapActionStateData}
 */
proto.skills.ActionState.prototype.getInitGlobalMap = function() {
  return /** @type{?proto.skills.InitGlobalMapActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapActionStateData, 256));
};


/**
 * @param {?proto.skills.InitGlobalMapActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setInitGlobalMap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 256, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearInitGlobalMap = function() {
  return this.setInitGlobalMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasInitGlobalMap = function() {
  return jspb.Message.getField(this, 256) != null;
};


/**
 * optional StopForPhotoInspectActionStateData stop_for_photo_inspect = 257;
 * @return {?proto.skills.StopForPhotoInspectActionStateData}
 */
proto.skills.ActionState.prototype.getStopForPhotoInspect = function() {
  return /** @type{?proto.skills.StopForPhotoInspectActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectActionStateData, 257));
};


/**
 * @param {?proto.skills.StopForPhotoInspectActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setStopForPhotoInspect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 257, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearStopForPhotoInspect = function() {
  return this.setStopForPhotoInspect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasStopForPhotoInspect = function() {
  return jspb.Message.getField(this, 257) != null;
};


/**
 * optional SpoofInitialGpsHeadingActionStateData spoof_initial_gps_heading = 259;
 * @return {?proto.skills.SpoofInitialGpsHeadingActionStateData}
 */
proto.skills.ActionState.prototype.getSpoofInitialGpsHeading = function() {
  return /** @type{?proto.skills.SpoofInitialGpsHeadingActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingActionStateData, 259));
};


/**
 * @param {?proto.skills.SpoofInitialGpsHeadingActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setSpoofInitialGpsHeading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 259, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearSpoofInitialGpsHeading = function() {
  return this.setSpoofInitialGpsHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasSpoofInitialGpsHeading = function() {
  return jspb.Message.getField(this, 259) != null;
};


/**
 * optional TrackLandingPadRequestActionStateData track_landing_pad_request = 260;
 * @return {?proto.skills.TrackLandingPadRequestActionStateData}
 */
proto.skills.ActionState.prototype.getTrackLandingPadRequest = function() {
  return /** @type{?proto.skills.TrackLandingPadRequestActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestActionStateData, 260));
};


/**
 * @param {?proto.skills.TrackLandingPadRequestActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setTrackLandingPadRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 260, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearTrackLandingPadRequest = function() {
  return this.setTrackLandingPadRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasTrackLandingPadRequest = function() {
  return jspb.Message.getField(this, 260) != null;
};


/**
 * optional ApriltagSetIdActionStateData apriltag_set_id = 261;
 * @return {?proto.skills.ApriltagSetIdActionStateData}
 */
proto.skills.ActionState.prototype.getApriltagSetId = function() {
  return /** @type{?proto.skills.ApriltagSetIdActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdActionStateData, 261));
};


/**
 * @param {?proto.skills.ApriltagSetIdActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setApriltagSetId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 261, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearApriltagSetId = function() {
  return this.setApriltagSetId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasApriltagSetId = function() {
  return jspb.Message.getField(this, 261) != null;
};


/**
 * optional DockGpsEstimatorActionStateData dock_gps_estimator = 262;
 * @return {?proto.skills.DockGpsEstimatorActionStateData}
 */
proto.skills.ActionState.prototype.getDockGpsEstimator = function() {
  return /** @type{?proto.skills.DockGpsEstimatorActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorActionStateData, 262));
};


/**
 * @param {?proto.skills.DockGpsEstimatorActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setDockGpsEstimator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 262, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearDockGpsEstimator = function() {
  return this.setDockGpsEstimator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasDockGpsEstimator = function() {
  return jspb.Message.getField(this, 262) != null;
};


/**
 * optional G47CommandOverrideActionStateData g47_command_override = 263;
 * @return {?proto.skills.G47CommandOverrideActionStateData}
 */
proto.skills.ActionState.prototype.getG47CommandOverride = function() {
  return /** @type{?proto.skills.G47CommandOverrideActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideActionStateData, 263));
};


/**
 * @param {?proto.skills.G47CommandOverrideActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setG47CommandOverride = function(value) {
  return jspb.Message.setOneofWrapperField(this, 263, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearG47CommandOverride = function() {
  return this.setG47CommandOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasG47CommandOverride = function() {
  return jspb.Message.getField(this, 263) != null;
};


/**
 * optional RequestTakeoffProfileActionStateData request_takeoff_profile = 264;
 * @return {?proto.skills.RequestTakeoffProfileActionStateData}
 */
proto.skills.ActionState.prototype.getRequestTakeoffProfile = function() {
  return /** @type{?proto.skills.RequestTakeoffProfileActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileActionStateData, 264));
};


/**
 * @param {?proto.skills.RequestTakeoffProfileActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setRequestTakeoffProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 264, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearRequestTakeoffProfile = function() {
  return this.setRequestTakeoffProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasRequestTakeoffProfile = function() {
  return jspb.Message.getField(this, 264) != null;
};


/**
 * optional UpdateDockLocationActionStateData update_dock_location_action = 265;
 * @return {?proto.skills.UpdateDockLocationActionStateData}
 */
proto.skills.ActionState.prototype.getUpdateDockLocationAction = function() {
  return /** @type{?proto.skills.UpdateDockLocationActionStateData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionStateData, 265));
};


/**
 * @param {?proto.skills.UpdateDockLocationActionStateData|undefined} value
 * @return {!proto.skills.ActionState} returns this
*/
proto.skills.ActionState.prototype.setUpdateDockLocationAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 265, proto.skills.ActionState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionState} returns this
 */
proto.skills.ActionState.prototype.clearUpdateDockLocationAction = function() {
  return this.setUpdateDockLocationAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionState.prototype.hasUpdateDockLocationAction = function() {
  return jspb.Message.getField(this, 265) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.FailedTakeoffSummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.FailedTakeoffSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.FailedTakeoffSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.FailedTakeoffSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FailedTakeoffSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
blockingFaultsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
flightPhase: jspb.Message.getFieldWithDefault(msg, 2, 0),
reason: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.FailedTakeoffSummary}
 */
proto.skills.FailedTakeoffSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.FailedTakeoffSummary;
  return proto.skills.FailedTakeoffSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.FailedTakeoffSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.FailedTakeoffSummary}
 */
proto.skills.FailedTakeoffSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.common.VehicleFault.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBlockingFaults(values[i]);
      }
      break;
    case 2:
      var value = /** @type {!proto.common.FlightPhaseEnum.Enum} */ (reader.readEnum());
      msg.setFlightPhase(value);
      break;
    case 3:
      var value = /** @type {!proto.skills.FailedTakeoffReason.Enum} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.FailedTakeoffSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.FailedTakeoffSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.FailedTakeoffSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FailedTakeoffSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockingFaultsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getFlightPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated common.VehicleFault.Enum blocking_faults = 1;
 * @return {!Array<!proto.common.VehicleFault.Enum>}
 */
proto.skills.FailedTakeoffSummary.prototype.getBlockingFaultsList = function() {
  return /** @type {!Array<!proto.common.VehicleFault.Enum>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.common.VehicleFault.Enum>} value
 * @return {!proto.skills.FailedTakeoffSummary} returns this
 */
proto.skills.FailedTakeoffSummary.prototype.setBlockingFaultsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.common.VehicleFault.Enum} value
 * @param {number=} opt_index
 * @return {!proto.skills.FailedTakeoffSummary} returns this
 */
proto.skills.FailedTakeoffSummary.prototype.addBlockingFaults = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.FailedTakeoffSummary} returns this
 */
proto.skills.FailedTakeoffSummary.prototype.clearBlockingFaultsList = function() {
  return this.setBlockingFaultsList([]);
};


/**
 * optional common.FlightPhaseEnum.Enum flight_phase = 2;
 * @return {!proto.common.FlightPhaseEnum.Enum}
 */
proto.skills.FailedTakeoffSummary.prototype.getFlightPhase = function() {
  return /** @type {!proto.common.FlightPhaseEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.FlightPhaseEnum.Enum} value
 * @return {!proto.skills.FailedTakeoffSummary} returns this
 */
proto.skills.FailedTakeoffSummary.prototype.setFlightPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional FailedTakeoffReason.Enum reason = 3;
 * @return {!proto.skills.FailedTakeoffReason.Enum}
 */
proto.skills.FailedTakeoffSummary.prototype.getReason = function() {
  return /** @type {!proto.skills.FailedTakeoffReason.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skills.FailedTakeoffReason.Enum} value
 * @return {!proto.skills.FailedTakeoffSummary} returns this
 */
proto.skills.FailedTakeoffSummary.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.FailedTakeoffReason.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.FailedTakeoffReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.FailedTakeoffReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FailedTakeoffReason.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.FailedTakeoffReason}
 */
proto.skills.FailedTakeoffReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.FailedTakeoffReason;
  return proto.skills.FailedTakeoffReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.FailedTakeoffReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.FailedTakeoffReason}
 */
proto.skills.FailedTakeoffReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.FailedTakeoffReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.FailedTakeoffReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.FailedTakeoffReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FailedTakeoffReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.FailedTakeoffReason.Enum = {
  UNINITIALIZED: 0,
  UNKNOWN: 1,
  BLOCKING_FAULTS: 2,
  NOT_IN_DOCK_OR_PEDESTAL: 3,
  POSE_GRAPH_NOT_AVAILABLE: 4,
  IN_HAND: 5,
  NO_ATTEMPT: 6,
  LOST_PILOTSHIP: 7,
  ENTERED_POST_FLIGHT_BEFORE_TAKEOFF_COMPLETED: 8,
  MISSION_REMOVED_BY_SCHEDULER_REASON_UNKNOWN: 9,
  TAKEOFF_CANCELED_REASON_UNKNOWN: 10,
  TAKEOFF_CANCELED_BLOCKING_FAULTS: 11,
  MISSION_REMOVED_BY_SCHEDULER_BLOCKING_FAULTS: 12
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PostMissionActionResultEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PostMissionActionResultEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PostMissionActionResultEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PostMissionActionResultEnum.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PostMissionActionResultEnum}
 */
proto.skills.PostMissionActionResultEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PostMissionActionResultEnum;
  return proto.skills.PostMissionActionResultEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PostMissionActionResultEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PostMissionActionResultEnum}
 */
proto.skills.PostMissionActionResultEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PostMissionActionResultEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PostMissionActionResultEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PostMissionActionResultEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PostMissionActionResultEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.PostMissionActionResultEnum.Enum = {
  UNKNOWN: 0,
  DID_NOT_RUN: 1,
  SUCCESS: 2,
  FAILURE: 3
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.MissionResult.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionResult.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionResult.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 6, 0),
executionUuid: jspb.Message.getFieldWithDefault(msg, 7, ""),
startUclock: jspb.Message.getFieldWithDefault(msg, 8, 0),
endUclock: jspb.Message.getFieldWithDefault(msg, 9, 0),
scheduledMissionUuid: jspb.Message.getFieldWithDefault(msg, 10, ""),
missionUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
templateUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
flightId: jspb.Message.getFieldWithDefault(msg, 11, ""),
vehicleId: jspb.Message.getFieldWithDefault(msg, 14, ""),
result: jspb.Message.getFieldWithDefault(msg, 3, 0),
errorString: jspb.Message.getFieldWithDefault(msg, 4, ""),
actionResultsList: jspb.Message.toObjectList(msg.getActionResultsList(),
    proto.skills.ActionResult.toObject, includeInstance),
postMissionTaskRuntimeSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
postMissionActionRuntimeSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
failedTakeoffSummary: (f = msg.getFailedTakeoffSummary()) && proto.skills.FailedTakeoffSummary.toObject(includeInstance, f),
postMissionActionResult: jspb.Message.getFieldWithDefault(msg, 16, 0),
takeoffDockId: jspb.Message.getFieldWithDefault(msg, 17, ""),
skipAlert: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionResult}
 */
proto.skills.MissionResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionResult;
  return proto.skills.MissionResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionResult}
 */
proto.skills.MissionResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionUuid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUclock(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndUclock(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduledMissionUuid(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissionUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateUuid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {!proto.skills.MissionResult.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorString(value);
      break;
    case 5:
      var value = new proto.skills.ActionResult;
      reader.readMessage(value,proto.skills.ActionResult.deserializeBinaryFromReader);
      msg.addActionResults(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPostMissionTaskRuntimeSeconds(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPostMissionActionRuntimeSeconds(value);
      break;
    case 15:
      var value = new proto.skills.FailedTakeoffSummary;
      reader.readMessage(value,proto.skills.FailedTakeoffSummary.deserializeBinaryFromReader);
      msg.setFailedTakeoffSummary(value);
      break;
    case 16:
      var value = /** @type {!proto.skills.PostMissionActionResultEnum.Enum} */ (reader.readEnum());
      msg.setPostMissionActionResult(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffDockId(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getExecutionUuid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStartUclock();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEndUclock();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getScheduledMissionUuid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMissionUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getErrorString();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActionResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.skills.ActionResult.serializeBinaryToWriter
    );
  }
  f = message.getPostMissionTaskRuntimeSeconds();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getPostMissionActionRuntimeSeconds();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getFailedTakeoffSummary();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.skills.FailedTakeoffSummary.serializeBinaryToWriter
    );
  }
  f = message.getPostMissionActionResult();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getTakeoffDockId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSkipAlert();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.MissionResult.Result = {
  UNKNOWN: 0,
  SUCCESS: 1,
  REJECT_ARGUMENTS: 2,
  ABORT_MANUAL: 3,
  ABORT_MANUAL_RTX: 13,
  ABORT_MANUAL_BUTTON_PRESSED: 21,
  ABORT_MANUAL_LAND: 14,
  ABORT_AUTONOMOUS: 4,
  FAILED_ACTIONS: 5,
  FAILED_TAKEOFF: 6,
  FAILED_POST_MISSION_ACTION: 7,
  OFFLINE: 8,
  ABORT_LOW_BATTERY: 9,
  ABORT_LOST_CONNECTION: 10,
  ABORT_TOO_DARK: 11,
  ABORT_FAILED_ACTIONS: 12,
  ABORT_AUTONOMOUS_MISSION_CONTROL: 15,
  LAND_IN_PLACE_MISSION_CONTROL: 16,
  WAYPOINT_VALIDATION_FAILED: 17,
  PERSISTENT_GLOBAL_GRAPH_DISABLED: 18,
  FAILED_TO_LOAD_GLOBAL_GRAPH: 19,
  NEED_NCPG_TO_LOAD_GLOBAL_GRAPH: 20,
  FAILED_TO_LOAD_NCPG: 22,
  ABORT_FAR_OUTSIDE_GEOFENCE: 24
};

/**
 * optional int64 utime = 6;
 * @return {number}
 */
proto.skills.MissionResult.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string execution_uuid = 7;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getExecutionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setExecutionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 start_uclock = 8;
 * @return {number}
 */
proto.skills.MissionResult.prototype.getStartUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setStartUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 end_uclock = 9;
 * @return {number}
 */
proto.skills.MissionResult.prototype.getEndUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setEndUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string scheduled_mission_uuid = 10;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getScheduledMissionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setScheduledMissionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mission_uuid = 1;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getMissionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setMissionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_uuid = 2;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getTemplateUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setTemplateUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_id = 11;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string vehicle_id = 14;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional Result result = 3;
 * @return {!proto.skills.MissionResult.Result}
 */
proto.skills.MissionResult.prototype.getResult = function() {
  return /** @type {!proto.skills.MissionResult.Result} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skills.MissionResult.Result} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string error_string = 4;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getErrorString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setErrorString = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ActionResult action_results = 5;
 * @return {!Array<!proto.skills.ActionResult>}
 */
proto.skills.MissionResult.prototype.getActionResultsList = function() {
  return /** @type{!Array<!proto.skills.ActionResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.ActionResult, 5));
};


/**
 * @param {!Array<!proto.skills.ActionResult>} value
 * @return {!proto.skills.MissionResult} returns this
*/
proto.skills.MissionResult.prototype.setActionResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skills.ActionResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.ActionResult}
 */
proto.skills.MissionResult.prototype.addActionResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skills.ActionResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.clearActionResultsList = function() {
  return this.setActionResultsList([]);
};


/**
 * optional float post_mission_task_runtime_seconds = 12;
 * @return {number}
 */
proto.skills.MissionResult.prototype.getPostMissionTaskRuntimeSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setPostMissionTaskRuntimeSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float post_mission_action_runtime_seconds = 13;
 * @return {number}
 */
proto.skills.MissionResult.prototype.getPostMissionActionRuntimeSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setPostMissionActionRuntimeSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional FailedTakeoffSummary failed_takeoff_summary = 15;
 * @return {?proto.skills.FailedTakeoffSummary}
 */
proto.skills.MissionResult.prototype.getFailedTakeoffSummary = function() {
  return /** @type{?proto.skills.FailedTakeoffSummary} */ (
    jspb.Message.getWrapperField(this, proto.skills.FailedTakeoffSummary, 15));
};


/**
 * @param {?proto.skills.FailedTakeoffSummary|undefined} value
 * @return {!proto.skills.MissionResult} returns this
*/
proto.skills.MissionResult.prototype.setFailedTakeoffSummary = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.clearFailedTakeoffSummary = function() {
  return this.setFailedTakeoffSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MissionResult.prototype.hasFailedTakeoffSummary = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PostMissionActionResultEnum.Enum post_mission_action_result = 16;
 * @return {!proto.skills.PostMissionActionResultEnum.Enum}
 */
proto.skills.MissionResult.prototype.getPostMissionActionResult = function() {
  return /** @type {!proto.skills.PostMissionActionResultEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.skills.PostMissionActionResultEnum.Enum} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setPostMissionActionResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string takeoff_dock_id = 17;
 * @return {string}
 */
proto.skills.MissionResult.prototype.getTakeoffDockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setTakeoffDockId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool skip_alert = 18;
 * @return {boolean}
 */
proto.skills.MissionResult.prototype.getSkipAlert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MissionResult} returns this
 */
proto.skills.MissionResult.prototype.setSkipAlert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.ActionResult.oneofGroups_ = [[102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,159,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,160,161,162,163,164,165,166]];

/**
 * @enum {number}
 */
proto.skills.ActionResult.DataCase = {
  DATA_NOT_SET: 0,
  GOTO_GPS_WAYPOINT: 102,
  GOTO_NAV_WAYPOINT: 103,
  TAKE_PHOTO: 104,
  SURFACE_SCAN_SKILL: 105,
  START_VIDEO: 106,
  STOP_VIDEO: 107,
  SWITCH_RECORDING_MODE: 108,
  TIMED_WAIT: 109,
  SEQUENCE: 110,
  ORBIT_POI_ACTION: 111,
  TAKE_PANORAMA: 112,
  KEYFRAME_SKILL: 113,
  GOTO_WAYPOINT: 114,
  SET_OBSTACLE_AVOIDANCE: 115,
  INITIALIZE_GPS: 116,
  SUBJECT_DETECTED: 117,
  LOOPER: 118,
  WITHIN_DISTANCE: 119,
  UNIFIED_FOLLOW_ACTION: 120,
  INVERTER: 121,
  TOGGLE_PHOTO_INTERVAL_MODE: 122,
  WIFI_SCAN: 123,
  ENABLE_STROBE_LIGHTS: 124,
  DISABLE_STROBE_LIGHTS: 125,
  SPOOF_JOYSTICKS_INPUTS: 126,
  UPDATE_JOYSTICKS_SETTINGS: 127,
  UPDATE_SHAPE_MAP: 128,
  SPOOF_OBSTACLE: 129,
  DUMMY_ACTION: 130,
  TEST_DESIRED_VELOCITY_ACTION: 131,
  TEST_TRACK_TRAJECTORY: 132,
  ATV_SUBJECT_SPOOF_ACTION: 133,
  SKI_SUBJECT_SPOOF_ACTION: 134,
  SET_GIMBAL_ORIENTATION: 135,
  PARALLEL: 136,
  SEQUENCE_UNTIL_SUCCESS: 137,
  SEQUENCE_UNTIL_FAILURE: 138,
  SEQUENCE_PRETAKEOFF: 159,
  SPOOF_DISTURBANCE_ACTION: 139,
  UPDATE_VEHICLE_SETTINGS: 140,
  WAIT_FOR_VEHICLE_STOP: 141,
  PRECOMPUTED_SUBJECT_SPOOF_ACTION: 142,
  MODIFY_SIM_STATE_ESTIMATION: 143,
  GOTO_AND_ORBIT_POI: 144,
  ACQUIRE_GPS: 145,
  SET_FAULT_OVERRIDE: 146,
  E_LAND: 147,
  TRAJECTORY_INSPECT: 148,
  TOGGLE_TARGET_POINT_EXPOSURE: 149,
  TOGGLE_SEMANTIC_AIM: 150,
  SPOOF_BATTERY_LEVEL: 151,
  NCPG_CONTROL: 152,
  REGISTER_COMPLIMENTARY_ACTIONS: 153,
  TRACK_IN_PLACE: 154,
  GLOBAL_GRAPH_CONTROL: 155,
  OVERRIDE_GPS_QUALITY: 156,
  INIT_GLOBAL_MAP: 157,
  STOP_FOR_PHOTO_INSPECT: 158,
  SPOOF_INITIAL_GPS_HEADING: 160,
  TRACK_LANDING_PAD_REQUEST: 161,
  APRILTAG_SET_ID: 162,
  DOCK_GPS_ESTIMATOR: 163,
  G47_COMMAND_OVERRIDE: 164,
  REQUEST_TAKEOFF_PROFILE: 165,
  UPDATE_DOCK_LOCATION_ACTION: 166
};

/**
 * @return {proto.skills.ActionResult.DataCase}
 */
proto.skills.ActionResult.prototype.getDataCase = function() {
  return /** @type {proto.skills.ActionResult.DataCase} */(jspb.Message.computeOneofCase(this, proto.skills.ActionResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ActionResult.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ActionResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ActionResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ActionResult.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 12, ""),
actionUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
actionKey: jspb.Message.getFieldWithDefault(msg, 9, ""),
message: jspb.Message.getFieldWithDefault(msg, 2, ""),
startUclock: jspb.Message.getFieldWithDefault(msg, 3, 0),
endUclock: jspb.Message.getFieldWithDefault(msg, 4, 0),
startUtime: jspb.Message.getFieldWithDefault(msg, 14, 0),
endUtime: jspb.Message.getFieldWithDefault(msg, 15, 0),
debugJson: jspb.Message.getFieldWithDefault(msg, 7, ""),
result: jspb.Message.getFieldWithDefault(msg, 10, 0),
errorCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
parentActionResultUuid: jspb.Message.getFieldWithDefault(msg, 13, ""),
gotoGpsWaypoint: (f = msg.getGotoGpsWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointResultData.toObject(includeInstance, f),
gotoNavWaypoint: (f = msg.getGotoNavWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointResultData.toObject(includeInstance, f),
takePhoto: (f = msg.getTakePhoto()) && pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoResultData.toObject(includeInstance, f),
surfaceScanSkill: (f = msg.getSurfaceScanSkill()) && pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanResultData.toObject(includeInstance, f),
startVideo: (f = msg.getStartVideo()) && pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoResultData.toObject(includeInstance, f),
stopVideo: (f = msg.getStopVideo()) && pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoResultData.toObject(includeInstance, f),
switchRecordingMode: (f = msg.getSwitchRecordingMode()) && pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeResultData.toObject(includeInstance, f),
timedWait: (f = msg.getTimedWait()) && pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitResultData.toObject(includeInstance, f),
sequence: (f = msg.getSequence()) && pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceResultData.toObject(includeInstance, f),
orbitPoiAction: (f = msg.getOrbitPoiAction()) && pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiResultData.toObject(includeInstance, f),
takePanorama: (f = msg.getTakePanorama()) && pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaResultData.toObject(includeInstance, f),
keyframeSkill: (f = msg.getKeyframeSkill()) && pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeResultData.toObject(includeInstance, f),
gotoWaypoint: (f = msg.getGotoWaypoint()) && pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointResultData.toObject(includeInstance, f),
setObstacleAvoidance: (f = msg.getSetObstacleAvoidance()) && pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceResultData.toObject(includeInstance, f),
initializeGps: (f = msg.getInitializeGps()) && pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsResultData.toObject(includeInstance, f),
subjectDetected: (f = msg.getSubjectDetected()) && pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedResultData.toObject(includeInstance, f),
looper: (f = msg.getLooper()) && pbtypes_vehicle_skills_actions_looper_looper_pb.LooperResultData.toObject(includeInstance, f),
withinDistance: (f = msg.getWithinDistance()) && pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceResultData.toObject(includeInstance, f),
unifiedFollowAction: (f = msg.getUnifiedFollowAction()) && pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowResultData.toObject(includeInstance, f),
inverter: (f = msg.getInverter()) && pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterResultData.toObject(includeInstance, f),
togglePhotoIntervalMode: (f = msg.getTogglePhotoIntervalMode()) && pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeResultData.toObject(includeInstance, f),
wifiScan: (f = msg.getWifiScan()) && pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanResultData.toObject(includeInstance, f),
enableStrobeLights: (f = msg.getEnableStrobeLights()) && proto.skills.EnableStrobeLightsResultData.toObject(includeInstance, f),
disableStrobeLights: (f = msg.getDisableStrobeLights()) && proto.skills.DisableStrobeLightsResultData.toObject(includeInstance, f),
spoofJoysticksInputs: (f = msg.getSpoofJoysticksInputs()) && pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsResultData.toObject(includeInstance, f),
updateJoysticksSettings: (f = msg.getUpdateJoysticksSettings()) && pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsResultData.toObject(includeInstance, f),
updateShapeMap: (f = msg.getUpdateShapeMap()) && pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapResultData.toObject(includeInstance, f),
spoofObstacle: (f = msg.getSpoofObstacle()) && pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleResultData.toObject(includeInstance, f),
dummyAction: (f = msg.getDummyAction()) && pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyResultData.toObject(includeInstance, f),
testDesiredVelocityAction: (f = msg.getTestDesiredVelocityAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityResultData.toObject(includeInstance, f),
testTrackTrajectory: (f = msg.getTestTrackTrajectory()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryResultData.toObject(includeInstance, f),
atvSubjectSpoofAction: (f = msg.getAtvSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofResultData.toObject(includeInstance, f),
skiSubjectSpoofAction: (f = msg.getSkiSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofResultData.toObject(includeInstance, f),
setGimbalOrientation: (f = msg.getSetGimbalOrientation()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationResultData.toObject(includeInstance, f),
parallel: (f = msg.getParallel()) && pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelResultData.toObject(includeInstance, f),
sequenceUntilSuccess: (f = msg.getSequenceUntilSuccess()) && pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilSuccessResultData.toObject(includeInstance, f),
sequenceUntilFailure: (f = msg.getSequenceUntilFailure()) && pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilFailureResultData.toObject(includeInstance, f),
sequencePretakeoff: (f = msg.getSequencePretakeoff()) && pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequencePretakeoffResultData.toObject(includeInstance, f),
spoofDisturbanceAction: (f = msg.getSpoofDisturbanceAction()) && pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceResultData.toObject(includeInstance, f),
updateVehicleSettings: (f = msg.getUpdateVehicleSettings()) && pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsResultData.toObject(includeInstance, f),
waitForVehicleStop: (f = msg.getWaitForVehicleStop()) && pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopResultData.toObject(includeInstance, f),
precomputedSubjectSpoofAction: (f = msg.getPrecomputedSubjectSpoofAction()) && pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofResultData.toObject(includeInstance, f),
modifySimStateEstimation: (f = msg.getModifySimStateEstimation()) && pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationResultData.toObject(includeInstance, f),
gotoAndOrbitPoi: (f = msg.getGotoAndOrbitPoi()) && pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiResultData.toObject(includeInstance, f),
acquireGps: (f = msg.getAcquireGps()) && pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsResultData.toObject(includeInstance, f),
setFaultOverride: (f = msg.getSetFaultOverride()) && pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideResultData.toObject(includeInstance, f),
eLand: (f = msg.getELand()) && pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandResultData.toObject(includeInstance, f),
trajectoryInspect: (f = msg.getTrajectoryInspect()) && pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectResultData.toObject(includeInstance, f),
toggleTargetPointExposure: (f = msg.getToggleTargetPointExposure()) && pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureResultData.toObject(includeInstance, f),
toggleSemanticAim: (f = msg.getToggleSemanticAim()) && pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimResultData.toObject(includeInstance, f),
spoofBatteryLevel: (f = msg.getSpoofBatteryLevel()) && pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelResultData.toObject(includeInstance, f),
ncpgControl: (f = msg.getNcpgControl()) && pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlResultData.toObject(includeInstance, f),
registerComplimentaryActions: (f = msg.getRegisterComplimentaryActions()) && pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsResultData.toObject(includeInstance, f),
trackInPlace: (f = msg.getTrackInPlace()) && pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceResultData.toObject(includeInstance, f),
globalGraphControl: (f = msg.getGlobalGraphControl()) && pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlResultData.toObject(includeInstance, f),
overrideGpsQuality: (f = msg.getOverrideGpsQuality()) && pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityResultData.toObject(includeInstance, f),
initGlobalMap: (f = msg.getInitGlobalMap()) && pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapResultData.toObject(includeInstance, f),
stopForPhotoInspect: (f = msg.getStopForPhotoInspect()) && pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectResultData.toObject(includeInstance, f),
spoofInitialGpsHeading: (f = msg.getSpoofInitialGpsHeading()) && pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingResultData.toObject(includeInstance, f),
trackLandingPadRequest: (f = msg.getTrackLandingPadRequest()) && pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestResultData.toObject(includeInstance, f),
apriltagSetId: (f = msg.getApriltagSetId()) && pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdResultData.toObject(includeInstance, f),
dockGpsEstimator: (f = msg.getDockGpsEstimator()) && pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorResultData.toObject(includeInstance, f),
g47CommandOverride: (f = msg.getG47CommandOverride()) && pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideResultData.toObject(includeInstance, f),
requestTakeoffProfile: (f = msg.getRequestTakeoffProfile()) && pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileResultData.toObject(includeInstance, f),
updateDockLocationAction: (f = msg.getUpdateDockLocationAction()) && pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionResultData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ActionResult}
 */
proto.skills.ActionResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ActionResult;
  return proto.skills.ActionResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ActionResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ActionResult}
 */
proto.skills.ActionResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUuid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUclock(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndUclock(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUtime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndUtime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebugJson(value);
      break;
    case 10:
      var value = /** @type {!proto.skills.ActionResult.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentActionResultUuid(value);
      break;
    case 102:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointResultData.deserializeBinaryFromReader);
      msg.setGotoGpsWaypoint(value);
      break;
    case 103:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointResultData.deserializeBinaryFromReader);
      msg.setGotoNavWaypoint(value);
      break;
    case 104:
      var value = new pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoResultData.deserializeBinaryFromReader);
      msg.setTakePhoto(value);
      break;
    case 105:
      var value = new pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanResultData.deserializeBinaryFromReader);
      msg.setSurfaceScanSkill(value);
      break;
    case 106:
      var value = new pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoResultData.deserializeBinaryFromReader);
      msg.setStartVideo(value);
      break;
    case 107:
      var value = new pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoResultData.deserializeBinaryFromReader);
      msg.setStopVideo(value);
      break;
    case 108:
      var value = new pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeResultData.deserializeBinaryFromReader);
      msg.setSwitchRecordingMode(value);
      break;
    case 109:
      var value = new pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitResultData.deserializeBinaryFromReader);
      msg.setTimedWait(value);
      break;
    case 110:
      var value = new pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceResultData.deserializeBinaryFromReader);
      msg.setSequence(value);
      break;
    case 111:
      var value = new pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiResultData.deserializeBinaryFromReader);
      msg.setOrbitPoiAction(value);
      break;
    case 112:
      var value = new pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaResultData.deserializeBinaryFromReader);
      msg.setTakePanorama(value);
      break;
    case 113:
      var value = new pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeResultData.deserializeBinaryFromReader);
      msg.setKeyframeSkill(value);
      break;
    case 114:
      var value = new pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointResultData.deserializeBinaryFromReader);
      msg.setGotoWaypoint(value);
      break;
    case 115:
      var value = new pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceResultData.deserializeBinaryFromReader);
      msg.setSetObstacleAvoidance(value);
      break;
    case 116:
      var value = new pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsResultData.deserializeBinaryFromReader);
      msg.setInitializeGps(value);
      break;
    case 117:
      var value = new pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedResultData.deserializeBinaryFromReader);
      msg.setSubjectDetected(value);
      break;
    case 118:
      var value = new pbtypes_vehicle_skills_actions_looper_looper_pb.LooperResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_looper_looper_pb.LooperResultData.deserializeBinaryFromReader);
      msg.setLooper(value);
      break;
    case 119:
      var value = new pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceResultData.deserializeBinaryFromReader);
      msg.setWithinDistance(value);
      break;
    case 120:
      var value = new pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowResultData.deserializeBinaryFromReader);
      msg.setUnifiedFollowAction(value);
      break;
    case 121:
      var value = new pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterResultData.deserializeBinaryFromReader);
      msg.setInverter(value);
      break;
    case 122:
      var value = new pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeResultData.deserializeBinaryFromReader);
      msg.setTogglePhotoIntervalMode(value);
      break;
    case 123:
      var value = new pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanResultData.deserializeBinaryFromReader);
      msg.setWifiScan(value);
      break;
    case 124:
      var value = new proto.skills.EnableStrobeLightsResultData;
      reader.readMessage(value,proto.skills.EnableStrobeLightsResultData.deserializeBinaryFromReader);
      msg.setEnableStrobeLights(value);
      break;
    case 125:
      var value = new proto.skills.DisableStrobeLightsResultData;
      reader.readMessage(value,proto.skills.DisableStrobeLightsResultData.deserializeBinaryFromReader);
      msg.setDisableStrobeLights(value);
      break;
    case 126:
      var value = new pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsResultData.deserializeBinaryFromReader);
      msg.setSpoofJoysticksInputs(value);
      break;
    case 127:
      var value = new pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsResultData.deserializeBinaryFromReader);
      msg.setUpdateJoysticksSettings(value);
      break;
    case 128:
      var value = new pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapResultData.deserializeBinaryFromReader);
      msg.setUpdateShapeMap(value);
      break;
    case 129:
      var value = new pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleResultData.deserializeBinaryFromReader);
      msg.setSpoofObstacle(value);
      break;
    case 130:
      var value = new pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyResultData.deserializeBinaryFromReader);
      msg.setDummyAction(value);
      break;
    case 131:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityResultData.deserializeBinaryFromReader);
      msg.setTestDesiredVelocityAction(value);
      break;
    case 132:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryResultData.deserializeBinaryFromReader);
      msg.setTestTrackTrajectory(value);
      break;
    case 133:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofResultData.deserializeBinaryFromReader);
      msg.setAtvSubjectSpoofAction(value);
      break;
    case 134:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofResultData.deserializeBinaryFromReader);
      msg.setSkiSubjectSpoofAction(value);
      break;
    case 135:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationResultData.deserializeBinaryFromReader);
      msg.setSetGimbalOrientation(value);
      break;
    case 136:
      var value = new pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelResultData.deserializeBinaryFromReader);
      msg.setParallel(value);
      break;
    case 137:
      var value = new pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilSuccessResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilSuccessResultData.deserializeBinaryFromReader);
      msg.setSequenceUntilSuccess(value);
      break;
    case 138:
      var value = new pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilFailureResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilFailureResultData.deserializeBinaryFromReader);
      msg.setSequenceUntilFailure(value);
      break;
    case 159:
      var value = new pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequencePretakeoffResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequencePretakeoffResultData.deserializeBinaryFromReader);
      msg.setSequencePretakeoff(value);
      break;
    case 139:
      var value = new pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceResultData.deserializeBinaryFromReader);
      msg.setSpoofDisturbanceAction(value);
      break;
    case 140:
      var value = new pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsResultData.deserializeBinaryFromReader);
      msg.setUpdateVehicleSettings(value);
      break;
    case 141:
      var value = new pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopResultData.deserializeBinaryFromReader);
      msg.setWaitForVehicleStop(value);
      break;
    case 142:
      var value = new pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofResultData.deserializeBinaryFromReader);
      msg.setPrecomputedSubjectSpoofAction(value);
      break;
    case 143:
      var value = new pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationResultData.deserializeBinaryFromReader);
      msg.setModifySimStateEstimation(value);
      break;
    case 144:
      var value = new pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiResultData.deserializeBinaryFromReader);
      msg.setGotoAndOrbitPoi(value);
      break;
    case 145:
      var value = new pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsResultData.deserializeBinaryFromReader);
      msg.setAcquireGps(value);
      break;
    case 146:
      var value = new pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideResultData.deserializeBinaryFromReader);
      msg.setSetFaultOverride(value);
      break;
    case 147:
      var value = new pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandResultData.deserializeBinaryFromReader);
      msg.setELand(value);
      break;
    case 148:
      var value = new pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectResultData.deserializeBinaryFromReader);
      msg.setTrajectoryInspect(value);
      break;
    case 149:
      var value = new pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureResultData.deserializeBinaryFromReader);
      msg.setToggleTargetPointExposure(value);
      break;
    case 150:
      var value = new pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimResultData.deserializeBinaryFromReader);
      msg.setToggleSemanticAim(value);
      break;
    case 151:
      var value = new pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelResultData.deserializeBinaryFromReader);
      msg.setSpoofBatteryLevel(value);
      break;
    case 152:
      var value = new pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlResultData.deserializeBinaryFromReader);
      msg.setNcpgControl(value);
      break;
    case 153:
      var value = new pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsResultData.deserializeBinaryFromReader);
      msg.setRegisterComplimentaryActions(value);
      break;
    case 154:
      var value = new pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceResultData.deserializeBinaryFromReader);
      msg.setTrackInPlace(value);
      break;
    case 155:
      var value = new pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlResultData.deserializeBinaryFromReader);
      msg.setGlobalGraphControl(value);
      break;
    case 156:
      var value = new pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityResultData.deserializeBinaryFromReader);
      msg.setOverrideGpsQuality(value);
      break;
    case 157:
      var value = new pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapResultData.deserializeBinaryFromReader);
      msg.setInitGlobalMap(value);
      break;
    case 158:
      var value = new pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectResultData.deserializeBinaryFromReader);
      msg.setStopForPhotoInspect(value);
      break;
    case 160:
      var value = new pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingResultData.deserializeBinaryFromReader);
      msg.setSpoofInitialGpsHeading(value);
      break;
    case 161:
      var value = new pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestResultData.deserializeBinaryFromReader);
      msg.setTrackLandingPadRequest(value);
      break;
    case 162:
      var value = new pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdResultData.deserializeBinaryFromReader);
      msg.setApriltagSetId(value);
      break;
    case 163:
      var value = new pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorResultData.deserializeBinaryFromReader);
      msg.setDockGpsEstimator(value);
      break;
    case 164:
      var value = new pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideResultData.deserializeBinaryFromReader);
      msg.setG47CommandOverride(value);
      break;
    case 165:
      var value = new pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileResultData.deserializeBinaryFromReader);
      msg.setRequestTakeoffProfile(value);
      break;
    case 166:
      var value = new pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionResultData;
      reader.readMessage(value,pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionResultData.deserializeBinaryFromReader);
      msg.setUpdateDockLocationAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ActionResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ActionResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ActionResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ActionResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getActionUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionKey();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartUclock();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndUclock();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStartUtime();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getEndUtime();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getDebugJson();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getParentActionResultUuid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getGotoGpsWaypoint();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointResultData.serializeBinaryToWriter
    );
  }
  f = message.getGotoNavWaypoint();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointResultData.serializeBinaryToWriter
    );
  }
  f = message.getTakePhoto();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoResultData.serializeBinaryToWriter
    );
  }
  f = message.getSurfaceScanSkill();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanResultData.serializeBinaryToWriter
    );
  }
  f = message.getStartVideo();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoResultData.serializeBinaryToWriter
    );
  }
  f = message.getStopVideo();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoResultData.serializeBinaryToWriter
    );
  }
  f = message.getSwitchRecordingMode();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeResultData.serializeBinaryToWriter
    );
  }
  f = message.getTimedWait();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitResultData.serializeBinaryToWriter
    );
  }
  f = message.getSequence();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceResultData.serializeBinaryToWriter
    );
  }
  f = message.getOrbitPoiAction();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiResultData.serializeBinaryToWriter
    );
  }
  f = message.getTakePanorama();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaResultData.serializeBinaryToWriter
    );
  }
  f = message.getKeyframeSkill();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeResultData.serializeBinaryToWriter
    );
  }
  f = message.getGotoWaypoint();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointResultData.serializeBinaryToWriter
    );
  }
  f = message.getSetObstacleAvoidance();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceResultData.serializeBinaryToWriter
    );
  }
  f = message.getInitializeGps();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsResultData.serializeBinaryToWriter
    );
  }
  f = message.getSubjectDetected();
  if (f != null) {
    writer.writeMessage(
      117,
      f,
      pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedResultData.serializeBinaryToWriter
    );
  }
  f = message.getLooper();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      pbtypes_vehicle_skills_actions_looper_looper_pb.LooperResultData.serializeBinaryToWriter
    );
  }
  f = message.getWithinDistance();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceResultData.serializeBinaryToWriter
    );
  }
  f = message.getUnifiedFollowAction();
  if (f != null) {
    writer.writeMessage(
      120,
      f,
      pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowResultData.serializeBinaryToWriter
    );
  }
  f = message.getInverter();
  if (f != null) {
    writer.writeMessage(
      121,
      f,
      pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterResultData.serializeBinaryToWriter
    );
  }
  f = message.getTogglePhotoIntervalMode();
  if (f != null) {
    writer.writeMessage(
      122,
      f,
      pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeResultData.serializeBinaryToWriter
    );
  }
  f = message.getWifiScan();
  if (f != null) {
    writer.writeMessage(
      123,
      f,
      pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanResultData.serializeBinaryToWriter
    );
  }
  f = message.getEnableStrobeLights();
  if (f != null) {
    writer.writeMessage(
      124,
      f,
      proto.skills.EnableStrobeLightsResultData.serializeBinaryToWriter
    );
  }
  f = message.getDisableStrobeLights();
  if (f != null) {
    writer.writeMessage(
      125,
      f,
      proto.skills.DisableStrobeLightsResultData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofJoysticksInputs();
  if (f != null) {
    writer.writeMessage(
      126,
      f,
      pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsResultData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateJoysticksSettings();
  if (f != null) {
    writer.writeMessage(
      127,
      f,
      pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsResultData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateShapeMap();
  if (f != null) {
    writer.writeMessage(
      128,
      f,
      pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapResultData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofObstacle();
  if (f != null) {
    writer.writeMessage(
      129,
      f,
      pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleResultData.serializeBinaryToWriter
    );
  }
  f = message.getDummyAction();
  if (f != null) {
    writer.writeMessage(
      130,
      f,
      pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyResultData.serializeBinaryToWriter
    );
  }
  f = message.getTestDesiredVelocityAction();
  if (f != null) {
    writer.writeMessage(
      131,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityResultData.serializeBinaryToWriter
    );
  }
  f = message.getTestTrackTrajectory();
  if (f != null) {
    writer.writeMessage(
      132,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryResultData.serializeBinaryToWriter
    );
  }
  f = message.getAtvSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      133,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofResultData.serializeBinaryToWriter
    );
  }
  f = message.getSkiSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      134,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofResultData.serializeBinaryToWriter
    );
  }
  f = message.getSetGimbalOrientation();
  if (f != null) {
    writer.writeMessage(
      135,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationResultData.serializeBinaryToWriter
    );
  }
  f = message.getParallel();
  if (f != null) {
    writer.writeMessage(
      136,
      f,
      pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelResultData.serializeBinaryToWriter
    );
  }
  f = message.getSequenceUntilSuccess();
  if (f != null) {
    writer.writeMessage(
      137,
      f,
      pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilSuccessResultData.serializeBinaryToWriter
    );
  }
  f = message.getSequenceUntilFailure();
  if (f != null) {
    writer.writeMessage(
      138,
      f,
      pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilFailureResultData.serializeBinaryToWriter
    );
  }
  f = message.getSequencePretakeoff();
  if (f != null) {
    writer.writeMessage(
      159,
      f,
      pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequencePretakeoffResultData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofDisturbanceAction();
  if (f != null) {
    writer.writeMessage(
      139,
      f,
      pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceResultData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateVehicleSettings();
  if (f != null) {
    writer.writeMessage(
      140,
      f,
      pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsResultData.serializeBinaryToWriter
    );
  }
  f = message.getWaitForVehicleStop();
  if (f != null) {
    writer.writeMessage(
      141,
      f,
      pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopResultData.serializeBinaryToWriter
    );
  }
  f = message.getPrecomputedSubjectSpoofAction();
  if (f != null) {
    writer.writeMessage(
      142,
      f,
      pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofResultData.serializeBinaryToWriter
    );
  }
  f = message.getModifySimStateEstimation();
  if (f != null) {
    writer.writeMessage(
      143,
      f,
      pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationResultData.serializeBinaryToWriter
    );
  }
  f = message.getGotoAndOrbitPoi();
  if (f != null) {
    writer.writeMessage(
      144,
      f,
      pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiResultData.serializeBinaryToWriter
    );
  }
  f = message.getAcquireGps();
  if (f != null) {
    writer.writeMessage(
      145,
      f,
      pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsResultData.serializeBinaryToWriter
    );
  }
  f = message.getSetFaultOverride();
  if (f != null) {
    writer.writeMessage(
      146,
      f,
      pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideResultData.serializeBinaryToWriter
    );
  }
  f = message.getELand();
  if (f != null) {
    writer.writeMessage(
      147,
      f,
      pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandResultData.serializeBinaryToWriter
    );
  }
  f = message.getTrajectoryInspect();
  if (f != null) {
    writer.writeMessage(
      148,
      f,
      pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectResultData.serializeBinaryToWriter
    );
  }
  f = message.getToggleTargetPointExposure();
  if (f != null) {
    writer.writeMessage(
      149,
      f,
      pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureResultData.serializeBinaryToWriter
    );
  }
  f = message.getToggleSemanticAim();
  if (f != null) {
    writer.writeMessage(
      150,
      f,
      pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimResultData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofBatteryLevel();
  if (f != null) {
    writer.writeMessage(
      151,
      f,
      pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelResultData.serializeBinaryToWriter
    );
  }
  f = message.getNcpgControl();
  if (f != null) {
    writer.writeMessage(
      152,
      f,
      pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlResultData.serializeBinaryToWriter
    );
  }
  f = message.getRegisterComplimentaryActions();
  if (f != null) {
    writer.writeMessage(
      153,
      f,
      pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsResultData.serializeBinaryToWriter
    );
  }
  f = message.getTrackInPlace();
  if (f != null) {
    writer.writeMessage(
      154,
      f,
      pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceResultData.serializeBinaryToWriter
    );
  }
  f = message.getGlobalGraphControl();
  if (f != null) {
    writer.writeMessage(
      155,
      f,
      pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlResultData.serializeBinaryToWriter
    );
  }
  f = message.getOverrideGpsQuality();
  if (f != null) {
    writer.writeMessage(
      156,
      f,
      pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityResultData.serializeBinaryToWriter
    );
  }
  f = message.getInitGlobalMap();
  if (f != null) {
    writer.writeMessage(
      157,
      f,
      pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapResultData.serializeBinaryToWriter
    );
  }
  f = message.getStopForPhotoInspect();
  if (f != null) {
    writer.writeMessage(
      158,
      f,
      pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectResultData.serializeBinaryToWriter
    );
  }
  f = message.getSpoofInitialGpsHeading();
  if (f != null) {
    writer.writeMessage(
      160,
      f,
      pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingResultData.serializeBinaryToWriter
    );
  }
  f = message.getTrackLandingPadRequest();
  if (f != null) {
    writer.writeMessage(
      161,
      f,
      pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestResultData.serializeBinaryToWriter
    );
  }
  f = message.getApriltagSetId();
  if (f != null) {
    writer.writeMessage(
      162,
      f,
      pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdResultData.serializeBinaryToWriter
    );
  }
  f = message.getDockGpsEstimator();
  if (f != null) {
    writer.writeMessage(
      163,
      f,
      pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorResultData.serializeBinaryToWriter
    );
  }
  f = message.getG47CommandOverride();
  if (f != null) {
    writer.writeMessage(
      164,
      f,
      pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideResultData.serializeBinaryToWriter
    );
  }
  f = message.getRequestTakeoffProfile();
  if (f != null) {
    writer.writeMessage(
      165,
      f,
      pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileResultData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateDockLocationAction();
  if (f != null) {
    writer.writeMessage(
      166,
      f,
      pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionResultData.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.skills.ActionResult.Result = {
  UNKNOWN: 0,
  SUCCESS: 1,
  DID_NOT_RUN: 8,
  SKIPPED: 2,
  FAILURE: 3,
  FAILED_CANNOT_REACH_WAYPOINT: 4,
  FAILED_GPS_INITIALIZATION: 5,
  FAILED_SWITCHING_RECORDING_MODE: 6,
  FAILED_PHOTO_CAPTURE: 7,
  FAILED_WAYPOINT_GEOFENCE: 9,
  FAILED_ENABLING_STROBES: 10,
  FAILED_DISABLING_STROBES: 11,
  FAILED_WAYPOINT_REJECTED: 12
};

/**
 * optional string uuid = 12;
 * @return {string}
 */
proto.skills.ActionResult.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string action_uuid = 1;
 * @return {string}
 */
proto.skills.ActionResult.prototype.getActionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setActionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action_key = 9;
 * @return {string}
 */
proto.skills.ActionResult.prototype.getActionKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setActionKey = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.skills.ActionResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 start_uclock = 3;
 * @return {number}
 */
proto.skills.ActionResult.prototype.getStartUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setStartUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_uclock = 4;
 * @return {number}
 */
proto.skills.ActionResult.prototype.getEndUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setEndUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 start_utime = 14;
 * @return {number}
 */
proto.skills.ActionResult.prototype.getStartUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setStartUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 end_utime = 15;
 * @return {number}
 */
proto.skills.ActionResult.prototype.getEndUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setEndUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string debug_json = 7;
 * @return {string}
 */
proto.skills.ActionResult.prototype.getDebugJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setDebugJson = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Result result = 10;
 * @return {!proto.skills.ActionResult.Result}
 */
proto.skills.ActionResult.prototype.getResult = function() {
  return /** @type {!proto.skills.ActionResult.Result} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.skills.ActionResult.Result} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string error_code = 11;
 * @return {string}
 */
proto.skills.ActionResult.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string parent_action_result_uuid = 13;
 * @return {string}
 */
proto.skills.ActionResult.prototype.getParentActionResultUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.setParentActionResultUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional GotoGpsWaypointResultData goto_gps_waypoint = 102;
 * @return {?proto.skills.GotoGpsWaypointResultData}
 */
proto.skills.ActionResult.prototype.getGotoGpsWaypoint = function() {
  return /** @type{?proto.skills.GotoGpsWaypointResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoGpsWaypointResultData, 102));
};


/**
 * @param {?proto.skills.GotoGpsWaypointResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setGotoGpsWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 102, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearGotoGpsWaypoint = function() {
  return this.setGotoGpsWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasGotoGpsWaypoint = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional GotoNavWaypointResultData goto_nav_waypoint = 103;
 * @return {?proto.skills.GotoNavWaypointResultData}
 */
proto.skills.ActionResult.prototype.getGotoNavWaypoint = function() {
  return /** @type{?proto.skills.GotoNavWaypointResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoNavWaypointResultData, 103));
};


/**
 * @param {?proto.skills.GotoNavWaypointResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setGotoNavWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 103, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearGotoNavWaypoint = function() {
  return this.setGotoNavWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasGotoNavWaypoint = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional TakePhotoResultData take_photo = 104;
 * @return {?proto.skills.TakePhotoResultData}
 */
proto.skills.ActionResult.prototype.getTakePhoto = function() {
  return /** @type{?proto.skills.TakePhotoResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_take_photo_take_photo_pb.TakePhotoResultData, 104));
};


/**
 * @param {?proto.skills.TakePhotoResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTakePhoto = function(value) {
  return jspb.Message.setOneofWrapperField(this, 104, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTakePhoto = function() {
  return this.setTakePhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTakePhoto = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional SurfaceScanResultData surface_scan_skill = 105;
 * @return {?proto.skills.SurfaceScanResultData}
 */
proto.skills.ActionResult.prototype.getSurfaceScanSkill = function() {
  return /** @type{?proto.skills.SurfaceScanResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_builtin_skills_surface_scan_surface_scan_pb.SurfaceScanResultData, 105));
};


/**
 * @param {?proto.skills.SurfaceScanResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSurfaceScanSkill = function(value) {
  return jspb.Message.setOneofWrapperField(this, 105, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSurfaceScanSkill = function() {
  return this.setSurfaceScanSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSurfaceScanSkill = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional StartVideoResultData start_video = 106;
 * @return {?proto.skills.StartVideoResultData}
 */
proto.skills.ActionResult.prototype.getStartVideo = function() {
  return /** @type{?proto.skills.StartVideoResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_start_video_start_video_pb.StartVideoResultData, 106));
};


/**
 * @param {?proto.skills.StartVideoResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setStartVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 106, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearStartVideo = function() {
  return this.setStartVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasStartVideo = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional StopVideoResultData stop_video = 107;
 * @return {?proto.skills.StopVideoResultData}
 */
proto.skills.ActionResult.prototype.getStopVideo = function() {
  return /** @type{?proto.skills.StopVideoResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_stop_video_stop_video_pb.StopVideoResultData, 107));
};


/**
 * @param {?proto.skills.StopVideoResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setStopVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 107, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearStopVideo = function() {
  return this.setStopVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasStopVideo = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional SwitchRecordingModeResultData switch_recording_mode = 108;
 * @return {?proto.skills.SwitchRecordingModeResultData}
 */
proto.skills.ActionResult.prototype.getSwitchRecordingMode = function() {
  return /** @type{?proto.skills.SwitchRecordingModeResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_switch_recording_mode_switch_recording_mode_pb.SwitchRecordingModeResultData, 108));
};


/**
 * @param {?proto.skills.SwitchRecordingModeResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSwitchRecordingMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 108, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSwitchRecordingMode = function() {
  return this.setSwitchRecordingMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSwitchRecordingMode = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional TimedWaitResultData timed_wait = 109;
 * @return {?proto.skills.TimedWaitResultData}
 */
proto.skills.ActionResult.prototype.getTimedWait = function() {
  return /** @type{?proto.skills.TimedWaitResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_timed_wait_timed_wait_pb.TimedWaitResultData, 109));
};


/**
 * @param {?proto.skills.TimedWaitResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTimedWait = function(value) {
  return jspb.Message.setOneofWrapperField(this, 109, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTimedWait = function() {
  return this.setTimedWait(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTimedWait = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional SequenceResultData sequence = 110;
 * @return {?proto.skills.SequenceResultData}
 */
proto.skills.ActionResult.prototype.getSequence = function() {
  return /** @type{?proto.skills.SequenceResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceResultData, 110));
};


/**
 * @param {?proto.skills.SequenceResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSequence = function(value) {
  return jspb.Message.setOneofWrapperField(this, 110, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSequence = function() {
  return this.setSequence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSequence = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional OrbitPoiResultData orbit_poi_action = 111;
 * @return {?proto.skills.OrbitPoiResultData}
 */
proto.skills.ActionResult.prototype.getOrbitPoiAction = function() {
  return /** @type{?proto.skills.OrbitPoiResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.OrbitPoiResultData, 111));
};


/**
 * @param {?proto.skills.OrbitPoiResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setOrbitPoiAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 111, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearOrbitPoiAction = function() {
  return this.setOrbitPoiAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasOrbitPoiAction = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional TakePanoramaResultData take_panorama = 112;
 * @return {?proto.skills.TakePanoramaResultData}
 */
proto.skills.ActionResult.prototype.getTakePanorama = function() {
  return /** @type{?proto.skills.TakePanoramaResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_take_panorama_take_panorama_pb.TakePanoramaResultData, 112));
};


/**
 * @param {?proto.skills.TakePanoramaResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTakePanorama = function(value) {
  return jspb.Message.setOneofWrapperField(this, 112, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTakePanorama = function() {
  return this.setTakePanorama(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTakePanorama = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional KeyframeResultData keyframe_skill = 113;
 * @return {?proto.skills.KeyframeResultData}
 */
proto.skills.ActionResult.prototype.getKeyframeSkill = function() {
  return /** @type{?proto.skills.KeyframeResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_builtin_skills_keyframe_keyframe_pb.KeyframeResultData, 113));
};


/**
 * @param {?proto.skills.KeyframeResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setKeyframeSkill = function(value) {
  return jspb.Message.setOneofWrapperField(this, 113, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearKeyframeSkill = function() {
  return this.setKeyframeSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasKeyframeSkill = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional GotoWaypointResultData goto_waypoint = 114;
 * @return {?proto.skills.GotoWaypointResultData}
 */
proto.skills.ActionResult.prototype.getGotoWaypoint = function() {
  return /** @type{?proto.skills.GotoWaypointResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_goto_waypoint_goto_waypoint_pb.GotoWaypointResultData, 114));
};


/**
 * @param {?proto.skills.GotoWaypointResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setGotoWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 114, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearGotoWaypoint = function() {
  return this.setGotoWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasGotoWaypoint = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional SetObstacleAvoidanceResultData set_obstacle_avoidance = 115;
 * @return {?proto.skills.SetObstacleAvoidanceResultData}
 */
proto.skills.ActionResult.prototype.getSetObstacleAvoidance = function() {
  return /** @type{?proto.skills.SetObstacleAvoidanceResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_set_obstacle_avoidance_set_obstacle_avoidance_pb.SetObstacleAvoidanceResultData, 115));
};


/**
 * @param {?proto.skills.SetObstacleAvoidanceResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSetObstacleAvoidance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 115, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSetObstacleAvoidance = function() {
  return this.setSetObstacleAvoidance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSetObstacleAvoidance = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional InitializeGpsResultData initialize_gps = 116;
 * @return {?proto.skills.InitializeGpsResultData}
 */
proto.skills.ActionResult.prototype.getInitializeGps = function() {
  return /** @type{?proto.skills.InitializeGpsResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_initialize_gps_initialize_gps_pb.InitializeGpsResultData, 116));
};


/**
 * @param {?proto.skills.InitializeGpsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setInitializeGps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 116, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearInitializeGps = function() {
  return this.setInitializeGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasInitializeGps = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional SubjectDetectedResultData subject_detected = 117;
 * @return {?proto.skills.SubjectDetectedResultData}
 */
proto.skills.ActionResult.prototype.getSubjectDetected = function() {
  return /** @type{?proto.skills.SubjectDetectedResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_conditional_conditional_pb.SubjectDetectedResultData, 117));
};


/**
 * @param {?proto.skills.SubjectDetectedResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSubjectDetected = function(value) {
  return jspb.Message.setOneofWrapperField(this, 117, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSubjectDetected = function() {
  return this.setSubjectDetected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSubjectDetected = function() {
  return jspb.Message.getField(this, 117) != null;
};


/**
 * optional LooperResultData looper = 118;
 * @return {?proto.skills.LooperResultData}
 */
proto.skills.ActionResult.prototype.getLooper = function() {
  return /** @type{?proto.skills.LooperResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_looper_looper_pb.LooperResultData, 118));
};


/**
 * @param {?proto.skills.LooperResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setLooper = function(value) {
  return jspb.Message.setOneofWrapperField(this, 118, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearLooper = function() {
  return this.setLooper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasLooper = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional WithinDistanceResultData within_distance = 119;
 * @return {?proto.skills.WithinDistanceResultData}
 */
proto.skills.ActionResult.prototype.getWithinDistance = function() {
  return /** @type{?proto.skills.WithinDistanceResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_within_distance_within_distance_pb.WithinDistanceResultData, 119));
};


/**
 * @param {?proto.skills.WithinDistanceResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setWithinDistance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 119, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearWithinDistance = function() {
  return this.setWithinDistance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasWithinDistance = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional UnifiedFollowResultData unified_follow_action = 120;
 * @return {?proto.skills.UnifiedFollowResultData}
 */
proto.skills.ActionResult.prototype.getUnifiedFollowAction = function() {
  return /** @type{?proto.skills.UnifiedFollowResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_unified_follow_unified_follow_pb.UnifiedFollowResultData, 120));
};


/**
 * @param {?proto.skills.UnifiedFollowResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setUnifiedFollowAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 120, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearUnifiedFollowAction = function() {
  return this.setUnifiedFollowAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasUnifiedFollowAction = function() {
  return jspb.Message.getField(this, 120) != null;
};


/**
 * optional InverterResultData inverter = 121;
 * @return {?proto.skills.InverterResultData}
 */
proto.skills.ActionResult.prototype.getInverter = function() {
  return /** @type{?proto.skills.InverterResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_inverter_inverter_pb.InverterResultData, 121));
};


/**
 * @param {?proto.skills.InverterResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setInverter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 121, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearInverter = function() {
  return this.setInverter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasInverter = function() {
  return jspb.Message.getField(this, 121) != null;
};


/**
 * optional TogglePhotoIntervalModeResultData toggle_photo_interval_mode = 122;
 * @return {?proto.skills.TogglePhotoIntervalModeResultData}
 */
proto.skills.ActionResult.prototype.getTogglePhotoIntervalMode = function() {
  return /** @type{?proto.skills.TogglePhotoIntervalModeResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_photo_interval_mode_toggle_photo_interval_mode_pb.TogglePhotoIntervalModeResultData, 122));
};


/**
 * @param {?proto.skills.TogglePhotoIntervalModeResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTogglePhotoIntervalMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 122, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTogglePhotoIntervalMode = function() {
  return this.setTogglePhotoIntervalMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTogglePhotoIntervalMode = function() {
  return jspb.Message.getField(this, 122) != null;
};


/**
 * optional WifiScanResultData wifi_scan = 123;
 * @return {?proto.skills.WifiScanResultData}
 */
proto.skills.ActionResult.prototype.getWifiScan = function() {
  return /** @type{?proto.skills.WifiScanResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_wifi_scan_wifi_scan_pb.WifiScanResultData, 123));
};


/**
 * @param {?proto.skills.WifiScanResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setWifiScan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 123, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearWifiScan = function() {
  return this.setWifiScan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasWifiScan = function() {
  return jspb.Message.getField(this, 123) != null;
};


/**
 * optional EnableStrobeLightsResultData enable_strobe_lights = 124;
 * @return {?proto.skills.EnableStrobeLightsResultData}
 */
proto.skills.ActionResult.prototype.getEnableStrobeLights = function() {
  return /** @type{?proto.skills.EnableStrobeLightsResultData} */ (
    jspb.Message.getWrapperField(this, proto.skills.EnableStrobeLightsResultData, 124));
};


/**
 * @param {?proto.skills.EnableStrobeLightsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setEnableStrobeLights = function(value) {
  return jspb.Message.setOneofWrapperField(this, 124, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearEnableStrobeLights = function() {
  return this.setEnableStrobeLights(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasEnableStrobeLights = function() {
  return jspb.Message.getField(this, 124) != null;
};


/**
 * optional DisableStrobeLightsResultData disable_strobe_lights = 125;
 * @return {?proto.skills.DisableStrobeLightsResultData}
 */
proto.skills.ActionResult.prototype.getDisableStrobeLights = function() {
  return /** @type{?proto.skills.DisableStrobeLightsResultData} */ (
    jspb.Message.getWrapperField(this, proto.skills.DisableStrobeLightsResultData, 125));
};


/**
 * @param {?proto.skills.DisableStrobeLightsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setDisableStrobeLights = function(value) {
  return jspb.Message.setOneofWrapperField(this, 125, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearDisableStrobeLights = function() {
  return this.setDisableStrobeLights(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasDisableStrobeLights = function() {
  return jspb.Message.getField(this, 125) != null;
};


/**
 * optional SpoofJoysticksInputsResultData spoof_joysticks_inputs = 126;
 * @return {?proto.skills.SpoofJoysticksInputsResultData}
 */
proto.skills.ActionResult.prototype.getSpoofJoysticksInputs = function() {
  return /** @type{?proto.skills.SpoofJoysticksInputsResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_joysticks_inputs_spoof_joysticks_inputs_pb.SpoofJoysticksInputsResultData, 126));
};


/**
 * @param {?proto.skills.SpoofJoysticksInputsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSpoofJoysticksInputs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 126, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSpoofJoysticksInputs = function() {
  return this.setSpoofJoysticksInputs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSpoofJoysticksInputs = function() {
  return jspb.Message.getField(this, 126) != null;
};


/**
 * optional UpdateJoysticksSettingsResultData update_joysticks_settings = 127;
 * @return {?proto.skills.UpdateJoysticksSettingsResultData}
 */
proto.skills.ActionResult.prototype.getUpdateJoysticksSettings = function() {
  return /** @type{?proto.skills.UpdateJoysticksSettingsResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_joysticks_settings_update_joysticks_settings_pb.UpdateJoysticksSettingsResultData, 127));
};


/**
 * @param {?proto.skills.UpdateJoysticksSettingsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setUpdateJoysticksSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 127, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearUpdateJoysticksSettings = function() {
  return this.setUpdateJoysticksSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasUpdateJoysticksSettings = function() {
  return jspb.Message.getField(this, 127) != null;
};


/**
 * optional UpdateShapeMapResultData update_shape_map = 128;
 * @return {?proto.skills.UpdateShapeMapResultData}
 */
proto.skills.ActionResult.prototype.getUpdateShapeMap = function() {
  return /** @type{?proto.skills.UpdateShapeMapResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_shape_map_update_shape_map_pb.UpdateShapeMapResultData, 128));
};


/**
 * @param {?proto.skills.UpdateShapeMapResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setUpdateShapeMap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 128, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearUpdateShapeMap = function() {
  return this.setUpdateShapeMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasUpdateShapeMap = function() {
  return jspb.Message.getField(this, 128) != null;
};


/**
 * optional SpoofObstacleResultData spoof_obstacle = 129;
 * @return {?proto.skills.SpoofObstacleResultData}
 */
proto.skills.ActionResult.prototype.getSpoofObstacle = function() {
  return /** @type{?proto.skills.SpoofObstacleResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_obstacle_spoof_obstacle_pb.SpoofObstacleResultData, 129));
};


/**
 * @param {?proto.skills.SpoofObstacleResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSpoofObstacle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 129, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSpoofObstacle = function() {
  return this.setSpoofObstacle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSpoofObstacle = function() {
  return jspb.Message.getField(this, 129) != null;
};


/**
 * optional DummyResultData dummy_action = 130;
 * @return {?proto.skills.DummyResultData}
 */
proto.skills.ActionResult.prototype.getDummyAction = function() {
  return /** @type{?proto.skills.DummyResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_dummy_action_dummy_action_pb.DummyResultData, 130));
};


/**
 * @param {?proto.skills.DummyResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setDummyAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 130, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearDummyAction = function() {
  return this.setDummyAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasDummyAction = function() {
  return jspb.Message.getField(this, 130) != null;
};


/**
 * optional TestDesiredVelocityResultData test_desired_velocity_action = 131;
 * @return {?proto.skills.TestDesiredVelocityResultData}
 */
proto.skills.ActionResult.prototype.getTestDesiredVelocityAction = function() {
  return /** @type{?proto.skills.TestDesiredVelocityResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestDesiredVelocityResultData, 131));
};


/**
 * @param {?proto.skills.TestDesiredVelocityResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTestDesiredVelocityAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 131, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTestDesiredVelocityAction = function() {
  return this.setTestDesiredVelocityAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTestDesiredVelocityAction = function() {
  return jspb.Message.getField(this, 131) != null;
};


/**
 * optional TestTrackTrajectoryResultData test_track_trajectory = 132;
 * @return {?proto.skills.TestTrackTrajectoryResultData}
 */
proto.skills.ActionResult.prototype.getTestTrackTrajectory = function() {
  return /** @type{?proto.skills.TestTrackTrajectoryResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.TestTrackTrajectoryResultData, 132));
};


/**
 * @param {?proto.skills.TestTrackTrajectoryResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTestTrackTrajectory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 132, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTestTrackTrajectory = function() {
  return this.setTestTrackTrajectory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTestTrackTrajectory = function() {
  return jspb.Message.getField(this, 132) != null;
};


/**
 * optional AtvSubjectSpoofResultData atv_subject_spoof_action = 133;
 * @return {?proto.skills.AtvSubjectSpoofResultData}
 */
proto.skills.ActionResult.prototype.getAtvSubjectSpoofAction = function() {
  return /** @type{?proto.skills.AtvSubjectSpoofResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.AtvSubjectSpoofResultData, 133));
};


/**
 * @param {?proto.skills.AtvSubjectSpoofResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setAtvSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 133, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearAtvSubjectSpoofAction = function() {
  return this.setAtvSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasAtvSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 133) != null;
};


/**
 * optional SkiSubjectSpoofResultData ski_subject_spoof_action = 134;
 * @return {?proto.skills.SkiSubjectSpoofResultData}
 */
proto.skills.ActionResult.prototype.getSkiSubjectSpoofAction = function() {
  return /** @type{?proto.skills.SkiSubjectSpoofResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SkiSubjectSpoofResultData, 134));
};


/**
 * @param {?proto.skills.SkiSubjectSpoofResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSkiSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 134, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSkiSubjectSpoofAction = function() {
  return this.setSkiSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSkiSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 134) != null;
};


/**
 * optional SetGimbalOrientationResultData set_gimbal_orientation = 135;
 * @return {?proto.skills.SetGimbalOrientationResultData}
 */
proto.skills.ActionResult.prototype.getSetGimbalOrientation = function() {
  return /** @type{?proto.skills.SetGimbalOrientationResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.SetGimbalOrientationResultData, 135));
};


/**
 * @param {?proto.skills.SetGimbalOrientationResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSetGimbalOrientation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 135, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSetGimbalOrientation = function() {
  return this.setSetGimbalOrientation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSetGimbalOrientation = function() {
  return jspb.Message.getField(this, 135) != null;
};


/**
 * optional ParallelResultData parallel = 136;
 * @return {?proto.skills.ParallelResultData}
 */
proto.skills.ActionResult.prototype.getParallel = function() {
  return /** @type{?proto.skills.ParallelResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_parallel_parallel_pb.ParallelResultData, 136));
};


/**
 * @param {?proto.skills.ParallelResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setParallel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 136, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearParallel = function() {
  return this.setParallel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasParallel = function() {
  return jspb.Message.getField(this, 136) != null;
};


/**
 * optional SequenceUntilSuccessResultData sequence_until_success = 137;
 * @return {?proto.skills.SequenceUntilSuccessResultData}
 */
proto.skills.ActionResult.prototype.getSequenceUntilSuccess = function() {
  return /** @type{?proto.skills.SequenceUntilSuccessResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilSuccessResultData, 137));
};


/**
 * @param {?proto.skills.SequenceUntilSuccessResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSequenceUntilSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 137, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSequenceUntilSuccess = function() {
  return this.setSequenceUntilSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSequenceUntilSuccess = function() {
  return jspb.Message.getField(this, 137) != null;
};


/**
 * optional SequenceUntilFailureResultData sequence_until_failure = 138;
 * @return {?proto.skills.SequenceUntilFailureResultData}
 */
proto.skills.ActionResult.prototype.getSequenceUntilFailure = function() {
  return /** @type{?proto.skills.SequenceUntilFailureResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequenceUntilFailureResultData, 138));
};


/**
 * @param {?proto.skills.SequenceUntilFailureResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSequenceUntilFailure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 138, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSequenceUntilFailure = function() {
  return this.setSequenceUntilFailure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSequenceUntilFailure = function() {
  return jspb.Message.getField(this, 138) != null;
};


/**
 * optional SequencePretakeoffResultData sequence_pretakeoff = 159;
 * @return {?proto.skills.SequencePretakeoffResultData}
 */
proto.skills.ActionResult.prototype.getSequencePretakeoff = function() {
  return /** @type{?proto.skills.SequencePretakeoffResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_sequence_sequence_pb.SequencePretakeoffResultData, 159));
};


/**
 * @param {?proto.skills.SequencePretakeoffResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSequencePretakeoff = function(value) {
  return jspb.Message.setOneofWrapperField(this, 159, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSequencePretakeoff = function() {
  return this.setSequencePretakeoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSequencePretakeoff = function() {
  return jspb.Message.getField(this, 159) != null;
};


/**
 * optional SpoofDisturbanceResultData spoof_disturbance_action = 139;
 * @return {?proto.skills.SpoofDisturbanceResultData}
 */
proto.skills.ActionResult.prototype.getSpoofDisturbanceAction = function() {
  return /** @type{?proto.skills.SpoofDisturbanceResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_disturbance_action_spoof_disturbance_action_pb.SpoofDisturbanceResultData, 139));
};


/**
 * @param {?proto.skills.SpoofDisturbanceResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSpoofDisturbanceAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 139, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSpoofDisturbanceAction = function() {
  return this.setSpoofDisturbanceAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSpoofDisturbanceAction = function() {
  return jspb.Message.getField(this, 139) != null;
};


/**
 * optional UpdateVehicleSettingsResultData update_vehicle_settings = 140;
 * @return {?proto.skills.UpdateVehicleSettingsResultData}
 */
proto.skills.ActionResult.prototype.getUpdateVehicleSettings = function() {
  return /** @type{?proto.skills.UpdateVehicleSettingsResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_vehicle_settings_update_vehicle_settings_pb.UpdateVehicleSettingsResultData, 140));
};


/**
 * @param {?proto.skills.UpdateVehicleSettingsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setUpdateVehicleSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 140, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearUpdateVehicleSettings = function() {
  return this.setUpdateVehicleSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasUpdateVehicleSettings = function() {
  return jspb.Message.getField(this, 140) != null;
};


/**
 * optional WaitForVehicleStopResultData wait_for_vehicle_stop = 141;
 * @return {?proto.skills.WaitForVehicleStopResultData}
 */
proto.skills.ActionResult.prototype.getWaitForVehicleStop = function() {
  return /** @type{?proto.skills.WaitForVehicleStopResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_wait_for_vehicle_stop_wait_for_vehicle_stop_pb.WaitForVehicleStopResultData, 141));
};


/**
 * @param {?proto.skills.WaitForVehicleStopResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setWaitForVehicleStop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 141, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearWaitForVehicleStop = function() {
  return this.setWaitForVehicleStop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasWaitForVehicleStop = function() {
  return jspb.Message.getField(this, 141) != null;
};


/**
 * optional PrecomputedSubjectSpoofResultData precomputed_subject_spoof_action = 142;
 * @return {?proto.skills.PrecomputedSubjectSpoofResultData}
 */
proto.skills.ActionResult.prototype.getPrecomputedSubjectSpoofAction = function() {
  return /** @type{?proto.skills.PrecomputedSubjectSpoofResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_controls_test_controls_test_pb.PrecomputedSubjectSpoofResultData, 142));
};


/**
 * @param {?proto.skills.PrecomputedSubjectSpoofResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setPrecomputedSubjectSpoofAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 142, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearPrecomputedSubjectSpoofAction = function() {
  return this.setPrecomputedSubjectSpoofAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasPrecomputedSubjectSpoofAction = function() {
  return jspb.Message.getField(this, 142) != null;
};


/**
 * optional ModifySimStateEstimationResultData modify_sim_state_estimation = 143;
 * @return {?proto.skills.ModifySimStateEstimationResultData}
 */
proto.skills.ActionResult.prototype.getModifySimStateEstimation = function() {
  return /** @type{?proto.skills.ModifySimStateEstimationResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_modify_sim_state_estimation_modify_sim_state_estimation_pb.ModifySimStateEstimationResultData, 143));
};


/**
 * @param {?proto.skills.ModifySimStateEstimationResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setModifySimStateEstimation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 143, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearModifySimStateEstimation = function() {
  return this.setModifySimStateEstimation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasModifySimStateEstimation = function() {
  return jspb.Message.getField(this, 143) != null;
};


/**
 * optional GotoAndOrbitPoiResultData goto_and_orbit_poi = 144;
 * @return {?proto.skills.GotoAndOrbitPoiResultData}
 */
proto.skills.ActionResult.prototype.getGotoAndOrbitPoi = function() {
  return /** @type{?proto.skills.GotoAndOrbitPoiResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_orbit_poi_orbit_poi_pb.GotoAndOrbitPoiResultData, 144));
};


/**
 * @param {?proto.skills.GotoAndOrbitPoiResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setGotoAndOrbitPoi = function(value) {
  return jspb.Message.setOneofWrapperField(this, 144, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearGotoAndOrbitPoi = function() {
  return this.setGotoAndOrbitPoi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasGotoAndOrbitPoi = function() {
  return jspb.Message.getField(this, 144) != null;
};


/**
 * optional AcquireGpsResultData acquire_gps = 145;
 * @return {?proto.skills.AcquireGpsResultData}
 */
proto.skills.ActionResult.prototype.getAcquireGps = function() {
  return /** @type{?proto.skills.AcquireGpsResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_acquire_gps_acquire_gps_pb.AcquireGpsResultData, 145));
};


/**
 * @param {?proto.skills.AcquireGpsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setAcquireGps = function(value) {
  return jspb.Message.setOneofWrapperField(this, 145, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearAcquireGps = function() {
  return this.setAcquireGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasAcquireGps = function() {
  return jspb.Message.getField(this, 145) != null;
};


/**
 * optional SetFaultOverrideResultData set_fault_override = 146;
 * @return {?proto.skills.SetFaultOverrideResultData}
 */
proto.skills.ActionResult.prototype.getSetFaultOverride = function() {
  return /** @type{?proto.skills.SetFaultOverrideResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_set_fault_override_set_fault_override_pb.SetFaultOverrideResultData, 146));
};


/**
 * @param {?proto.skills.SetFaultOverrideResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSetFaultOverride = function(value) {
  return jspb.Message.setOneofWrapperField(this, 146, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSetFaultOverride = function() {
  return this.setSetFaultOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSetFaultOverride = function() {
  return jspb.Message.getField(this, 146) != null;
};


/**
 * optional ELandResultData e_land = 147;
 * @return {?proto.skills.ELandResultData}
 */
proto.skills.ActionResult.prototype.getELand = function() {
  return /** @type{?proto.skills.ELandResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_e_land_e_land_pb.ELandResultData, 147));
};


/**
 * @param {?proto.skills.ELandResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setELand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 147, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearELand = function() {
  return this.setELand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasELand = function() {
  return jspb.Message.getField(this, 147) != null;
};


/**
 * optional TrajectoryInspectResultData trajectory_inspect = 148;
 * @return {?proto.skills.TrajectoryInspectResultData}
 */
proto.skills.ActionResult.prototype.getTrajectoryInspect = function() {
  return /** @type{?proto.skills.TrajectoryInspectResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_trajectory_inspect_trajectory_inspect_pb.TrajectoryInspectResultData, 148));
};


/**
 * @param {?proto.skills.TrajectoryInspectResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTrajectoryInspect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 148, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTrajectoryInspect = function() {
  return this.setTrajectoryInspect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTrajectoryInspect = function() {
  return jspb.Message.getField(this, 148) != null;
};


/**
 * optional ToggleTargetPointExposureResultData toggle_target_point_exposure = 149;
 * @return {?proto.skills.ToggleTargetPointExposureResultData}
 */
proto.skills.ActionResult.prototype.getToggleTargetPointExposure = function() {
  return /** @type{?proto.skills.ToggleTargetPointExposureResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_target_point_exposure_toggle_target_point_exposure_pb.ToggleTargetPointExposureResultData, 149));
};


/**
 * @param {?proto.skills.ToggleTargetPointExposureResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setToggleTargetPointExposure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 149, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearToggleTargetPointExposure = function() {
  return this.setToggleTargetPointExposure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasToggleTargetPointExposure = function() {
  return jspb.Message.getField(this, 149) != null;
};


/**
 * optional ToggleSemanticAimResultData toggle_semantic_aim = 150;
 * @return {?proto.skills.ToggleSemanticAimResultData}
 */
proto.skills.ActionResult.prototype.getToggleSemanticAim = function() {
  return /** @type{?proto.skills.ToggleSemanticAimResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_toggle_semantic_aim_toggle_semantic_aim_pb.ToggleSemanticAimResultData, 150));
};


/**
 * @param {?proto.skills.ToggleSemanticAimResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setToggleSemanticAim = function(value) {
  return jspb.Message.setOneofWrapperField(this, 150, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearToggleSemanticAim = function() {
  return this.setToggleSemanticAim(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasToggleSemanticAim = function() {
  return jspb.Message.getField(this, 150) != null;
};


/**
 * optional SpoofBatteryLevelResultData spoof_battery_level = 151;
 * @return {?proto.skills.SpoofBatteryLevelResultData}
 */
proto.skills.ActionResult.prototype.getSpoofBatteryLevel = function() {
  return /** @type{?proto.skills.SpoofBatteryLevelResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_battery_level_spoof_battery_level_pb.SpoofBatteryLevelResultData, 151));
};


/**
 * @param {?proto.skills.SpoofBatteryLevelResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSpoofBatteryLevel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 151, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSpoofBatteryLevel = function() {
  return this.setSpoofBatteryLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSpoofBatteryLevel = function() {
  return jspb.Message.getField(this, 151) != null;
};


/**
 * optional NcpgControlResultData ncpg_control = 152;
 * @return {?proto.skills.NcpgControlResultData}
 */
proto.skills.ActionResult.prototype.getNcpgControl = function() {
  return /** @type{?proto.skills.NcpgControlResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_ncpg_control_ncpg_control_pb.NcpgControlResultData, 152));
};


/**
 * @param {?proto.skills.NcpgControlResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setNcpgControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 152, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearNcpgControl = function() {
  return this.setNcpgControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasNcpgControl = function() {
  return jspb.Message.getField(this, 152) != null;
};


/**
 * optional RegisterComplimentaryActionsResultData register_complimentary_actions = 153;
 * @return {?proto.skills.RegisterComplimentaryActionsResultData}
 */
proto.skills.ActionResult.prototype.getRegisterComplimentaryActions = function() {
  return /** @type{?proto.skills.RegisterComplimentaryActionsResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_register_complimentary_actions_register_complimentary_actions_pb.RegisterComplimentaryActionsResultData, 153));
};


/**
 * @param {?proto.skills.RegisterComplimentaryActionsResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setRegisterComplimentaryActions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 153, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearRegisterComplimentaryActions = function() {
  return this.setRegisterComplimentaryActions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasRegisterComplimentaryActions = function() {
  return jspb.Message.getField(this, 153) != null;
};


/**
 * optional TrackInPlaceResultData track_in_place = 154;
 * @return {?proto.skills.TrackInPlaceResultData}
 */
proto.skills.ActionResult.prototype.getTrackInPlace = function() {
  return /** @type{?proto.skills.TrackInPlaceResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_track_in_place_track_in_place_pb.TrackInPlaceResultData, 154));
};


/**
 * @param {?proto.skills.TrackInPlaceResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTrackInPlace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 154, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTrackInPlace = function() {
  return this.setTrackInPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTrackInPlace = function() {
  return jspb.Message.getField(this, 154) != null;
};


/**
 * optional GlobalGraphControlResultData global_graph_control = 155;
 * @return {?proto.skills.GlobalGraphControlResultData}
 */
proto.skills.ActionResult.prototype.getGlobalGraphControl = function() {
  return /** @type{?proto.skills.GlobalGraphControlResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_global_graph_control_global_graph_control_pb.GlobalGraphControlResultData, 155));
};


/**
 * @param {?proto.skills.GlobalGraphControlResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setGlobalGraphControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 155, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearGlobalGraphControl = function() {
  return this.setGlobalGraphControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasGlobalGraphControl = function() {
  return jspb.Message.getField(this, 155) != null;
};


/**
 * optional OverrideGpsQualityResultData override_gps_quality = 156;
 * @return {?proto.skills.OverrideGpsQualityResultData}
 */
proto.skills.ActionResult.prototype.getOverrideGpsQuality = function() {
  return /** @type{?proto.skills.OverrideGpsQualityResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_override_gps_quality_override_gps_quality_pb.OverrideGpsQualityResultData, 156));
};


/**
 * @param {?proto.skills.OverrideGpsQualityResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setOverrideGpsQuality = function(value) {
  return jspb.Message.setOneofWrapperField(this, 156, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearOverrideGpsQuality = function() {
  return this.setOverrideGpsQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasOverrideGpsQuality = function() {
  return jspb.Message.getField(this, 156) != null;
};


/**
 * optional InitGlobalMapResultData init_global_map = 157;
 * @return {?proto.skills.InitGlobalMapResultData}
 */
proto.skills.ActionResult.prototype.getInitGlobalMap = function() {
  return /** @type{?proto.skills.InitGlobalMapResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_init_global_map_init_global_map_pb.InitGlobalMapResultData, 157));
};


/**
 * @param {?proto.skills.InitGlobalMapResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setInitGlobalMap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 157, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearInitGlobalMap = function() {
  return this.setInitGlobalMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasInitGlobalMap = function() {
  return jspb.Message.getField(this, 157) != null;
};


/**
 * optional StopForPhotoInspectResultData stop_for_photo_inspect = 158;
 * @return {?proto.skills.StopForPhotoInspectResultData}
 */
proto.skills.ActionResult.prototype.getStopForPhotoInspect = function() {
  return /** @type{?proto.skills.StopForPhotoInspectResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_stop_for_photo_inspect_stop_for_photo_inspect_pb.StopForPhotoInspectResultData, 158));
};


/**
 * @param {?proto.skills.StopForPhotoInspectResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setStopForPhotoInspect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 158, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearStopForPhotoInspect = function() {
  return this.setStopForPhotoInspect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasStopForPhotoInspect = function() {
  return jspb.Message.getField(this, 158) != null;
};


/**
 * optional SpoofInitialGpsHeadingResultData spoof_initial_gps_heading = 160;
 * @return {?proto.skills.SpoofInitialGpsHeadingResultData}
 */
proto.skills.ActionResult.prototype.getSpoofInitialGpsHeading = function() {
  return /** @type{?proto.skills.SpoofInitialGpsHeadingResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_spoof_initial_gps_heading_spoof_initial_gps_heading_pb.SpoofInitialGpsHeadingResultData, 160));
};


/**
 * @param {?proto.skills.SpoofInitialGpsHeadingResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setSpoofInitialGpsHeading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 160, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearSpoofInitialGpsHeading = function() {
  return this.setSpoofInitialGpsHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasSpoofInitialGpsHeading = function() {
  return jspb.Message.getField(this, 160) != null;
};


/**
 * optional TrackLandingPadRequestResultData track_landing_pad_request = 161;
 * @return {?proto.skills.TrackLandingPadRequestResultData}
 */
proto.skills.ActionResult.prototype.getTrackLandingPadRequest = function() {
  return /** @type{?proto.skills.TrackLandingPadRequestResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_track_landing_pad_request_track_landing_pad_request_pb.TrackLandingPadRequestResultData, 161));
};


/**
 * @param {?proto.skills.TrackLandingPadRequestResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setTrackLandingPadRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 161, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearTrackLandingPadRequest = function() {
  return this.setTrackLandingPadRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasTrackLandingPadRequest = function() {
  return jspb.Message.getField(this, 161) != null;
};


/**
 * optional ApriltagSetIdResultData apriltag_set_id = 162;
 * @return {?proto.skills.ApriltagSetIdResultData}
 */
proto.skills.ActionResult.prototype.getApriltagSetId = function() {
  return /** @type{?proto.skills.ApriltagSetIdResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_apriltag_set_id_apriltag_set_id_pb.ApriltagSetIdResultData, 162));
};


/**
 * @param {?proto.skills.ApriltagSetIdResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setApriltagSetId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 162, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearApriltagSetId = function() {
  return this.setApriltagSetId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasApriltagSetId = function() {
  return jspb.Message.getField(this, 162) != null;
};


/**
 * optional DockGpsEstimatorResultData dock_gps_estimator = 163;
 * @return {?proto.skills.DockGpsEstimatorResultData}
 */
proto.skills.ActionResult.prototype.getDockGpsEstimator = function() {
  return /** @type{?proto.skills.DockGpsEstimatorResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_dock_gps_estimator_dock_gps_estimator_pb.DockGpsEstimatorResultData, 163));
};


/**
 * @param {?proto.skills.DockGpsEstimatorResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setDockGpsEstimator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 163, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearDockGpsEstimator = function() {
  return this.setDockGpsEstimator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasDockGpsEstimator = function() {
  return jspb.Message.getField(this, 163) != null;
};


/**
 * optional G47CommandOverrideResultData g47_command_override = 164;
 * @return {?proto.skills.G47CommandOverrideResultData}
 */
proto.skills.ActionResult.prototype.getG47CommandOverride = function() {
  return /** @type{?proto.skills.G47CommandOverrideResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_g47_command_override_g47_command_override_pb.G47CommandOverrideResultData, 164));
};


/**
 * @param {?proto.skills.G47CommandOverrideResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setG47CommandOverride = function(value) {
  return jspb.Message.setOneofWrapperField(this, 164, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearG47CommandOverride = function() {
  return this.setG47CommandOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasG47CommandOverride = function() {
  return jspb.Message.getField(this, 164) != null;
};


/**
 * optional RequestTakeoffProfileResultData request_takeoff_profile = 165;
 * @return {?proto.skills.RequestTakeoffProfileResultData}
 */
proto.skills.ActionResult.prototype.getRequestTakeoffProfile = function() {
  return /** @type{?proto.skills.RequestTakeoffProfileResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_request_takeoff_profile_request_takeoff_profile_pb.RequestTakeoffProfileResultData, 165));
};


/**
 * @param {?proto.skills.RequestTakeoffProfileResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setRequestTakeoffProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 165, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearRequestTakeoffProfile = function() {
  return this.setRequestTakeoffProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasRequestTakeoffProfile = function() {
  return jspb.Message.getField(this, 165) != null;
};


/**
 * optional UpdateDockLocationActionResultData update_dock_location_action = 166;
 * @return {?proto.skills.UpdateDockLocationActionResultData}
 */
proto.skills.ActionResult.prototype.getUpdateDockLocationAction = function() {
  return /** @type{?proto.skills.UpdateDockLocationActionResultData} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_actions_update_dock_location_update_dock_location_pb.UpdateDockLocationActionResultData, 166));
};


/**
 * @param {?proto.skills.UpdateDockLocationActionResultData|undefined} value
 * @return {!proto.skills.ActionResult} returns this
*/
proto.skills.ActionResult.prototype.setUpdateDockLocationAction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 166, proto.skills.ActionResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ActionResult} returns this
 */
proto.skills.ActionResult.prototype.clearUpdateDockLocationAction = function() {
  return this.setUpdateDockLocationAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ActionResult.prototype.hasUpdateDockLocationAction = function() {
  return jspb.Message.getField(this, 166) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.VehicleSupportedActions.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.VehicleSupportedActions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.VehicleSupportedActions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.VehicleSupportedActions.toObject = function(includeInstance, msg) {
  var f, obj = {
actionKeysArgsMap: (f = msg.getActionKeysArgsMap()) ? f.toObject(includeInstance, proto.skills.ActionArgs.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.VehicleSupportedActions}
 */
proto.skills.VehicleSupportedActions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.VehicleSupportedActions;
  return proto.skills.VehicleSupportedActions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.VehicleSupportedActions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.VehicleSupportedActions}
 */
proto.skills.VehicleSupportedActions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getActionKeysArgsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.skills.ActionArgs.deserializeBinaryFromReader, "", new proto.skills.ActionArgs());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.VehicleSupportedActions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.VehicleSupportedActions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.VehicleSupportedActions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.VehicleSupportedActions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionKeysArgsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.skills.ActionArgs.serializeBinaryToWriter);
  }
};


/**
 * map<string, ActionArgs> action_keys_args = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.skills.ActionArgs>}
 */
proto.skills.VehicleSupportedActions.prototype.getActionKeysArgsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.skills.ActionArgs>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.skills.ActionArgs));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skills.VehicleSupportedActions} returns this
 */
proto.skills.VehicleSupportedActions.prototype.clearActionKeysArgsMap = function() {
  this.getActionKeysArgsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.MissionTemplate.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MissionTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MissionTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MissionTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.skills.Action.toObject, includeInstance),
templateVersion: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MissionTemplate}
 */
proto.skills.MissionTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MissionTemplate;
  return proto.skills.MissionTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MissionTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MissionTemplate}
 */
proto.skills.MissionTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.skills.Action;
      reader.readMessage(value,proto.skills.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MissionTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MissionTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MissionTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MissionTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skills.Action.serializeBinaryToWriter
    );
  }
  f = message.getTemplateVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.skills.MissionTemplate.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionTemplate} returns this
 */
proto.skills.MissionTemplate.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.skills.MissionTemplate.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionTemplate} returns this
 */
proto.skills.MissionTemplate.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Action actions = 3;
 * @return {!Array<!proto.skills.Action>}
 */
proto.skills.MissionTemplate.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.skills.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Action, 3));
};


/**
 * @param {!Array<!proto.skills.Action>} value
 * @return {!proto.skills.MissionTemplate} returns this
*/
proto.skills.MissionTemplate.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skills.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Action}
 */
proto.skills.MissionTemplate.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skills.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.MissionTemplate} returns this
 */
proto.skills.MissionTemplate.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string template_version = 4;
 * @return {string}
 */
proto.skills.MissionTemplate.prototype.getTemplateVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.MissionTemplate} returns this
 */
proto.skills.MissionTemplate.prototype.setTemplateVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.SequenceActionArgs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SequenceActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SequenceActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SequenceActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.skills.Action.toObject, includeInstance),
hideReverseUi: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SequenceActionArgs}
 */
proto.skills.SequenceActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SequenceActionArgs;
  return proto.skills.SequenceActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SequenceActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SequenceActionArgs}
 */
proto.skills.SequenceActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 1:
      var value = new proto.skills.Action;
      reader.readMessage(value,proto.skills.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideReverseUi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SequenceActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SequenceActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SequenceActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skills.Action.serializeBinaryToWriter
    );
  }
  f = message.getHideReverseUi();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skills.SequenceActionArgs.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.SequenceActionArgs} returns this
 */
proto.skills.SequenceActionArgs.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Action actions = 1;
 * @return {!Array<!proto.skills.Action>}
 */
proto.skills.SequenceActionArgs.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.skills.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Action, 1));
};


/**
 * @param {!Array<!proto.skills.Action>} value
 * @return {!proto.skills.SequenceActionArgs} returns this
*/
proto.skills.SequenceActionArgs.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skills.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Action}
 */
proto.skills.SequenceActionArgs.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skills.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.SequenceActionArgs} returns this
 */
proto.skills.SequenceActionArgs.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional bool hide_reverse_ui = 3;
 * @return {boolean}
 */
proto.skills.SequenceActionArgs.prototype.getHideReverseUi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.SequenceActionArgs} returns this
 */
proto.skills.SequenceActionArgs.prototype.setHideReverseUi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SequenceActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SequenceActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SequenceActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
currentActionState: (f = msg.getCurrentActionState()) && proto.skills.ActionState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SequenceActionStateData}
 */
proto.skills.SequenceActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SequenceActionStateData;
  return proto.skills.SequenceActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SequenceActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SequenceActionStateData}
 */
proto.skills.SequenceActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.ActionState;
      reader.readMessage(value,proto.skills.ActionState.deserializeBinaryFromReader);
      msg.setCurrentActionState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SequenceActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SequenceActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SequenceActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentActionState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.ActionState.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActionState current_action_state = 1;
 * @return {?proto.skills.ActionState}
 */
proto.skills.SequenceActionStateData.prototype.getCurrentActionState = function() {
  return /** @type{?proto.skills.ActionState} */ (
    jspb.Message.getWrapperField(this, proto.skills.ActionState, 1));
};


/**
 * @param {?proto.skills.ActionState|undefined} value
 * @return {!proto.skills.SequenceActionStateData} returns this
*/
proto.skills.SequenceActionStateData.prototype.setCurrentActionState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SequenceActionStateData} returns this
 */
proto.skills.SequenceActionStateData.prototype.clearCurrentActionState = function() {
  return this.setCurrentActionState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SequenceActionStateData.prototype.hasCurrentActionState = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SequenceUntilFailureActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SequenceUntilFailureActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SequenceUntilFailureActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceUntilFailureActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
currentActionState: (f = msg.getCurrentActionState()) && proto.skills.ActionState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SequenceUntilFailureActionStateData}
 */
proto.skills.SequenceUntilFailureActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SequenceUntilFailureActionStateData;
  return proto.skills.SequenceUntilFailureActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SequenceUntilFailureActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SequenceUntilFailureActionStateData}
 */
proto.skills.SequenceUntilFailureActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.ActionState;
      reader.readMessage(value,proto.skills.ActionState.deserializeBinaryFromReader);
      msg.setCurrentActionState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SequenceUntilFailureActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SequenceUntilFailureActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SequenceUntilFailureActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceUntilFailureActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentActionState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.ActionState.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActionState current_action_state = 1;
 * @return {?proto.skills.ActionState}
 */
proto.skills.SequenceUntilFailureActionStateData.prototype.getCurrentActionState = function() {
  return /** @type{?proto.skills.ActionState} */ (
    jspb.Message.getWrapperField(this, proto.skills.ActionState, 1));
};


/**
 * @param {?proto.skills.ActionState|undefined} value
 * @return {!proto.skills.SequenceUntilFailureActionStateData} returns this
*/
proto.skills.SequenceUntilFailureActionStateData.prototype.setCurrentActionState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SequenceUntilFailureActionStateData} returns this
 */
proto.skills.SequenceUntilFailureActionStateData.prototype.clearCurrentActionState = function() {
  return this.setCurrentActionState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SequenceUntilFailureActionStateData.prototype.hasCurrentActionState = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SequenceUntilSuccessActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SequenceUntilSuccessActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SequenceUntilSuccessActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceUntilSuccessActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
currentActionState: (f = msg.getCurrentActionState()) && proto.skills.ActionState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SequenceUntilSuccessActionStateData}
 */
proto.skills.SequenceUntilSuccessActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SequenceUntilSuccessActionStateData;
  return proto.skills.SequenceUntilSuccessActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SequenceUntilSuccessActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SequenceUntilSuccessActionStateData}
 */
proto.skills.SequenceUntilSuccessActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.ActionState;
      reader.readMessage(value,proto.skills.ActionState.deserializeBinaryFromReader);
      msg.setCurrentActionState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SequenceUntilSuccessActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SequenceUntilSuccessActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SequenceUntilSuccessActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequenceUntilSuccessActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentActionState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.ActionState.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActionState current_action_state = 1;
 * @return {?proto.skills.ActionState}
 */
proto.skills.SequenceUntilSuccessActionStateData.prototype.getCurrentActionState = function() {
  return /** @type{?proto.skills.ActionState} */ (
    jspb.Message.getWrapperField(this, proto.skills.ActionState, 1));
};


/**
 * @param {?proto.skills.ActionState|undefined} value
 * @return {!proto.skills.SequenceUntilSuccessActionStateData} returns this
*/
proto.skills.SequenceUntilSuccessActionStateData.prototype.setCurrentActionState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SequenceUntilSuccessActionStateData} returns this
 */
proto.skills.SequenceUntilSuccessActionStateData.prototype.clearCurrentActionState = function() {
  return this.setCurrentActionState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SequenceUntilSuccessActionStateData.prototype.hasCurrentActionState = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SequencePretakeoffActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SequencePretakeoffActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SequencePretakeoffActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequencePretakeoffActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
currentActionState: (f = msg.getCurrentActionState()) && proto.skills.ActionState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SequencePretakeoffActionStateData}
 */
proto.skills.SequencePretakeoffActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SequencePretakeoffActionStateData;
  return proto.skills.SequencePretakeoffActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SequencePretakeoffActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SequencePretakeoffActionStateData}
 */
proto.skills.SequencePretakeoffActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.ActionState;
      reader.readMessage(value,proto.skills.ActionState.deserializeBinaryFromReader);
      msg.setCurrentActionState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SequencePretakeoffActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SequencePretakeoffActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SequencePretakeoffActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SequencePretakeoffActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentActionState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.ActionState.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActionState current_action_state = 1;
 * @return {?proto.skills.ActionState}
 */
proto.skills.SequencePretakeoffActionStateData.prototype.getCurrentActionState = function() {
  return /** @type{?proto.skills.ActionState} */ (
    jspb.Message.getWrapperField(this, proto.skills.ActionState, 1));
};


/**
 * @param {?proto.skills.ActionState|undefined} value
 * @return {!proto.skills.SequencePretakeoffActionStateData} returns this
*/
proto.skills.SequencePretakeoffActionStateData.prototype.setCurrentActionState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SequencePretakeoffActionStateData} returns this
 */
proto.skills.SequencePretakeoffActionStateData.prototype.clearCurrentActionState = function() {
  return this.setCurrentActionState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SequencePretakeoffActionStateData.prototype.hasCurrentActionState = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoAndOrbitPoiActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoAndOrbitPoiActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoAndOrbitPoiActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoAndOrbitPoiActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
sequenceState: (f = msg.getSequenceState()) && proto.skills.SequenceActionStateData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoAndOrbitPoiActionStateData}
 */
proto.skills.GotoAndOrbitPoiActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoAndOrbitPoiActionStateData;
  return proto.skills.GotoAndOrbitPoiActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoAndOrbitPoiActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoAndOrbitPoiActionStateData}
 */
proto.skills.GotoAndOrbitPoiActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.SequenceActionStateData;
      reader.readMessage(value,proto.skills.SequenceActionStateData.deserializeBinaryFromReader);
      msg.setSequenceState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoAndOrbitPoiActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoAndOrbitPoiActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoAndOrbitPoiActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoAndOrbitPoiActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSequenceState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.SequenceActionStateData.serializeBinaryToWriter
    );
  }
};


/**
 * optional SequenceActionStateData sequence_state = 1;
 * @return {?proto.skills.SequenceActionStateData}
 */
proto.skills.GotoAndOrbitPoiActionStateData.prototype.getSequenceState = function() {
  return /** @type{?proto.skills.SequenceActionStateData} */ (
    jspb.Message.getWrapperField(this, proto.skills.SequenceActionStateData, 1));
};


/**
 * @param {?proto.skills.SequenceActionStateData|undefined} value
 * @return {!proto.skills.GotoAndOrbitPoiActionStateData} returns this
*/
proto.skills.GotoAndOrbitPoiActionStateData.prototype.setSequenceState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoAndOrbitPoiActionStateData} returns this
 */
proto.skills.GotoAndOrbitPoiActionStateData.prototype.clearSequenceState = function() {
  return this.setSequenceState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoAndOrbitPoiActionStateData.prototype.hasSequenceState = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.LooperActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.LooperActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.LooperActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LooperActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
maxLoops: jspb.Message.getFieldWithDefault(msg, 3, 0),
action: (f = msg.getAction()) && proto.skills.Action.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.LooperActionArgs}
 */
proto.skills.LooperActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.LooperActionArgs;
  return proto.skills.LooperActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.LooperActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.LooperActionArgs}
 */
proto.skills.LooperActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skills.ControlNodeTypeEnum.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxLoops(value);
      break;
    case 4:
      var value = new proto.skills.Action;
      reader.readMessage(value,proto.skills.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.LooperActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.LooperActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.LooperActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LooperActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxLoops();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional ControlNodeTypeEnum.Enum type = 1;
 * @return {!proto.skills.ControlNodeTypeEnum.Enum}
 */
proto.skills.LooperActionArgs.prototype.getType = function() {
  return /** @type {!proto.skills.ControlNodeTypeEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skills.ControlNodeTypeEnum.Enum} value
 * @return {!proto.skills.LooperActionArgs} returns this
 */
proto.skills.LooperActionArgs.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skills.LooperActionArgs.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.LooperActionArgs} returns this
 */
proto.skills.LooperActionArgs.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 max_loops = 3;
 * @return {number}
 */
proto.skills.LooperActionArgs.prototype.getMaxLoops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.LooperActionArgs} returns this
 */
proto.skills.LooperActionArgs.prototype.setMaxLoops = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Action action = 4;
 * @return {?proto.skills.Action}
 */
proto.skills.LooperActionArgs.prototype.getAction = function() {
  return /** @type{?proto.skills.Action} */ (
    jspb.Message.getWrapperField(this, proto.skills.Action, 4));
};


/**
 * @param {?proto.skills.Action|undefined} value
 * @return {!proto.skills.LooperActionArgs} returns this
*/
proto.skills.LooperActionArgs.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LooperActionArgs} returns this
 */
proto.skills.LooperActionArgs.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LooperActionArgs.prototype.hasAction = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.InverterActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.InverterActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.InverterActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.InverterActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
action: (f = msg.getAction()) && proto.skills.Action.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.InverterActionArgs}
 */
proto.skills.InverterActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.InverterActionArgs;
  return proto.skills.InverterActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.InverterActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.InverterActionArgs}
 */
proto.skills.InverterActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.Action;
      reader.readMessage(value,proto.skills.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.InverterActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.InverterActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.InverterActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.InverterActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional Action action = 1;
 * @return {?proto.skills.Action}
 */
proto.skills.InverterActionArgs.prototype.getAction = function() {
  return /** @type{?proto.skills.Action} */ (
    jspb.Message.getWrapperField(this, proto.skills.Action, 1));
};


/**
 * @param {?proto.skills.Action|undefined} value
 * @return {!proto.skills.InverterActionArgs} returns this
*/
proto.skills.InverterActionArgs.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.InverterActionArgs} returns this
 */
proto.skills.InverterActionArgs.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.InverterActionArgs.prototype.hasAction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.EnableStrobeLightsActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.EnableStrobeLightsActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.EnableStrobeLightsActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnableStrobeLightsActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
noArgs: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.EnableStrobeLightsActionArgs}
 */
proto.skills.EnableStrobeLightsActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.EnableStrobeLightsActionArgs;
  return proto.skills.EnableStrobeLightsActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.EnableStrobeLightsActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.EnableStrobeLightsActionArgs}
 */
proto.skills.EnableStrobeLightsActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.EnableStrobeLightsActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.EnableStrobeLightsActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.EnableStrobeLightsActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnableStrobeLightsActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoArgs();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool no_args = 1;
 * @return {boolean}
 */
proto.skills.EnableStrobeLightsActionArgs.prototype.getNoArgs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.EnableStrobeLightsActionArgs} returns this
 */
proto.skills.EnableStrobeLightsActionArgs.prototype.setNoArgs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DisableStrobeLightsActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DisableStrobeLightsActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DisableStrobeLightsActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisableStrobeLightsActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
noArgs: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DisableStrobeLightsActionArgs}
 */
proto.skills.DisableStrobeLightsActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DisableStrobeLightsActionArgs;
  return proto.skills.DisableStrobeLightsActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DisableStrobeLightsActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DisableStrobeLightsActionArgs}
 */
proto.skills.DisableStrobeLightsActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DisableStrobeLightsActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DisableStrobeLightsActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DisableStrobeLightsActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisableStrobeLightsActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoArgs();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool no_args = 1;
 * @return {boolean}
 */
proto.skills.DisableStrobeLightsActionArgs.prototype.getNoArgs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.DisableStrobeLightsActionArgs} returns this
 */
proto.skills.DisableStrobeLightsActionArgs.prototype.setNoArgs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.EnableStrobeLightsResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.EnableStrobeLightsResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.EnableStrobeLightsResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnableStrobeLightsResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.EnableStrobeLightsResultData}
 */
proto.skills.EnableStrobeLightsResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.EnableStrobeLightsResultData;
  return proto.skills.EnableStrobeLightsResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.EnableStrobeLightsResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.EnableStrobeLightsResultData}
 */
proto.skills.EnableStrobeLightsResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.EnableStrobeLightsResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.EnableStrobeLightsResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.EnableStrobeLightsResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnableStrobeLightsResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DisableStrobeLightsResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DisableStrobeLightsResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DisableStrobeLightsResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisableStrobeLightsResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DisableStrobeLightsResultData}
 */
proto.skills.DisableStrobeLightsResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DisableStrobeLightsResultData;
  return proto.skills.DisableStrobeLightsResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DisableStrobeLightsResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DisableStrobeLightsResultData}
 */
proto.skills.DisableStrobeLightsResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DisableStrobeLightsResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DisableStrobeLightsResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DisableStrobeLightsResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisableStrobeLightsResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.EnableStrobeLightsActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.EnableStrobeLightsActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.EnableStrobeLightsActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnableStrobeLightsActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.EnableStrobeLightsActionStateData}
 */
proto.skills.EnableStrobeLightsActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.EnableStrobeLightsActionStateData;
  return proto.skills.EnableStrobeLightsActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.EnableStrobeLightsActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.EnableStrobeLightsActionStateData}
 */
proto.skills.EnableStrobeLightsActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.EnableStrobeLightsActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.EnableStrobeLightsActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.EnableStrobeLightsActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnableStrobeLightsActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DisableStrobeLightsActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DisableStrobeLightsActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DisableStrobeLightsActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisableStrobeLightsActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DisableStrobeLightsActionStateData}
 */
proto.skills.DisableStrobeLightsActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DisableStrobeLightsActionStateData;
  return proto.skills.DisableStrobeLightsActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DisableStrobeLightsActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DisableStrobeLightsActionStateData}
 */
proto.skills.DisableStrobeLightsActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DisableStrobeLightsActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DisableStrobeLightsActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DisableStrobeLightsActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisableStrobeLightsActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.NcpgWaypointInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.NcpgWaypointInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.NcpgWaypointInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgWaypointInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
poseId: (f = msg.getPoseId()) && pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.toObject(includeInstance, f),
defaultNavTReferencePose: (f = msg.getDefaultNavTReferencePose()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.NcpgWaypointInfo}
 */
proto.skills.NcpgWaypointInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.NcpgWaypointInfo;
  return proto.skills.NcpgWaypointInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.NcpgWaypointInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.NcpgWaypointInfo}
 */
proto.skills.NcpgWaypointInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb;
      reader.readMessage(value,pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.deserializeBinaryFromReader);
      msg.setPoseId(value);
      break;
    case 3:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setDefaultNavTReferencePose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.NcpgWaypointInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.NcpgWaypointInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.NcpgWaypointInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgWaypointInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getPoseId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.serializeBinaryToWriter
    );
  }
  f = message.getDefaultNavTReferencePose();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional Waypoint waypoint = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.NcpgWaypointInfo.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.NcpgWaypointInfo} returns this
*/
proto.skills.NcpgWaypointInfo.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgWaypointInfo} returns this
 */
proto.skills.NcpgWaypointInfo.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgWaypointInfo.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional nav_cam_reconstruction.PoseIdPb pose_id = 2;
 * @return {?proto.nav_cam_reconstruction.PoseIdPb}
 */
proto.skills.NcpgWaypointInfo.prototype.getPoseId = function() {
  return /** @type{?proto.nav_cam_reconstruction.PoseIdPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb, 2));
};


/**
 * @param {?proto.nav_cam_reconstruction.PoseIdPb|undefined} value
 * @return {!proto.skills.NcpgWaypointInfo} returns this
*/
proto.skills.NcpgWaypointInfo.prototype.setPoseId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgWaypointInfo} returns this
 */
proto.skills.NcpgWaypointInfo.prototype.clearPoseId = function() {
  return this.setPoseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgWaypointInfo.prototype.hasPoseId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional body.TransPb default_nav_T_reference_pose = 3;
 * @return {?proto.body.TransPb}
 */
proto.skills.NcpgWaypointInfo.prototype.getDefaultNavTReferencePose = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 3));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.skills.NcpgWaypointInfo} returns this
*/
proto.skills.NcpgWaypointInfo.prototype.setDefaultNavTReferencePose = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgWaypointInfo} returns this
 */
proto.skills.NcpgWaypointInfo.prototype.clearDefaultNavTReferencePose = function() {
  return this.setDefaultNavTReferencePose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgWaypointInfo.prototype.hasDefaultNavTReferencePose = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.NcpgWaypointsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.NcpgWaypointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.NcpgWaypointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.NcpgWaypointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgWaypointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
ncpgWaypointInfosList: jspb.Message.toObjectList(msg.getNcpgWaypointInfosList(),
    proto.skills.NcpgWaypointInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.NcpgWaypointsRequest}
 */
proto.skills.NcpgWaypointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.NcpgWaypointsRequest;
  return proto.skills.NcpgWaypointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.NcpgWaypointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.NcpgWaypointsRequest}
 */
proto.skills.NcpgWaypointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 3:
      var value = new proto.skills.NcpgWaypointInfo;
      reader.readMessage(value,proto.skills.NcpgWaypointInfo.deserializeBinaryFromReader);
      msg.addNcpgWaypointInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.NcpgWaypointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.NcpgWaypointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.NcpgWaypointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgWaypointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNcpgWaypointInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skills.NcpgWaypointInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.NcpgWaypointsRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.NcpgWaypointsRequest} returns this
 */
proto.skills.NcpgWaypointsRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated NcpgWaypointInfo ncpg_waypoint_infos = 3;
 * @return {!Array<!proto.skills.NcpgWaypointInfo>}
 */
proto.skills.NcpgWaypointsRequest.prototype.getNcpgWaypointInfosList = function() {
  return /** @type{!Array<!proto.skills.NcpgWaypointInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.NcpgWaypointInfo, 3));
};


/**
 * @param {!Array<!proto.skills.NcpgWaypointInfo>} value
 * @return {!proto.skills.NcpgWaypointsRequest} returns this
*/
proto.skills.NcpgWaypointsRequest.prototype.setNcpgWaypointInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skills.NcpgWaypointInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.NcpgWaypointInfo}
 */
proto.skills.NcpgWaypointsRequest.prototype.addNcpgWaypointInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skills.NcpgWaypointInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.NcpgWaypointsRequest} returns this
 */
proto.skills.NcpgWaypointsRequest.prototype.clearNcpgWaypointInfosList = function() {
  return this.setNcpgWaypointInfosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode}
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode;
  return proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode}
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.ConvertMissionWithNCPGInfoIntoSiteMissionResultCode.Enum = {
  UNKNOWN: 0,
  SUCCESS: 1,
  INVALID_ARGUMENTS: 2,
  NOT_ALL_WAYPOINTS_HAVE_NCPG_INFO: 3,
  RUNTIME_CONVERSION_ERROR: 4
};

goog.object.extend(exports, proto.skills);
